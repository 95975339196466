import './FiltersGroup.css';
import React, { useEffect, useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';

// data = [
//     {
//         name: '',
//         onClick: () => {},
//         params: [],
//         to: '',
//     },
// ];

function FiltersGroup({ data, className = '' }) {
    const [activeLink, setActiveLink] = useState(0);
    const [isSticky, setIsSticky] = useState(false);

    const containerRef = useRef();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        data.forEach(filter => {
            if (!filter.params) filter.params = [];
        });
    }, [data]);

    function handleScroll() {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            if (rect.top <= 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }
    }

    return (
        <section className={'filters-group-container ' + className + (isSticky ? ' pinned' : '')} ref={containerRef}>
            <div className="container">
                {data.map((filter, key) => {
                    return filter.to ? (
                        <HashLink
                            smooth
                            custom-title={filter.name}
                            className={'filter ' + (activeLink === key ? 'active' : '')}
                            onClick={() => setActiveLink(key)}
                            to={filter.to}
                            key={key}>
                            {filter.name}
                        </HashLink>
                    ) : (
                        <div
                            custom-title={filter.name}
                            className={'filter ' + (activeLink === key ? 'active' : '')}
                            onClick={() => {
                                setActiveLink(key);
                                filter.onClick && filter.onClick(...filter.params);
                            }}
                            key={key}>
                            {filter.name}
                        </div>
                    );
                })}
            </div>
        </section>
    );
}

export default FiltersGroup;
