import './ThumbnailsRow.css';
import React, { useEffect, useRef, useState } from 'react';
import Thumbnail from '../../molecules/thumbnail/Thumbnail';
import { useWindowSize } from '@uidotdev/usehooks';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';

// data = [
//     {
//         title: '',
//         tag: '',
//         picture: '',
//         alt: '',
//         link: '',
//         linkText: '',
//     },
// ];

function ThumbnailsRow({ data, margin = '30px 0', className = '', title, thumbnailWidth = 'auto' }) {
    const [fingerStartPosition, setFingerStartPosition] = useState({
        x: 0,
        y: 0,
    });
    const [slidePosition, _setSlidePosition] = useState(0);
    const [scrollHorizontal, setScrollHorizontal] = useState(0);
    const [lastSlideX, setLastSlideX] = useState(0);
    const [sliderDimensions, setSliderDimensions] = useState({
        width: 0,
        childrenCount: 0,
        childWidth: 0,
    });
    const [isScrolling, setIsScrolling] = useState(false);
    const [displayedItemsCount, setDisplayedItemsCount] = useState(1);
    const [thumbnailPadding, setThumbnailPadding] = useState(20);
    const [sliderContainerWidth, setSliderContainerWidth] = useState(0);

    const { width, _ } = useWindowSize();

    const sliderRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (sliderRef.current) {
                setSliderDimensions({
                    width: sliderRef.current.offsetWidth,
                    childrenCount: sliderRef.current.children.length,
                    childWidth:
                        sliderRef.current.children[0] && sliderRef.current.children[0].offsetWidth + thumbnailPadding,
                    padding: parseInt(window.getComputedStyle(sliderRef.current).getPropertyValue('padding-left')) * 2,
                });
            }

            if (containerRef.current) {
                setSliderContainerWidth(containerRef.current.offsetWidth);
            }

            let tmp = 1;
            switch (true) {
                case width > 576 && width <= 768:
                    tmp = 2;
                    break;
                case width > 768 && width <= 1200:
                    tmp = 3;
                    break;
                case width > 1200:
                    tmp = 4;
            }
            setDisplayedItemsCount(tmp);
        }, 500);
    }, [width, data, thumbnailPadding]);

    useEffect(() => {
        let tmp = 0;
        if (width > 576) tmp = 40;
        if (width <= 576) tmp = 20;

        if (tmp != thumbnailPadding) setThumbnailPadding(tmp);
    }, [width]);

    function setSlidePosition(value) {
        if (displayedItemsCount === sliderRef.current.children.length) return;
        if (
            value + 5 >= -sliderDimensions.padding / 2 &&
            value - 5 <=
                sliderDimensions.width -
                    sliderDimensions.padding / 2 -
                    sliderDimensions.childWidth * displayedItemsCount
        ) {
            _setSlidePosition(value);
        }
    }

    function handleScroll(e) {
        const fingerPosition =
            e.type === 'mousemove'
                ? {
                      x: e.pageX,
                      y: e.pageY,
                  }
                : {
                      x: e.touches[0].screenX,
                      y: e.touches[0].screenY,
                  };
        const slideX = fingerStartPosition.x - fingerPosition.x;
        const slideY = fingerStartPosition.y - fingerPosition.y;

        switch (scrollHorizontal) {
            case 0:
                if (Math.abs(slideX) > Math.abs(slideY)) setScrollHorizontal(1);
                else setScrollHorizontal(-1);
                break;
            case -1:
                break;
            case 1:
                setScrollHorizontal(1);
                setSlidePosition(slidePosition + lastSlideX - fingerPosition.x);
                setLastSlideX(fingerPosition.x);
        }
    }

    function handleScrollStart(e) {
        sliderRef.current.style.transition = 'none';

        if (e.type === 'mousedown') {
            setFingerStartPosition({ x: e.pageX, y: e.pageY });
            setLastSlideX(e.pageX);
        } else {
            setFingerStartPosition({ x: e.touches[0].screenX, y: e.touches[0].screenY });
            setLastSlideX(e.touches[0].screenX);
        }

        setIsScrolling(true);
    }

    function handleScrollEnd() {
        sliderRef.current.style.transition = 'right 0.3s';

        setScrollHorizontal(0);
        setSlidePosition(getClosestArticle(fingerStartPosition.x > lastSlideX));
        setIsScrolling(false);
    }

    function getClosestArticle(upper) {
        let value;
        const maxValue = sliderDimensions.childWidth * (sliderDimensions.childrenCount - displayedItemsCount);

        if (upper) value = Math.ceil(slidePosition / sliderDimensions.childWidth) * sliderDimensions.childWidth;
        else value = Math.floor(slidePosition / sliderDimensions.childWidth) * sliderDimensions.childWidth;

        if (value > maxValue) value = maxValue;

        if (value < 0) value = 0;

        return value;
    }

    function toNextArticle(upper) {
        let value = 0;

        if (upper) value = Math.ceil((slidePosition + 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;
        else value = Math.floor((slidePosition - 10) / sliderDimensions.childWidth) * sliderDimensions.childWidth;

        setSlidePosition(value);
    }

    function isAtFirstPosition() {
        return slidePosition === 0 || displayedItemsCount >= sliderDimensions.childrenCount;
    }

    function isAtLastPosition() {
        return (
            displayedItemsCount >= sliderDimensions.childrenCount ||
            slidePosition === sliderDimensions.childWidth * (sliderDimensions.childrenCount - displayedItemsCount)
        );
    }

    return (
        <section
            className={'thumbnails-row-container ' + className + (width > 992 ? ' container' : '')}
            ref={containerRef}
            style={{
                '--margin': margin,
                '--thumbnail-max-width': sliderContainerWidth / displayedItemsCount - 40 + 'px',
            }}>
            {title}
            <FilledButton
                className={'thumbnail-row-bracket ' + (isAtFirstPosition() ? 'd-none' : '')}
                onClick={() => toNextArticle()}
                padding="12px 10px">
                <Bracket rotation="270deg" marginLeft="0" />
            </FilledButton>
            <FilledButton
                className={'thumbnail-row-bracket ' + (isAtLastPosition() ? 'd-none' : '')}
                onClick={() => toNextArticle(true)}
                padding="12px 10px">
                <Bracket rotation="90deg" marginLeft="0" />
            </FilledButton>
            <div className="thumbnails-row">
                <div
                    className="thumbnails-slider"
                    style={{ '--slide': slidePosition + 'px', '--thumbnail-width': width > 576 ? thumbnailWidth : '' }}
                    ref={sliderRef}
                    onTouchStart={handleScrollStart}
                    onTouchMove={handleScroll}
                    onTouchEnd={handleScrollEnd}
                    onMouseDown={handleScrollStart}
                    onMouseMove={e => isScrolling && handleScroll(e)}
                    onMouseUp={handleScrollEnd}
                    onMouseLeave={handleScrollEnd}>
                    {data.map((thumbnail, key) => {
                        return width > 576 ? (
                            <div className="thumbnails-row-unit" key={key}>
                                <Thumbnail {...thumbnail} />
                            </div>
                        ) : (
                            <Thumbnail key={key} {...thumbnail} />
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default ThumbnailsRow;
