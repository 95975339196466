import { useWindowSize } from '@uidotdev/usehooks';
import './RapportsRow.css';
import React from 'react';
import ComponentsRow from '../componentsRow/ComponentsRow';
import Download from '../../atoms/icons/general/download/Download';
import Usine from '../../atoms/icons/general/usine/Usine';
import Commerce from '../../atoms/icons/general/commerce/Commerce';
import Immeuble from '../../atoms/icons/general/immeuble/Immeuble';
import RapportFDC from '../../../assets/rapportsExamples/miniature_rapport_fdc.webp';
import RapportMC from '../../../assets/rapportsExamples/miniature_rapport_mc.webp';
import RapportTDS from '../../../assets/rapportsExamples/miniature_rapport_tds.webp';

function RapportsRow() {
    const { width } = useWindowSize();

    const TarifExample = ({ Icon, title, img, fileName }) => {
        return (
            <div>
                <div className="centered">
                    <Icon width="50px" />
                </div>
                <p>{title}</p>
                <div className="centered">
                    <a target="_blank" href={require(`../../../uploads/rapportsExamples/${fileName}.pdf`)}>
                        <img src={img} className="rapport-example" />
                    </a>
                    <a
                        className="button filled-button default-button-settings"
                        style={{ '--padding': '10px 15px' }}
                        download={fileName}
                        href={require(`../../../uploads/rapportsExamples/${fileName}.pdf`)}>
                        <Download width="30px" />
                    </a>
                </div>
            </div>
        );
    };

    return (
        <>
            {width && width > 576 ? (
                <div className="container">
                    <div className="exemples-rapports-container row-500">
                        <div>
                            <div className="centered mb-sm">
                                <Immeuble width="50px" />
                            </div>
                            <p>Murs commerciaux</p>
                            <div className="centered">
                                <a
                                    target="_blank"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_mc.pdf')}>
                                    <img src={RapportMC} className="rapport-example" />
                                </a>
                                <a
                                    className="button filled-button default-button-settings"
                                    style={{ '--padding': '10px 15px' }}
                                    download="exemple_rapport_mc.pdf"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_mc.pdf')}>
                                    <Download width="30px" />
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="centered mb-sm">
                                <Commerce width="50px" />
                            </div>
                            <p>Fonds de commerce</p>
                            <div className="centered">
                                <a
                                    target="_blank"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_fdc.pdf')}>
                                    <img src={RapportFDC} className="rapport-example" />
                                </a>
                                <a
                                    className="button filled-button default-button-settings"
                                    style={{ '--padding': '10px 15px' }}
                                    download="exemple_rapport_fdc.pdf"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_fdc.pdf')}>
                                    <Download width="30px" />
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className=" mb-sm centered">
                                <Usine width="50px" />
                            </div>
                            <p>Titres de société</p>
                            <div className="centered">
                                <a
                                    target="_blank"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_tds.pdf')}>
                                    <img src={RapportTDS} className="rapport-example" />
                                </a>
                                <a
                                    className="button filled-button default-button-settings"
                                    style={{ '--padding': '10px 15px' }}
                                    download="exemple_rapport_tds.pdf"
                                    href={require('../../../uploads/rapportsExamples/exemple_rapport_tds.pdf')}>
                                    <Download width="30px" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <ComponentsRow
                    contentType="rapportsExamples"
                    className="exemples-rapports-container"
                    Component={TarifExample}
                    componentsProps={[
                        {
                            Icon: Immeuble,
                            title: 'Murs commerciaux',
                            img: RapportMC,
                            fileName: 'exemple_rapport_mc',
                        },
                        {
                            Icon: Commerce,
                            title: 'Fonds de commerce',
                            img: RapportFDC,
                            fileName: 'exemple_rapport_fdc',
                        },
                        {
                            Icon: Usine,
                            title: 'Titres de société',
                            img: RapportTDS,
                            fileName: 'exemple_rapport_tds',
                        },
                    ]}
                />
            )}
        </>
    );
}

export default RapportsRow;
