import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import SearchBar from '../../../components/molecules/searchBar/SearchBar';
import TextHr from '../../../components/molecules/textHr/TextHr';
import ComponentsRow from '../../../components/organisms/componentsRow/ComponentsRow';
import ExpendableGroup from '../../../components/organisms/expendableGroup/ExpendableGroup';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './LexiqueCommercial.css';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';

function LexiqueCommercial() {
    const [definitions, setDefinitions] = useState([]);
    const [indexes, setIndexes] = useState([]);
    const [indexesHeight, setIndexesHeight] = useState([]);
    const [activeIndex, setActiveIndex] = useState(-1);

    const { width } = useWindowSize();

    const lexiqueContainerRef = useRef(null);

    useEffect(() => {
        setDefinitions(
            sortDefinitions([
                {
                    title: 'Filan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Hctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Hctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Hctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Hctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Jilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Actifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Actifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Actifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Actifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Pctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Kilan comptable',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Zctifs',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: "Rcte définitif de vente - Acte de vente - Acte de cession - Acte d'acquisition",
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'lail commercial',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Mail de courte durée (dit bail dérogatoire ou bail précaire)',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Qmortissements',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Bureaux',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Scte authentique de vente',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Iail commercial',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Yail de courte durée (dit bail dérogatoire ou bail précaire)',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Vmortissements',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Gureaux',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
                {
                    title: 'Ucte authentique de vente',
                    content:
                        "Dans une vente immobilière (logement, terrain, murs commerciaux), l’acte authentique est le contrat définitif de cession signé chez un officier public (généralement un notaire) et non chez un avocat. L’acte authentique, en constatant la vente définitive, garantit la date de signature et le contenu du contrat avec ses différentes conditions. Il s’agit donc d’un élément de preuve incontestable en cas de litige.Si un exploitant souhaite céder son affaire et qu'il est soumis à un bail commercial, ce dernier stipule au chapitre “cession” les modalités de vente : acte authentique (avec donc l’intervention d’un notaire) ou sous seing privé (soit directement entre cédant et repreneur - ce qui est vivement déconseillé - ou avec l’intervention d’un avocat).",
                },
            ])
        );
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [indexesHeight]);

    useEffect(() => {
        setIndexes(getIndexesFromDefinitions());
    }, [definitions]);

    useEffect(() => {
        setIndexHeight();
    }, [indexes]);

    function sortDefinitions(definitions) {
        definitions.sort((a, b) => {
            a = a.title.toLowerCase();
            b = b.title.toLowerCase();

            if (a > b) return 1;
            if (a === b) return 0;
            if (a < b) return -1;
        });

        return definitions;
    }

    function handleScroll() {
        const tmp = indexesHeight.indexOf(
            indexesHeight.filter(index => {
                return index.top - 60 <= window.scrollY && index.bottom - 60 >= window.scrollY;
            })[0]
        );

        if (tmp !== activeIndex) setActiveIndex(tmp);
    }

    function setIndexHeight() {
        const children = Array.from(lexiqueContainerRef.current.children);
        children.pop();
        let tmp = [];

        for (let i = 0; i < children.length; i++) {
            if (children[i].localName === 'div') {
                tmp.push({
                    top: children[i].offsetTop - 90,
                });
            }
            for (let i = 0; i < tmp.length; i++) {
                tmp[i].bottom = tmp[i + 1]
                    ? tmp[i + 1].top - 1
                    : children[children.length - 1].offsetTop + children[children.length - 1].offsetHeight;
            }
        }
        if (tmp !== activeIndex) setIndexesHeight(tmp);
    }

    function getIndexesFromDefinitions() {
        let data = [];

        definitions.forEach(definition => {
            const letter = definition.title.charAt(0);

            if (!data.includes(letter)) {
                data.push(letter);
            }
        });

        data.map((letter, index) => {
            data[index] = {
                letter: letter,
                id: index,
                index: index,
            };
        });

        return data;
    }

    function getDefinitionsByIndex(index) {
        return definitions
            .filter(definition => definition.title.charAt(0) == index)
            .map(def => {
                return { title: def.title, content: <p>{def.content}</p> };
            });
    }

    const LetterComponent = ({ letter, id, index }) => {
        return (
            <p>
                <HashLink
                    smooth
                    to={'#' + id}
                    className={'letter-component ' + index + ' ' + (index === activeIndex ? 'active' : '')}>
                    {letter}
                </HashLink>
            </p>
        );
    };

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Outils: Lexique commercial',
                            to: '/lexique-commercial',
                        },
                    ]}
                />
                <Banner
                    title="Lexique commercial"
                    subtitle="Notre petit lexique des mots barbares à l'usage des (non)initiés"
                />
            </section>
            <div>
                <div className="sticky-container mb-0">
                    <ComponentsRow
                        margin="0 auto"
                        Component={LetterComponent}
                        className="letter-component-container mb-sm"
                        componentsProps={indexes}
                        contentType="letters"
                        slideLength={4}
                    />
                    <div className="mx-sm">
                        <SearchBar topTreshold={95} />
                    </div>
                </div>
                <section className="container" ref={lexiqueContainerRef}>
                    {indexes.map((index, key) => {
                        return (
                            <Fragment key={key}>
                                <TextHr fontSize={width > 576 ? '3rem' : '2.5rem'} className="lexique-hr" id={index.id}>
                                    {index.letter}
                                </TextHr>
                                <ExpendableGroup data={getDefinitionsByIndex(index.letter)} />
                            </Fragment>
                        );
                    })}
                    <FilledButton to="/debut-parcours" className="mid-page-button">
                        Je démarre mon estimation
                    </FilledButton>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default LexiqueCommercial;
