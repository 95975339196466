import Cross from '../../atoms/icons/general/cross/Cross';
import './Notification.css';
import React, { useEffect, useState } from 'react';

function Notification({
    bgColor = 'var(--blue-focus)',
    color = 'white',
    children,
    visible,
    setVisible,
    visibleTime = 5000,
}) {
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if (visible) {
            setTimer(
                setTimeout(() => {
                    setVisible(false);
                }, visibleTime),
            );
        } else {
            clearTimeout(timer);
        }
    }, [visible]);

    return (
        <section className={'notification-background ' + (visible ? 'notification-shown' : 'notification-hidden')}>
            <div
                className="notification-container container"
                style={{ '--background-color': bgColor, '--color': color }}>
                <div className="notification-content">{children}</div>
                <Cross color={color} onClick={() => setVisible(false)} width="20px" />
            </div>
        </section>
    );
}

export default Notification;
