import './MesEstimations.css';
import FilledButton from '../../../../../components/molecules/buttons/filledButton/FilledButton';
import SearchBar from '../../../../../components/molecules/searchBar/SearchBar';
import ComponentsRow from '../../../../../components/organisms/componentsRow/ComponentsRow';
import Add from '../../../../../components/atoms/icons/general/add/Add';
import React, { useEffect, useState } from 'react';
import Download from '../../../../../components/atoms/icons/general/download/Download';
import Pen from '../../../../../components/atoms/icons/general/pen/Pen';
import Archive from '../../../../../components/atoms/icons/general/archive/Archive';
import Unarchive from '../../../../../components/atoms/icons/general/unarchive/Unarchive';
import Duplicate from '../../../../../components/atoms/icons/general/duplicate/Duplicate';
import { useWindowSize } from '@uidotdev/usehooks';

function MesEstimations() {
    const [searchValue, setSearchValue] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [estimations, setEstimations] = useState([]);
    const [visibleEstimations, setVisibleEstimations] = useState([]);

    const { width } = useWindowSize();

    useEffect(() => {
        fetchEstimations();
    }, []);

    useEffect(() => {
        if (estimations.length) setVisibleEstimations(getFilteredEstimations(estimations));
    }, [activeTab, searchValue]);

    function fetchEstimations() {
        const estimations = [
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Murs',
                adresse: '12az rue de Rennes',
                date: '01/07/2023',
                valide: true,
                archive: 0,
            },
            {
                id: '3',
                enseigne: 'Test de la place',
                type: 'Fonds',
                adresse: '1 rue de Rennes',
                date: '01/07/2023',
                valide: true,
                archive: 0,
            },
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Murs',
                adresse: '1332 rue de Rennes',
                date: '01/07/2023',
                valide: false,
                archive: 0,
            },
            {
                id: '1',
                enseigne: 'Titre de la place',
                type: 'Titre',
                adresse: '12 rue de Rennes',
                date: '01/07/2023',
                valide: false,
                archive: 0,
            },
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Murs',
                adresse: '132 rue de Rennes',
                date: '01/07/2023',
                valide: true,
                archive: 0,
            },
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Murs',
                adresse: '12 rue de Rennes',
                date: '01/07/2023',
                valide: true,
                archive: 1,
            },
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Fonds',
                adresse: '12 rue de Rennes',
                date: '01/07/2023',
                valide: false,
                archive: 1,
            },
            {
                id: '1',
                enseigne: 'Brasserie de la place',
                type: 'Fonds',
                adresse: '12 rue de Rennes',
                date: '01/07/2023',
                valide: true,
                archive: 1,
            },
        ];
        setEstimations(estimations);
        setVisibleEstimations(getFilteredEstimations(estimations));
        //TODO
    }

    function getFilteredEstimations(estimations) {
        if (searchValue === '') return estimations.filter(estimation => estimation.archive === activeTab);

        return estimations.filter(
            estimation =>
                estimation.archive === activeTab &&
                (estimation.enseigne.toLowerCase().includes(searchValue.toLowerCase()) ||
                    estimation.type.toLowerCase().includes(searchValue.toLowerCase()) ||
                    estimation.adresse.toLowerCase().includes(searchValue.toLowerCase())),
        );
    }

    function createEstimation() {
        //TODO
    }

    const EstimationTumbnail = ({ type, enseigne, adresse, date, valide, archive }) => {
        return (
            <div className="estimation-thumbnail-container">
                <div className="text-center">Enseigne</div>
                <div className="estimation-thumbnail">
                    <div className="estimation-enseigne">{enseigne}</div>
                    <div className="estimation-infos">
                        <p className="label">Type</p>
                        <p>{type}</p>
                        <p className="label">Adresse</p>
                        <p>{adresse}</p>
                        <p className="label">Date</p>
                        <p>{date}</p>
                    </div>
                    <div className="estimation-buttons">
                        <div>
                            <FilledButton>
                                <Pen width="30px" color="var(--white)" />
                            </FilledButton>
                            Modifier
                        </div>
                        {valide ? (
                            <>
                                <div>
                                    <FilledButton>
                                        <Download width="30px" />
                                    </FilledButton>
                                    Télécharger
                                </div>
                                <div>
                                    <FilledButton>
                                        <Duplicate width="30px" color="var(--white)" />
                                    </FilledButton>
                                    Dupliquer
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div className="fake-button">
                                        <Download width="30px" />
                                        <div>Estimation non terminée</div>
                                    </div>
                                    Télécharger
                                </div>
                                <div>
                                    <div className="fake-button">
                                        <Duplicate width="30px" color="var(--white)" />
                                        <div>Estimation non terminée</div>
                                    </div>
                                    Dupliquer
                                </div>
                            </>
                        )}
                        {archive ? (
                            <>
                                <div>
                                    <FilledButton>
                                        <Unarchive width="30px" color="var(--white)" />
                                    </FilledButton>
                                    Désarchiver
                                </div>
                            </>
                        ) : (
                            <div>
                                <FilledButton>
                                    <Archive width="30px" color="var(--white)" />
                                </FilledButton>
                                Archiver
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <section className="container">
                <FilledButton onClick={createEstimation} className="mid-page-button estimations-button">
                    <Add width={width > 576 ? '30px' : '20px'} /> Démarrer une nouvelle estimation
                </FilledButton>
                <div className="estimations-container">
                    <div className="estimations-container-header">
                        <p className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
                            Mes estimations
                        </p>
                        <p className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                            Mes estimations archivées
                        </p>
                    </div>
                    <div className="search-input-container">
                        <SearchBar
                            onChange={value => setSearchValue(value)}
                            placeholder="Rechercher"
                            bgColor={width > 1200 ? 'var(--white)' : 'var(--beige)'}
                            className="estimations-search-bar"
                        />
                    </div>
                    {width > 1200 && (
                        <div className="estimations-table">
                            <div className="estimations-filters">
                                <div>Enseigne</div>
                                <div>Type</div>
                                <div>Adresse</div>
                                <div>Date</div>
                                <div className="estimations-filters-buttons">
                                    <div className="centered" custom-title="Modifier">
                                        <Pen width="26px" />
                                    </div>
                                    <div className="centered" custom-title="Télécharger">
                                        <Download color="var(--dark-blue)" width="30px" />
                                    </div>
                                    <div className="centered" custom-title="Dupliquer">
                                        <Duplicate />
                                    </div>
                                    <div className="centered" custom-title={activeTab ? 'Désarchiver' : 'Archiver'}>
                                        <Archive />
                                    </div>
                                </div>
                            </div>
                            <div className="estimations-table-body">
                                {visibleEstimations.map((estimation, key) => {
                                    return (
                                        <div className="estimation-overview-container" key={key}>
                                            <div>{estimation.enseigne}</div>
                                            <div>{estimation.type}</div>
                                            <div>{estimation.adresse}</div>
                                            <div>{estimation.date}</div>
                                            <div className="estimations-filters-buttons">
                                                <FilledButton bgColor="#E6CC83" hoverColor="#E6CC83">
                                                    Modifier
                                                </FilledButton>
                                                {estimation.valide ? (
                                                    <>
                                                        <FilledButton bgColor="#B8C7FF" hoverColor="#B8C7FF">
                                                            Télécharger
                                                        </FilledButton>
                                                        <FilledButton bgColor="#E3E3E3" hoverColor="#E3E3E3">
                                                            Dupliquer
                                                        </FilledButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="fake-button">
                                                            Télécharger<div>Estimation non terminée</div>
                                                        </div>
                                                        <div className="fake-button">
                                                            Dupliquer<div>Estimation non terminée</div>
                                                        </div>
                                                    </>
                                                )}
                                                {estimation.archive ? (
                                                    <FilledButton bgColor="#FDB7A9" hoverColor="#FDB7A9">
                                                        Désarchiver
                                                    </FilledButton>
                                                ) : (
                                                    <FilledButton bgColor="#FDB7A9" hoverColor="#FDB7A9">
                                                        Archiver
                                                    </FilledButton>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {width <= 1200 && (
                <>
                    <ComponentsRow
                        slideLength={width > 576 ? 1 : 0}
                        contentType="estimations"
                        Component={EstimationTumbnail}
                        componentsProps={visibleEstimations}
                    />
                </>
            )}
        </>
    );
}

export default MesEstimations;
