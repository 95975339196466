import './Toggle.css';
import React, { useRef, useEffect } from 'react';
import { toggleCollapseElement } from '../../../../utils/Utils';
import Warning from '../../../atoms/icons/general/warning/Warning';

function Toggle({
    label,
    label2,
    name,
    error,
    register,
    visible,
    color = 'var(--sky-blue)',
    bgColor = 'var(--blue)',
    onChange,
    checked,
}) {
    const ref = useRef(null);

    useEffect(() => {
        if (visible !== undefined) toggleCollapseElement(ref.current, visible);
    }, [visible]);

    const inputRegister = register ? register(name) : null;

    return (
        <div className="toggle-error-container" ref={ref}>
            <div className="field-container">
                <div className="toggle-container">
                    <label className="form-label" htmlFor={name}>
                        {label}
                    </label>
                    <input
                        disabled={visible !== undefined && !visible}
                        checked={checked}
                        name={name}
                        className="toggle-input"
                        type="checkbox"
                        id={name}
                        {...inputRegister}
                        onChange={e => {
                            register && inputRegister.onChange(e);
                            onChange && onChange(e);
                        }}
                    />
                    <label
                        htmlFor={name}
                        className={'toggle-front ' + (checked && ' checked')}
                        style={{ '--bg-color': bgColor, '--color': color }}
                    />
                    <label className="form-label" htmlFor={name}>
                        {label2}
                    </label>
                </div>
            </div>
            {error ? (
                <div className="form-error">
                    <Warning /> <p>{error}</p>
                </div>
            ) : (
                <p className="form-error"></p>
            )}
        </div>
    );
}

export default Toggle;
