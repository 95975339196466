import { NavLink } from 'react-router-dom';
import './Header.css';
import React, { useEffect, useRef, useState } from 'react';
import EmcLogo from '../../atoms/logos/emcLogo/EmcLogo';
import Calculator from '../../atoms/icons/headerIcons/calculator/Calculator';
import Chart from '../../atoms/icons/headerIcons/chart/Chart';
import Rapport from '../../atoms/icons/headerIcons/rapport/Rapport';
import FingerSnap from '../../atoms/icons/headerIcons/fingerSnap/FingerSnap';
import Gear from '../../atoms/icons/headerIcons/gear/Gear';
import LightBulb from '../../atoms/icons/headerIcons/lightBulb/LightBulb';
import Alphabet from '../../atoms/icons/headerIcons/alphabet/Alphabet';
import Megaphone from '../../atoms/icons/headerIcons/megaphone/Megaphone';
import InterrogationMark from '../../atoms/icons/headerIcons/interrogationMark/InterrogationMark';
import Shop from '../../atoms/icons/headerIcons/shop/Shop';
import Advice from '../../atoms/icons/headerIcons/advice/Advice';
import Factory from '../../atoms/icons/headerIcons/factory/Factory';
import Building from '../../atoms/icons/headerIcons/building/Building';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import UnfilledButton from '../../molecules/buttons/unfilledButton/UnfilledButton';
import Cart from '../../atoms/icons/headerIcons/cart/Cart';
import Power from '../../atoms/icons/headerIcons/power/Power';
import ArrowSm from '../../atoms/icons/general/arrowSm/ArrowSm';
import { toggleCollapseElement } from '../../../utils/Utils';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import { useWindowSize } from '@uidotdev/usehooks';
import RoundedDiv from '../../atoms/icons/general/roundedDiv/RoundedDiv';

function Header() {
    const [openedIndex, setOpenedIndex] = useState({ index: -1, visible: false });
    const [headerOpened, setHeaderOpened] = useState(false);
    const [mobileOpenedIndex, setMobileOpenedIndex] = useState(-1);
    const navLinksRef = useRef(null);

    const { width } = useWindowSize();

    useEffect(() => {
        if (width > 1200 && openedIndex.index > -1 && navLinksRef.current) {
            toggleCollapseElement(navLinksRef.current.children[openedIndex.index].children[1], openedIndex.visible);
            navLinksRef.current.children[openedIndex.index].children[1].classList.toggle(
                'opened-header-section',
                openedIndex.visible,
            );
        }
    }, [openedIndex]);

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.overflow = headerOpened ? 'hidden' : 'auto';
    }, [headerOpened]);

    function toggleMobileMenu(index) {
        if (width <= 1200) {
            switch (mobileOpenedIndex) {
                case index:
                    toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
                    setMobileOpenedIndex(-1);
                    break;
                case -1:
                    toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
                    setMobileOpenedIndex(index);
                    break;
                default:
                    toggleCollapseElement(navLinksRef.current.children[mobileOpenedIndex].children[1], false);
                    toggleCollapseElement(navLinksRef.current.children[index].children[1], true);
                    setMobileOpenedIndex(index);
            }
        }
    }

    return (
        <>
            <div className={'header-background ' + (headerOpened ? 'header-background-opened' : '')}>
                <section className={'container header-container ' + (headerOpened ? 'opened-header' : 'closed-header')}>
                    <nav className="flex s-between relative">
                        <NavLink to="/" className="logo-container">
                            <EmcLogo dark={width <= 1200} />
                        </NavLink>
                        <div className="mobile-header-buttons">
                            <UnfilledButton
                                padding="4px"
                                to="/panier"
                                bgColor="var(--white)"
                                hoverColor="var(--dark-blue)">
                                <Cart color="var(--white)" />
                            </UnfilledButton>
                            <div className="burger-menu" onClick={() => setHeaderOpened(val => !val)}>
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>
                        <ul className="navbar-container" ref={navLinksRef}>
                            <li onMouseLeave={() => setOpenedIndex({ index: 0, visible: false })}>
                                <p
                                    custom-title="Estimer"
                                    className={
                                        'navbar-header ' + (mobileOpenedIndex === 0 ? 'mobile-opened-index' : '')
                                    }
                                    onMouseEnter={() => setOpenedIndex({ index: 0, visible: true })}
                                    onClick={() => toggleMobileMenu(0)}>
                                    Estimer
                                    <Bracket
                                        color="var(--dark-blue)"
                                        rotation="180deg"
                                        width="20px"
                                        className="header-bracket"
                                    />
                                </p>
                                <ul className="navbar-content ">
                                    <NavLink to="/debut-parcours">
                                        <Shop />
                                        Fonds de commerce
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/debut-parcours">
                                        <Factory />
                                        Titres de société
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/debut-parcours">
                                        <Building />
                                        Murs commerciaux
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/methode">
                                        <Gear />
                                        Méthode & philosophie
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                </ul>
                            </li>
                            <li onMouseLeave={() => setOpenedIndex({ index: 1, visible: false })}>
                                <p
                                    custom-title="Outils"
                                    className={
                                        'navbar-header ' + (mobileOpenedIndex === 1 ? 'mobile-opened-index' : '')
                                    }
                                    onMouseEnter={() => setOpenedIndex({ index: 1, visible: true })}
                                    onClick={() => toggleMobileMenu(1)}>
                                    Outils
                                    <Bracket
                                        color="var(--dark-blue)"
                                        rotation="180deg"
                                        width="20px"
                                        className="header-bracket"
                                    />
                                </p>
                                <ul className="navbar-content ">
                                    <NavLink to="/outils-pratiques">
                                        <LightBulb />
                                        Outils pratiques à télécharger
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/lexique-commercial">
                                        <Alphabet />
                                        Lexique commercial
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/annonces">
                                        <Megaphone />
                                        Les annonces
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/faq">
                                        <InterrogationMark />
                                        F.A.Q
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                </ul>
                            </li>
                            <li onMouseLeave={() => setOpenedIndex({ index: 2, visible: false })}>
                                <p
                                    custom-title="Conseils"
                                    className={
                                        'navbar-header ' + (mobileOpenedIndex === 2 ? 'mobile-opened-index' : '')
                                    }
                                    onMouseEnter={() => setOpenedIndex({ index: 2, visible: true })}
                                    onClick={() => toggleMobileMenu(2)}>
                                    Conseils
                                    <Bracket
                                        color="var(--dark-blue)"
                                        rotation="180deg"
                                        width="20px"
                                        className="header-bracket"
                                    />
                                </p>
                                <ul className="navbar-content ">
                                    <NavLink to="/conseils-pros">
                                        <Advice />
                                        Conseils de pros
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/trucs-astuces">
                                        <FingerSnap />
                                        Trucs & astuces agents immos
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                </ul>
                            </li>
                            <li onMouseLeave={() => setOpenedIndex({ index: 3, visible: false })}>
                                <p
                                    custom-title="Simulateurs"
                                    className={
                                        'navbar-header ' + (mobileOpenedIndex === 3 ? 'mobile-opened-index' : '')
                                    }
                                    onMouseEnter={() => setOpenedIndex({ index: 3, visible: true })}
                                    onClick={() => toggleMobileMenu(3)}>
                                    Simulateurs
                                    <Bracket
                                        color="var(--dark-blue)"
                                        rotation="180deg"
                                        width="20px"
                                        className="header-bracket"
                                    />
                                </p>
                                <ul className="navbar-content ">
                                    <NavLink to="/simulateur-credit">
                                        <Chart />
                                        Simulateur de crédit
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/plan-financement">
                                        <Rapport />
                                        Plan de financement
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                    <NavLink to="/calculateur-cotisations">
                                        <Calculator />
                                        Calculateur de cotisations
                                        <ArrowSm rotation="180deg" className="navbar-content-arrow" />
                                    </NavLink>
                                </ul>
                            </li>
                            <li>
                                <p custom-title="Tarifs">
                                    <NavLink to="/tarifs">Tarifs</NavLink>
                                </p>
                            </li>
                        </ul>
                        <div className="header-buttons-container">
                            <div className="flex">
                                <FilledButton
                                    fontSize="0.9rem"
                                    to="/mon-compte"
                                    bgColor={width <= 1200 ? 'var(--light-gold)' : 'var(--blue)'}
                                    color={width <= 1200 ? 'var(--dark-blue)' : 'var(--white)'}>
                                    Mon compte
                                </FilledButton>
                                <UnfilledButton padding="4px">
                                    <Power />
                                </UnfilledButton>
                            </div>
                            <div className="flex">
                                <FilledButton
                                    fontSize="0.9rem"
                                    bgColor={width <= 1200 ? 'var(--light-gold)' : 'var(--blue)'}
                                    color={width <= 1200 ? 'var(--dark-blue)' : 'var(--white)'}>
                                    Demander une démo
                                </FilledButton>
                                <UnfilledButton padding="4px" to="/panier">
                                    <Cart />
                                </UnfilledButton>
                            </div>
                        </div>
                    </nav>
                </section>
            </div>
            {width <= 1200 && !headerOpened && (
                <RoundedDiv rotation="180deg" height="3vw" radius="1600vw" className="header-rounded-div mb-sm" />
            )}
        </>
    );
}

export default Header;
