import { toggleCollapseElement } from '../../../utils/Utils';
import Bracket from '../../atoms/icons/general/bracket/Bracket';
import './ExpendableGroup.css';
import React, { useEffect, useRef, useState } from 'react';

// data =  [
//     {
//         title: "",
//         content: ""
//     },
// ]

function ExpendableGroup({ data, className = '' }) {
    const [openedElement, setOpenedElement] = useState(-1);
    const parentRef = useRef(null);

    useEffect(() => {
        for (let i = 0; i < parentRef.current.children.length; i++) {
            const child = parentRef.current.children[i];
            if (child.classList.contains('active')) {
                toggleCollapseElement(child.children[1], true, 60);
            } else {
                toggleCollapseElement(child.children[1], false, 60);
            }
        }
    }, [openedElement]);

    function changeOpenedElement(index) {
        setOpenedElement(value => (value === index ? -1 : index));
    }

    return (
        <section className={'expendable-group-container ' + className} ref={parentRef}>
            {data.map((element, key) => {
                return (
                    <div className={'expendable-container ' + (openedElement === key ? 'active' : '')} key={key}>
                        <div className="expendable-top" onClick={() => changeOpenedElement(key)}>
                            <div className="expendable-title">{element.title}</div>
                            <div className="expendable-arrow">
                                <Bracket width="20px" rotation={openedElement === key ? '0' : '180deg'} />
                            </div>
                        </div>
                        <div className="expendable-content">
                        {element.content}
                        </div>
                    </div>
                );
            })}
        </section>
    );
}

export default ExpendableGroup;
