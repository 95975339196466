import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/headerIcons/fingerSnap.svg';

function FingerSnap({ width = '30px', color = 'var(--dark-blue)', className }) {
    return (
        <Icon style={{ '--color': color, fill: 'var(--color)' }} width={width} height={width} className={className} />
    );
}

export default FingerSnap;
