import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TrucsAstuces from '../pages/conseils/trucsAstuces/TrucsAstuces';
import { HelmetProvider } from 'react-helmet-async';
import '../stylesheets/main.css';
import Home from '../pages/autres/home/Home';
import ConseilsPros from '../pages/conseils/conseilsPros/ConseilsPros';
import ConseilsProsArticle from '../pages/conseils/conseilsProsArticle/ConseilsProsArticle';
import LexiqueCommercial from '../pages/outils/lexiqueCommercial/LexiqueCommercial';
import OutilsPratiques from '../pages/outils/outilsPratiques/OutilsPratiques';
import { useScrollbarWidth } from '../utils/Utils';
import Faq from '../pages/outils/faq/Faq';
import AppContext from '../context/AppContext';
import Modal from '../components/organisms/modal/Modal';
import TrucsAstucesArtice from '../pages/conseils/trucsAstucesArticle/TrucsAstucesArticle';
import OutilPratiqueDetail from '../pages/outils/outilPratiqueDetail/OutilPratiqueDetail';
import ScrollToTop from '../utils/ScrollToTop';
import Methode from '../pages/estimations/methode/Methode';
import Tarifs from '../pages/autres/tarifs/Tarifs';
import DebutParcours from '../pages/estimations/parcours/debutParcours/DebutParcours';
import DetailParcours from '../pages/estimations/parcours/detailParcours/DetailParcours';
import CalculateurCotisations from '../pages/simulateurs/calculateurCotisations/CalculateurCotisations';
import SimulateurCredit from '../pages/simulateurs/simulateurCredit/SimulateurCredit';
import PlanFinancement from '../pages/simulateurs/planFinancement/PlanFinancement';
import Notification from '../components/organisms/notification/Notification';
import Error404 from '../pages/errors/error404/Error404';
import MonCompte from '../pages/compte/monCompte/MonCompte';

function App() {
    document.getElementById('root').style.setProperty('--scrollbar-width', useScrollbarWidth() + 'px');

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', content: <></>, buttons: <></> });
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationContent, setNotificationContent] = useState({
        bgColor: 'transparent',
        color: 'var(--dark-blue)',
        content: '',
        visibleTime: 5000,
    });

    function showNotification(content, bgColor = 'var(--sky-blue)', color = 'var(dark-blue)', visibleTime = 5000) {
        setNotificationContent({ content, bgColor, color, visibleTime });
        setNotificationVisible(true);
    }

    return (
        <AppContext.Provider value={{ setModalContent, setModalVisible, showNotification }}>
            <Modal visible={modalVisible} setVisible={setModalVisible} title={modalContent.title}>
                {modalContent.content}
                {modalContent.buttons && <div className="modal-buttons">{modalContent.buttons}</div>}
            </Modal>
            <Notification
                visible={notificationVisible}
                setVisible={setNotificationVisible}
                bgColor={notificationContent.bgColor}
                color={notificationContent.color}
                visibleTime={notificationContent.visibleTime}>
                {notificationContent.content}
            </Notification>
            <HelmetProvider>
                <Router>
                    <ScrollToTop />
                    <Routes>
                        {/* Autres */}
                        <Route path="/" element={<Home />} />
                        <Route path="/tarifs" element={<Tarifs />} />

                        {/* Liens légaux */}
                        {/* <Route path="/politique-confidentialite" element={<Home />} /> */}
                        {/* <Route path="/mentions-legales" element={<Home />} /> */}
                        {/* <Route path="/paramatrage-cookies" element={<Home />} /> */}
                        {/* <Route path="/cgu" element={<Home />} /> */}
                        {/* <Route path="/cgv" element={<Home />} /> */}

                        {/* Estimations */}
                        {/* <Route path="/debut-parcours" element={<DebutParcours />} />
                        <Route path="/detail-parcours" element={<DetailParcours />} /> */}
                        <Route path="/methode" element={<Methode />} />

                        {/* Outils */}
                        <Route path="/outils-pratiques" element={<OutilsPratiques />} />
                        <Route path="/outils-pratiques/:outilURL" element={<OutilPratiqueDetail />} />
                        <Route path="/lexique-commercial" element={<LexiqueCommercial />} />
                        {/* <Route path="/annonces" element={<Home />} /> */}
                        <Route path="/faq" element={<Faq />} />

                        {/* Conseils */}
                        <Route path="/conseils-pros" element={<ConseilsPros />} />
                        <Route path="/conseils-pros/:articleURL" element={<ConseilsProsArticle />} />
                        <Route path="/trucs-astuces" element={<TrucsAstuces />} />
                        <Route path="/trucs-astuces/:articleURL" element={<TrucsAstucesArtice />} />

                        {/* Simulateurs */}
                        <Route path="/simulateur-credit" element={<SimulateurCredit />} />
                        <Route path="/plan-financement" element={<PlanFinancement />} />
                        <Route path="/calculateur-cotisations" element={<CalculateurCotisations />} />

                        {/* Compte */}
                        <Route path="mon-compte" element={<MonCompte />} />
                        {/* <Route path="panier" element={<Home />} /> */}

                        {/* Errors */}
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </Router>
            </HelmetProvider>
        </AppContext.Provider>
    );
}

export default App;
