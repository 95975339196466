import { useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './ConseilsPros.css';
import React, { useEffect, useState } from 'react';
import ThumbnailsGroup from '../../../components/organisms/thumbnailsGroup/ThumbnailsGroup';
import DotsLine from '../../../components/atoms/dotsLine/DotsLine';
import Bracket from '../../../components/atoms/icons/general/bracket/Bracket';
import SearchBar from '../../../components/molecules/searchBar/SearchBar';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import ThumbnailsRow from '../../../components/organisms/thumbnailsRow/ThumbnailsRow';
import TextHr from '../../../components/molecules/textHr/TextHr';
import { Link } from 'react-router-dom';

function ConseilsPros() {
    const [articles, setArticles] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsFilters, setTagsFilters] = useState([]);
    const [shownArticles, setShownArticles] = useState([]);
    const [searchBarTreshold, setSearchBarTreshold] = useState();

    const { width } = useWindowSize();

    useEffect(() => {
        fetchArticles();
    }, []);

    useEffect(() => {
        setSearchBarTreshold(width > 1200 ? 160 : 90);
    }, [width]);

    useEffect(() => {
        setTagsFromArticles(articles);
    }, [articles, width]);

    useEffect(() => {
        setTagsFiltersFromTags(tags);
    }, [tags]);

    async function fetchArticles() {
        //TODO

        const data = [
            {
                id: '10',
                title: '0compte courant d’associé : définition, fonctionnement et cession',
                url: 'benchmark-concurrentiel',
                picture: 'Image_benchmark_concurrentiel.jpg',
                tag: "Cession d'une affaire",
            },
            {
                id: '8',
                title: '1Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Cession_droit_bail.jpeg',
                tag: 'Valeur des fonds, titres, murs ou droit au bail',
            },
            {
                id: '7',
                title: "2Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_compromis_vente_protocole_accord.jpeg',
                tag: 'Valeur des fonds, titres, murs ou droit au bail',
            },
            {
                id: '6',
                title: "3Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_estimation_fonds_commerce.jpeg',
                tag: 'Bien acheter une affaire',
            },
            {
                id: '5',
                title: '4Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
                tag: 'Maitriser le bail commercial',
            },
            {
                id: '4',
                title: "5Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
                tag: 'Maitriser le bail commercial',
            },
            {
                id: '12',
                title: "6Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Le_calendrier.jpeg',
                tag: 'Maitriser le bail commercial',
            },
            {
                id: '3',
                title: '7Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Valorisation_murs_commerciaux.jpeg',
                tag: 'Maitriser le bail commercial',
            },
            {
                id: '2',
                title: "8Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Vente_fonds_commerce.svg',
                tag: 'Comment optimiser son affaire ?',
            },
        ];

        data.map((article, index) => {
            data[index] = {
                title: article.title,
                tag: article.tag,
                picture: '/conseilsPros/' + article.id + '/thumbnail/' + article.picture,
                link: article.url,
                linkText: "Lire l'article",
            };
        });

        setArticles(data);
        setShownArticles(data);
    }

    function setTagsFromArticles(articles) {
        const data = [];

        articles.forEach(article => {
            if (!data.includes(article.tag)) data.push(article.tag);
        });

        data.map((tag, index) => {
            data[index] = {
                name: tag,
                id: tag.toLowerCase().split(' ').join('-'),
            };
        });

        if (width > 576)
            data.unshift({
                name: 'Tous',
                id: 'tous',
            });
        else
            data.unshift({
                name: 'À la une',
                id: 'à-la-une',
            });

        setTags(data);
    }

    function setTagsFiltersFromTags(tags) {
        const data = [];

        tags.forEach(tag => {
            data.push({
                name: tag.name,
                to: width < 576 && '#' + tag.id,
                onClick: () => filterArticles(tag.id),
            });
        });

        setTagsFilters(data);
    }

    function filterArticles(tagId) {
        if (tagId === 'tous') {
            setShownArticles(articles);
            return;
        }
        const tag = tags.filter(tag => tag.id == tagId)[0].name;

        setShownArticles(articles.filter(article => article.tag == tag));
    }

    const MobileArticles = () => {
        let index = 0;

        return tags.map((tag, key) => {
            if (tag.id !== -1 && articles.slice(1).filter(article => article.tag === tag.name).length) {
                index++;
                return (
                    <ThumbnailsRow
                        key={key}
                        title={<TextHr id={tag.id}>{tag.name}</TextHr>}
                        data={articles.slice(1).filter(article => article.tag === tag.name)}
                    />
                );
            }
        });
    };

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Conseils: Conseils de pros',
                            to: '/conseils-pros',
                        },
                    ]}
                />
                <Banner
                    title="Conseils de pros"
                    subtitle="Cession, acquisition, optimisation: astuces, pièges, points essentiels"
                />
                {articles[0] && width > 1200 && (
                    <>
                        <PageTitle title="À LA UNE" className="mt-lg" />
                        <div className="une-container">
                            <Link to={'/conseils-pros/' + articles[0].link}>
                                <img src={require('../../../uploads' + articles[0].picture)} className="une-picture" />
                                <div className="une-tag">{articles[0].tag}</div>
                                <div>
                                    <p className="une-title">{articles[0].title}</p>
                                    <DotsLine />
                                </div>
                                <p className="une-link">
                                    <span>Lire </span>
                                    <span className="unbreakable">
                                        l'article
                                        <Bracket color="var(--dark-blue)" rotation="90deg" width="12px" />
                                    </span>
                                </p>
                            </Link>
                        </div>
                    </>
                )}
            </section>
            <div>
                <div className="sticky-container">
                    <FiltersGroup
                        data={tagsFilters.map(tag => {
                            return {
                                name: tag.name,
                                onClick: tag.onClick,
                                params: [],
                                to: tag.to,
                            };
                        })}
                    />
                    <div className="sticky-search-bar-container">
                        <SearchBar topTreshold={searchBarTreshold} />
                    </div>
                </div>
                {width > 576 && articles.length ? (
                    <section className="container conseils-pros-container">
                        <ThumbnailsGroup
                            data={
                                width > 1200 && shownArticles.length === articles.length
                                    ? shownArticles.slice(1)
                                    : shownArticles
                            }
                        />
                    </section>
                ) : (
                    <>
                        <ThumbnailsRow title={<TextHr id="à-la-une">À LA UNE</TextHr>} data={[articles[0]]} />
                        <MobileArticles />
                    </>
                )}
                <FilledButton to="debut-parcours" className="mid-page-button">
                    Démarrer le parcours d'estimation
                </FilledButton>
            </div>
            <Footer />
        </>
    );
}

export default ConseilsPros;
