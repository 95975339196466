import React, { useEffect, useRef } from 'react';
import { defaultValidation } from '../../../../utils/formValidation/FormValidation';
import './Input.css';
import Magnifier from '../../../atoms/icons/general/magnifier/Magnifier.js';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseElement } from '../../../../utils/Utils.js';

function Input({
    label,
    name,
    type = 'text',
    register,
    defaultValue,
    validation = defaultValidation,
    error,
    icon,
    placeholder,
    visible,
    bgColor,
    tip,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    children,
    preventAutoComplete,
    value,
    disabled,
}) {
    const inputRegister = register && (visible || visible === undefined) ? register(name, validation) : null;

    const ref = useRef(null);

    useEffect(() => {
        if (visible !== undefined) toggleCollapseElement(ref.current, visible);
    }, [visible]);

    function showCalendar(e) {
        if (type === 'date') {
            e.target.showPicker();
        }
    }

    return (
        <>
            <div className={'w-100 d-flex' + (visible === undefined || visible ? ' pb-sm' : '')} ref={ref}>
                <div style={{ '--bg-color': bgColor }} className={'field-container ' + (bgColor ? 'filled-input' : '')}>
                    <label className="form-label" htmlFor={name}>
                        {label}
                    </label>
                    <p className={'label-tip' + (tip ? '' : ' d-none')}>{tip}</p>
                    <span className={'input-container before-' + icon}>
                        {icon === 'search' ? <Magnifier className="before-search-content" /> : <></>}
                        <input
                            disabled={(visible !== undefined && !visible) || disabled}
                            placeholder={placeholder}
                            className={'before-' + icon}
                            id={name}
                            value={value !== undefined ? value : undefined}
                            autoComplete={preventAutoComplete ? 'off' : 'on'}
                            type={type}
                            {...inputRegister}
                            onChange={e => {
                                inputRegister && inputRegister.onChange(e);
                                onChange && onChange(e);
                            }}
                            onFocus={e => {
                                showCalendar(e);
                                onFocus && onFocus(e);
                            }}
                            onBlur={e => {
                                onBlur && onBlur(e);
                            }}
                            defaultValue={type === 'file' ? '' : defaultValue}
                            onKeyDown={onKeyDown}
                        />
                        {children}
                        {error ? (
                            <div className="form-error">
                                <Warning /> <p>{error}</p>
                            </div>
                        ) : (
                            <p className="form-error"></p>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
}

export default Input;
