import { useWindowScroll } from '@uidotdev/usehooks';
import Magnifier from '../../atoms/icons/general/magnifier/Magnifier';
import './SearchBar.css';
import React, { useEffect, useRef, useState } from 'react';
import { isStickyElementPinned } from '../../../utils/Utils';

function SearchBar({
    className = '',
    onChange = () => {},
    width = '50%',
    placeholder,
    bgColor = 'var(--beige)',
    topTreshold = -1,
}) {
    const [value, setValue] = useState('');
    const [pinned, setPinned] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        window.addEventListener('scroll', handleStickyScroll);
        return () => {
            window.removeEventListener('scroll', handleStickyScroll);
        };
    }, [topTreshold]);

    function handleStickyScroll() {
        setPinned(isStickyElementPinned(inputRef, topTreshold));
    }

    return (
        <div
            className={'search-bar-container ' + className}
            style={{ '--width': width, '--background-color': bgColor }}>
            <input
                ref={inputRef}
                className={pinned ? 'pinned' : ''}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                type="text"
                placeholder={placeholder}
            />
            <Magnifier />
        </div>
    );
}

export default SearchBar;
