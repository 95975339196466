import './PlanFinancementForm.css';
import React, { useEffect, useState, useRef } from 'react';
import Toggle from '../../../components/molecules/formComponents/toggle/Toggle';
import PlanFinancementFdc from './planFinancementFdc/PlanFinancementFdc';
import PlanFinancementMurs from './planFinancementMurs/PlanFinancementMurs';
import { toggleCollapseElement, getIdUser } from '../../../utils/Utils';
import UnfilledButton from '../../molecules/buttons/unfilledButton/UnfilledButton';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Card from '../../molecules/cards/card/Card';
import Hr from '../../molecules/hr/Hr';
import Carousel from '../../organisms/carousel/Carousel';
import Trash from '../../atoms/icons/general/trash/Trash';
import axios from 'axios';
import { PlanFinancementContext } from './planFinancementContext/PlanFinancementContext';
// import Notification from '../../molecules/notification/Notification';
import Financement from '../../atoms/icons/general/financement/Financement';
import Info from '../../atoms/icons/general/info/Info';
import Loader from '../../organisms/loader/Loader';
import Download from '../../atoms/icons/general/download/Download';
import { useContext } from 'react';
import AppContext from '../../../context/AppContext';

function PlanFinancementForm() {
    const [typePlan, setTypePlan] = useState(0);
    const [plansFinancement, setPlansFinancement] = useState([]);
    const [plan, setPlan] = useState({});
    const [estimationData, setEstimationData] = useState({});
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [fromSynthese, setFromSynthese] = useState(false);

    const plansRefs = useRef(new Array(2).fill(null));
    const mursRef = useRef();
    const fdcRef = useRef();
    const id_user = getIdUser();

    const { setModalContent, setModalVisible, showNotification } = useContext(AppContext);

    useEffect(() => {
        fetchAllData();
    }, []);

    useEffect(() => {
        toggleCollapseElement(plansRefs.current[typePlan], false, 1000);
        toggleCollapseElement(plansRefs.current[Number(!typePlan)], true, 1000);
    }, [typePlan]);

    useEffect(() => {
        plansFinancement.forEach(element => {
            if (element.numEstimation != plan.numEstimation && element.numEstimation == estimationData.numEstimation)
                displayPlan(element);
        });
    }, [plansFinancement, estimationData]);

    async function fetchAllData() {
        await fetchDataFromV1();
        await fetchPlansFinancement(false);
    }

    async function fetchPlansFinancement(name) {
        if (id_user) {
            try {
                const plansMurs = (
                    await axios.get(process.env.REACT_APP_SERVER_URL + 'plan-financement/murs/iduser/' + id_user, {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                        },
                    })
                ).data;
                const plansFdc = (
                    await axios.get(process.env.REACT_APP_SERVER_URL + 'plan-financement/fdc/iduser/' + id_user, {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                        },
                    })
                ).data;

                plansFdc.forEach(element => {
                    element.typePlan = 0;
                });
                plansMurs.forEach(element => {
                    element.typePlan = 1;
                });

                let tmp = [...plansFdc, ...plansMurs];
                tmp.forEach(element => {
                    for (const [key, value] of Object.entries(element)) {
                        if (value === null) element[key] = '';
                    }
                });

                if (name) {
                    setPlan(tmp.filter(element => element.name === name)[0]);
                    setTypePlan(tmp.filter(element => element.name === name)[0].typePlan);
                    setPlansFinancement(tmp);
                    return tmp.filter(element => element.name === name)[0];
                } else if (name === undefined) {
                    setEstimationData({});
                }
                setPlansFinancement(tmp);
            } catch (e) {
                showNotification(
                    'Une erreur est survenue lors de la récupération de vos plans de financement',
                    'var(--red)',
                );
            }
        }
        const data = JSON.parse(localStorage.getItem('planFinancement'));
        if (data) {
            setTypePlan(data.typePlan);
            delete data.typePlan;
            setPlan(data);
        }
    }

    async function fetchDataFromV1() {
        try {
            let formData = new FormData();
            formData.append('id_user', id_user);
            let data = await axios.post(process.env.REACT_APP_SERVER_URL + 'api/get-v2', formData);

            localStorage.setItem('token', 'Bearer ' + data.data.id_user_hash);

            data = JSON.parse(data.data.json);

            if (Object.entries(data).length) {
                if (data.typePlan === 1) {
                    setTypePlan(1);
                    setEstimationData({
                        typePlan: data.typePlan,
                        name: data.data.name,
                        numEstimation: data.data.numEstimation,
                        id_user: data.id_user,
                        extra: { loyer: data.data.loyer },
                    });

                    localStorage.setItem('fromSynthese', true);
                    setFromSynthese(true);

                    mursRef.current.setName('Plan de financement ' + data.data.name);
                    fdcRef.current.setName('');

                    document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
                } else if (data.typePlan === 0) {
                    setTypePlan(0);
                    let tmp = {
                        typePlan: data.typePlan,
                        name: data.data[0].name,
                        numEstimation: data.data[0].numeroEstimation,
                        id_user: data.id_user,
                        extra: {},
                    };
                    data.data.forEach(element => {
                        tmp.extra['n' + element.n] = element.EBE;
                    });
                    setEstimationData(tmp);

                    localStorage.setItem('fromSynthese', true);
                    setFromSynthese(true);

                    fdcRef.current.setName('Plan de financement ' + tmp.name);
                    mursRef.current.setName('');

                    document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
                } else {
                    setEstimationData({ id_user: data.id_user });
                    localStorage.removeItem('fromSynthese');
                    setFromSynthese(false);
                }
            } else {
                localStorage.removeItem('fromSynthese');
                setFromSynthese(false);
            }
        } catch (e) {}
    }

    async function getEstimationData(numEstimation, typePlan) {
        try {
            let tmp;

            if (typePlan === 0) {
                const fdc = await axios.get(
                    process.env.REACT_APP_SERVER_URL +
                        'plan-financement/estimation/fdc/' +
                        numEstimation +
                        '?iduser=' +
                        id_user,
                    {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                        },
                    },
                );

                tmp = {
                    typePlan: 0,
                    name: fdc.data[0].nom_estimation,
                    numEstimation: fdc.data[0].numeroEstimation,
                    id_user: id_user,
                    extra: {},
                };
                fdc.data.forEach(element => {
                    tmp.extra['n' + element.n] = element.EBE;
                });
            } else {
                const mur = await axios.get(
                    process.env.REACT_APP_SERVER_URL +
                        'plan-financement/estimation/murs/' +
                        numEstimation +
                        '?iduser=' +
                        id_user,
                    {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                        },
                    },
                );

                tmp = {
                    typePlan: 1,
                    name: mur.data.nom_estimation,
                    numEstimation: mur.data.numEstimation,
                    id_user: id_user,
                    extra: {
                        loyer: mur.data.montantLoyer,
                    },
                };
            }

            setEstimationData(tmp);
        } catch (e) {}
    }

    async function changeTypePlan() {
        try {
            await axios.delete(
                process.env.REACT_APP_SERVER_URL +
                    'plan-financement/' +
                    (plan.typePlan ? 'murs' : 'fdc') +
                    '/' +
                    plan.id,
                {
                    headers: {
                        Authorization: localStorage.getItem('token'),
                    },
                    data: {
                        id_user: id_user,
                    },
                },
            );

            await axios.post(
                process.env.REACT_APP_SERVER_URL + 'plan-financement/' + (plan.typePlan ? 'fdc' : 'murs'),
                plan.typePlan
                    ? {
                          name: plan.name,
                          id_user: id_user,
                          id: 0,
                          prix_vente: 0,
                          stocks: 0,
                          honoraires_juridiques: 0,
                          droits_enregistrement: 0,
                          fonds_roulement: 0,
                          travaux: 0,
                          materiel_agencement: 0,
                          ratio_apport_financier: 0,
                          apport_financier: 0,
                          montant_sept_ans: 0,
                          taux_emprunt: 0,
                          total_a_financer: 0,
                          total_finance: 0,
                          ebe_retraite: 0,
                          rbs_credit: 0,
                          reste_a_vivre: 0,
                          cot_sociales_tns: 0,
                          rem_nette_tns: 0,
                          cot_sociales_salarie: 0,
                          rem_nette_salarie: 0,
                          autres: [],
                      }
                    : {
                          name: plan.name,
                          id_user: id_user,
                          prix_murs: 0,
                          ratio_frais_notaire: 0,
                          frais_notaire: 0,
                          travaux: 0,
                          ratio_apport_financier: 0,
                          apport_financier: 0,
                          montant_a_emprunter: 0,
                          duree_pret: 0,
                          taux_emprunt: 0,
                          total_a_financer: 0,
                          total_finance: 0,
                          loyer: 0,
                          rbs_credit: 0,
                          benefice_perte: 0,
                          autres: [],
                      },
                {
                    headers: {
                        Authorization: localStorage.getItem('token'),
                    },
                },
            );
            await fetchPlansFinancement(plan.name);
            showNotification('Le type du plan de financement ' + plan.name + 'a été changé avec succès');
        } catch (e) {
            showNotification('Une erreur est survenue', 'var(--red');
        }
        setModalVisible(false);
    }

    function switchTypePlan(e) {
        if (estimationData.typePlan !== undefined) {
            setModalVisible(true);
            setModalContent({
                title: 'changer de plan de financement',
                content: (
                    <>
                        <div className="modal-text">
                            <p>
                                Il est impossible de changer le type de plan d'un plan de financement relié à une
                                estimation
                            </p>
                        </div>
                        <div className="modal-buttons">
                            <UnfilledButton
                                borderColor="var(--dark-blue)"
                                onClick={() => setModalVisible(false)}
                                hoverColor="var(--sky-blue)">
                                Annuler
                            </UnfilledButton>
                        </div>
                    </>
                ),
            });
            return;
        }

        let planFilled = 0;
        const otherKeys = ['autres', 'id', 'id_user', 'name', 'typePlan'];

        Object.keys(plan).forEach(key => {
            if (!otherKeys.includes(key)) planFilled += Number(plan[key]);
        });
        if (plan.autres && plan.autres.length) planFilled = 1;

        if (Object.keys(plan).length && !localStorage.getItem('planFinancement') && planFilled) {
            setModalContent({
                title: 'Changer de type',
                content: ModalSwitchTypePlan(),
            });

            setModalVisible(true);
        } else {
            if (e.target.checked) {
                setTypePlan(1);

                mursRef.current.setName(fdcRef.current.getName());
                fdcRef.current.setName('');
            } else {
                setTypePlan(0);

                fdcRef.current.setName(mursRef.current.getName());
                mursRef.current.setName('');
            }
        }
    }

    function displayPlan(data) {
        if (data.numEstimation) getEstimationData(data.numEstimation, data.typePlan);

        setEstimationData({});
        localStorage.removeItem('planFinancement');
        setPlan(data);
        setTypePlan(data.typePlan);

        document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
    }

    async function deletePlan(toDelete) {
        if (toDelete.name === plan.name) {
            setPlan({});
        }
        try {
            await axios.delete(
                process.env.REACT_APP_SERVER_URL +
                    'plan-financement/' +
                    (toDelete.typePlan ? 'murs' : 'fdc') +
                    '/' +
                    toDelete.id,
                {
                    headers: {
                        Authorization: localStorage.getItem('token'),
                    },
                    data: {
                        id_user: id_user,
                    },
                },
            );
            fetchPlansFinancement();
            showNotification('Le plan de financement ' + toDelete.name + ' a été supprimé avec succès');
            localStorage.removeItem('fromSynthese');
        } catch (e) {
            showNotification('Une erreur est survenue', 'var(--red');
        }
        setModalVisible(false);
    }

    function createNewPlan() {
        setPlan({});
        setEstimationData({});
        mursRef.current.resetAutres();
        fdcRef.current.resetAutres();

        document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
    }

    async function getUserSub() {
        try {
            const res = await axios.get(
                process.env.REACT_APP_SERVER_URL + 'plan-financement/user-abo?id_user=' + id_user,
            );
            const typeAbo = res.data.type_abo;
            if (typeAbo) return typeAbo;
            return false;
        } catch (e) {
            return false;
        }
    }

    async function downloadPlan(downloadPlan) {
        if (!(await getUserSub())) {
            setModalVisible(true);
            setModalContent({
                title: 'Plan de financement',
                content: (
                    <>
                        <div className="modal-text">
                            <p>Vous devez être abonné pour télécharger votre plan de financement</p>
                        </div>
                        <div className="modal-buttons">
                            <UnfilledButton
                                onClick={() => setModalVisible(false)}
                                borderColor="var(--dark-blue)"
                                hoverColor="var(--sky-blue)">
                                Annuler
                            </UnfilledButton>
                            <FilledButton to={process.env.REACT_APP_V1_URL + 'offres'}>Voir les offres</FilledButton>
                        </div>
                    </>
                ),
            });
            return;
        }

        localStorage.setItem('downloadPdf', JSON.stringify(plan));
        localStorage.setItem('downloadPdfValues', JSON.stringify(downloadPlan));

        displayPlan(downloadPlan);

        setTimeout(() => {
            let ref = fdcRef;
            if (downloadPlan.typePlan) ref = mursRef;

            ref.current.preparePdf(downloadPlan, downloadPlanCallback);
        }, 200);
    }

    function downloadPlanCallback() {
        const planToDisplay = JSON.parse(localStorage.getItem('downloadPdf'));

        if (Object.keys(planToDisplay).length) displayPlan(planToDisplay);
        else createNewPlan();

        window.scrollTo(0, 0);

        const planValues = JSON.parse(localStorage.getItem('downloadPdfValues'));

        document.getElementById('input-plan-id').value = planValues.id;
        document.getElementById('input-plan-type').value = planValues.typePlan;
        document.getElementById('input-plan-name').value = planValues.name;
        document.getElementById('form-pdf').submit();

        localStorage.removeItem('downloadPdf');
        localStorage.removeItem('downloadPdfValues');
    }

    const CarouselElement = data => (
        <Card className={'centered-card s-between ' + (plan.id === data.data.id ? 'active-plan' : '')} padding=".5rem">
            <div>
                <p className="text-sky-blue text-sm text-center">
                    {!data.data.typePlan ? 'Fonds de commerce' : 'Murs commerciaux'}
                </p>
                <p className="plan-financement-name">{data.data.name}</p>
            </div>
            <div className="plan-financement-card">
                <FilledButton
                    bgColor="var(--gold)"
                    color="var(--dark-blue)"
                    height="30px"
                    onClick={() => displayPlan(data.data)}>
                    Consulter
                </FilledButton>
                <div className="row gap-sm">
                    <Download color="var(--dark-blue)" width="22px" onClick={() => downloadPlan(data.data)} />
                    <Trash
                        onClick={() => {
                            setModalVisible(true);
                            setModalContent({
                                title: 'Supprimer',
                                content: (
                                    <>
                                        <div className="modal-text">
                                            <p>Voulez-vous supprimer le plan de financement {data.data.name} ?</p>
                                        </div>
                                        <div className="modal-buttons">
                                            <UnfilledButton onClick={() => setModalVisible(false)}>
                                                Annuler
                                            </UnfilledButton>
                                            <FilledButton onClick={() => deletePlan(data.data)}>Valider</FilledButton>
                                        </div>
                                    </>
                                ),
                            });
                        }}
                    />
                </div>
            </div>
        </Card>
    );

    const ModalSwitchTypePlan = () => {
        return (
            <>
                <div className="modal-text">
                    <p>Voulez-vous changer le type du plan de financement {plan.name} ?</p>
                    <p>Cette action est irréversible et écrasera les données existantes (excepté son nom).</p>
                </div>
                <div className="modal-buttons">
                    <UnfilledButton
                        onClick={() => setModalVisible(false)}
                        borderColor="var(--dark-blue)"
                        hoverColor="var(--sky-blue)">
                        Annuler
                    </UnfilledButton>
                    <FilledButton onClick={changeTypePlan}>Valider</FilledButton>
                </div>
            </>
        );
    };

    return (
        <PlanFinancementContext.Provider
            value={{
                id_user,
                typePlan,
                plan,
                fetchPlansFinancement,
                plansFinancement,
                estimationData,
                setLoaderVisible,
            }}>
            <form
                id="form-pdf"
                action={process.env.REACT_APP_V1_URL + 'pdf-plan-financement'}
                target="_blank"
                method="POST">
                <input type="hidden" value="" name="input-plan-id" id="input-plan-id" />
                <input type="hidden" value="" name="input-plan-type" id="input-plan-type" />
                <input type="hidden" value="" name="input-plan-name" id="input-plan-name" />
            </form>
            <Loader visible={loaderVisible} />
            {/* <Notification bgColor={notificationColor} visible={notificationVisible} setVisible={setNotificationVisible}>
                {notificationContent}
            </Notification> */}
            <div className="plan-financement-container container">
                {plansFinancement.length > 0 && !fromSynthese && (
                    <>
                        <div className="centered mb-lg">
                            <FilledButton padding="10px 20px" onClick={createNewPlan}>
                                Créer un nouveau plan de financement
                            </FilledButton>
                        </div>
                        <Card padding="30px" className="mb-lg" bgColor="var(--dark-blue)">
                            <div className="s-between mb-3">
                                <h2 className="m-0 white-text">Mes plans de financement</h2>
                                <Financement />
                            </div>
                            <Hr color="var(--gold)" />
                            <Carousel data={plansFinancement} color="white" Element={CarouselElement} />
                        </Card>
                    </>
                )}
                <div className={'row gap-sm mb-sm ' + (!fromSynthese && 'mt-lg')} id="plan-financement-start">
                    {(estimationData.typePlan !== undefined || plan.numEstimation > 0) && (
                        <>
                            <Info color="var(--sky-blue)" width="25px" />
                            <p className="blue-text">
                                Ce plan de financement est relié à l'estimation {estimationData.name}
                            </p>
                        </>
                    )}
                </div>
                {!fromSynthese && (
                    <div className="centered">
                        <Toggle
                            bgColor="var(--sky-blue)"
                            label2="Murs commerciaux"
                            label="Fonds de commerce"
                            name="check-type-plan"
                            checked={typePlan}
                            onChange={switchTypePlan}
                        />
                    </div>
                )}

                <p className="text-sm text-sky-blue centered mb-sm">
                    Note : certains montants calculés automatiquement sont modifiables manuellement
                </p>
                <div>
                    <PlanFinancementFdc componentRef={plansRefs} ref={fdcRef} visible={typePlan === 0} />
                    <PlanFinancementMurs componentRef={plansRefs} ref={mursRef} visible={typePlan === 1} />
                </div>
            </div>
        </PlanFinancementContext.Provider>
    );
}

export default PlanFinancementForm;
