import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/eye.svg';

function Eye({ width = '25px', color = 'var(--white)', className = '', onClick }) {
    return (
        <Icon
            style={{ '--color': color, fill: 'var(--color)' }}
            width={width}
            className={className + (onClick ? ' clickable-icon' : '')}
            onClick={onClick}
        />
    );
}

export default Eye;
