import './Error404.css';
import React from 'react';

function Error404() {
    return (
        <>
        <div style={{width: '100%', height: '100vh'}}>
        <img src={require('../../../assets/errorPage.jpg')} style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
        </div>
        </>
    );
}

export default Error404;
