import './Card.css';
import React from 'react';

function Card({ bgColor, padding, children, className = "" }) {
    return (
        <section className={'card ' + className} style={{ '--background-color': bgColor, '--padding': padding }}>
            {children}
        </section>
    );
}

export default Card;
