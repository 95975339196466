import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './OutilPratiqueDetail.css';
import React, { useContext, useEffect, useState } from 'react';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Download from '../../../components/atoms/icons/general/download/Download';
import InfiniteThumbnailsRow from '../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow';
import AppContext from '../../../context/AppContext';
import { useWindowSize } from '@uidotdev/usehooks';

function OutilPratiqueDetail() {
    const [outil, setOutil] = useState({});
    const [suggestions, setSuggestions] = useState([]);

    const { setModalContent, setModalVisible } = useContext(AppContext);
    const { width } = useWindowSize();

    const userSub = true; // TODO

    useEffect(() => {
        fetchOutil();
        fetchSuggestions();
    }, []);

    async function fetchOutil() {
        //TODO
        const outil = {
            url: 'todo',
            id: '1',
            previewAlt: 'super image',
            title: 'Les 15 points de vérification du bail commercial',
            description: `Si vous avez trouvé un accord pour céder ou acheter une affaire, vous allez devoir vous rapprocher d’un conseil juridique pour la rédaction du compromis de vente.
            </br></br>
            Nos listes de pièces à fournir si vous cédez votre affaire et de pièces à fournir si vous
            achetez une affaire, disponibles dans nos outils pratiques, vous permettent d’anticiper au mieux.
            </br></br>
            En parallèle de la préparation de ces pièces, afin de gagner en clarté et en efficacité, le canevas suivant - qu’il vous suffit de compléter - vous permet d’indiquer au rédacteur du compromis de vente (ou du protocole d’accord) un certain nombre d’éléments dont il aura invariablement besoin et de joindre ce document à votre liste de pièces.
            </br></br>
            Ainsi vous lui faciliterez la compréhension du dossier, la rapidité de traitement et les échanges avec lui sur les questions entourant la cession / acquisition de l’affaire seront plus fluides.`,
            preview: 'Canevas_compromis.jpeg',
            fileName: 'CANEVAS_DE_COMPROMIS_POUR_LE_REDACTEUR_DU_COMPROMIS.pdf',
        };

        setOutil(outil);
    }

    async function fetchSuggestions() {
        // TODO

        const suggestions = [
            {
                title: 'Le crédit-vendeur dans une cession de fonds : ses avantages',
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Les différents acteurs d'une cession de fonds de commerce",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/3/thumbnail/Image_de_tete_Valorisation_murs_commerciaux.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/4/thumbnail/Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: 'Le crédit-vendeur dans une cession de fonds : ses avantages',
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/5/thumbnail/Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Les différents acteurs d'une cession de fonds de commerce",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/6/thumbnail/Image_de_tete_estimation_fonds_commerce.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/7/thumbnail/Image_de_tete_compromis_vente_protocole_accord.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
        ];

        setSuggestions(suggestions);
    }

    function showSubModal() {
        setModalContent({
            title: outil.title,
            content: <p>Profitez de tous nos outils pratiques avec l'offre la mieux adaptée à vos besoins</p>,
            buttons: (
                <a className="button filled-button" href="/tarifs">
                    Voir les offres
                </a>
            ),
        });
        setModalVisible(true);
    }

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Outils: Outils pratiques',
                            to: '/outils-pratiques',
                        },
                        {
                            name: outil.title,
                            to: outil.url,
                        },
                    ]}
                />
                <PageTitle className={width > 1200 ? 'mt-xl' : ''} title={outil.title} />
                <div className="outil-pratique-container">
                    <div>
                        <p>
                            {outil.description &&
                                outil.description.split('</br>').map((line, key) => (
                                    <React.Fragment key={key}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                        </p>
                    </div>
                    <div className="outil-preview-container">
                        {userSub ? (
                            <a
                                className="button filled-button default-button-settings"
                                download={outil.fileName}
                                href={
                                    outil.id &&
                                    outil.fileName &&
                                    require(`../../../uploads/outilsPratiques/${outil.id}/file/${outil.fileName}`)
                                }>
                                Télécharger
                                <Download />
                            </a>
                        ) : (
                            <FilledButton padding={width > 1200 ? '20px' : '5px 20px'} onClick={showSubModal}>
                                Télécharger
                                <Download />
                            </FilledButton>
                        )}
                        <img
                            src={
                                outil.id &&
                                outil.preview &&
                                require(`../../../uploads/outilsPratiques/${outil.id}/preview/${outil.preview}`)
                            }
                            alt={outil.previewAlt}
                        />
                    </div>
                </div>
            </section>
            <InfiniteThumbnailsRow
                title={
                    <h2 className={'mb-md container ' + (width <= 992 ? 'text-center' : '')}>
                        Découvrez nos conseils de pros sur cette thématique :
                    </h2>
                }
                data={suggestions}
            />
            <FilledButton className={'mid-page-button ' + (width <= 1200 ? 'mt-md' : 'mt-xl')} to="/conseils-pros">
                Voir tous nos conseils de pros
            </FilledButton>
            <Footer />
        </>
    );
}

export default OutilPratiqueDetail;
