import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/headerIcons/power.svg';

function Power({ width = '25px', color = 'var(--blue)', className }) {
    return (
        <Icon
            style={{ '--color': color, fill: 'var(--color)', stroke: 'var(--color)' }}
            stroke={color}
            width={width}
            height={width}
            className={className}
        />
    );
}

export default Power;
