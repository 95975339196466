import './Banner.css';
import React from 'react';

function Banner({ title, subtitle, className = '' }) {
    return (
        <div className={'banner-container ' + className}>
            <h1>{title}</h1>
            <hr className="banner-hr" />
            <p>{subtitle}</p>
        </div>
    );
}

export default Banner;
