import { useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './TrucsAstuces.css';
import React, { useEffect, useState } from 'react';
import ThumbnailsGroup from '../../../components/organisms/thumbnailsGroup/ThumbnailsGroup';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Thumbnail from '../../../components/molecules/thumbnail/Thumbnail';

function TrucsAstuces() {
    const [articles, setArticles] = useState([]);

    const { _, width } = useWindowSize();

    useEffect(() => {
        fetchArticles();
    }, []);

    async function fetchArticles() {
        //TODO

        const data = [
            {
                id: '10',
                title: '0compte courant d’associé : définition, fonctionnement et cession',
                url: 'benchmark-concurrentiel',
                picture: 'Image_benchmark_concurrentiel.jpg',
            },
            {
                id: '8',
                title: '1Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Cession_droit_bail.jpeg',
            },
            {
                id: '7',
                title: "2Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_compromis_vente_protocole_accord.jpeg',
            },
            {
                id: '6',
                title: "3Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_estimation_fonds_commerce.jpeg',
            },
            {
                id: '5',
                title: '4Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
            },
            {
                id: '4',
                title: "5Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
            },
            {
                id: '12',
                title: "6Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                url: 'benchmark-concurrentiel',
                picture: 'Image_Visite_affaire_bien_structuree.jpg',
            },
            {
                id: '3',
                title: '7Le crédit-vendeur dans une cession de fonds : ses avantages',
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Valorisation_murs_commerciaux.jpeg',
            },
            {
                id: '2',
                title: "8Les différents acteurs d'une cession de fonds de commerce",
                url: 'benchmark-concurrentiel',
                picture: 'Image_de_tete_Vente_fonds_commerce.svg',
            },
        ];

        data.map((article, index) => {
            data[index] = {
                title: article.title,
                picture: '/trucsAstuces/' + article.id + '/thumbnail/' + article.picture,
                link: article.url,
                linkText: "Lire l'article",
            };
        });

        setArticles(data);
    }

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Conseils: Trucs & astuces agents immos',
                            to: '/trucs-astuces',
                        },
                    ]}
                />
                <Banner
                    title={<>Agents immos :{width <= 1200 ? <br /> : <>&nbsp;</>}nos trucs & astuces</>}
                    subtitle="Abonnez-vous en fin de page à notre Newsletter pour recevoir nos méthodes métiers tous les mois"
                    className="mb-xl"
                />
                {width > 576 && <div className="py-md" />}
                {width > 576 && articles.length ? (
                    <ThumbnailsGroup data={articles} />
                ) : (
                    <>
                        <div className="mobile-thumbnails-col">
                            {articles.map((article, key) => {
                                return (
                                    <Thumbnail
                                        link={article.link}
                                        key={key}
                                        title={article.title}
                                        picture={article.picture}
                                        linkText={article.linkText}
                                    />
                                );
                            })}
                        </div>
                        <div className="centered">
                            <FilledButton to="/debut-parcours">Je démarre une estimation</FilledButton>
                        </div>
                    </>
                )}
            </section>
            <Footer />
        </>
    );
}

export default TrucsAstuces;
