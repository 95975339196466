import { useForm } from 'react-hook-form';
import './SimulateurCreditForm.css';
import React from 'react';
import NumberInput from '../../molecules/formComponents/numberInput/NumberInput';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Input from '../../molecules/formComponents/input/Input';

function SimulateurCreditForm() {
    const { register, setValue, watch } = useForm();
    const Form = { register, setValue, watch };

    return (
        <>
            <fieldset className="borderless-fieldset simulateur-credit-container">
                <NumberInput useForm={Form} icon="euro" name="montantPret" label="Montant du prêt *" />
                <div className="row-1000">
                    <NumberInput useForm={Form} icon="percent" name="tauxHorsAssurance" label="Taux hors assurance *" />
                    <NumberInput useForm={Form} icon="percent" name="tauxAssurance" label="Taux assurance *" />
                </div>
                <div className="row-1000">
                    <NumberInput useForm={Form} name="duree" label="Durée (années) *" />
                    <Input
                        type="date"
                        useForm={Form}
                        name="dateDepart"
                        label="Date de départ du tableau d'amortissement *"
                    />
                </div>
                <div className="centered mt-md">
                    <FilledButton padding="10px 20px">Calculer</FilledButton>
                </div>
            </fieldset>
        </>
    );
}

export default SimulateurCreditForm;
