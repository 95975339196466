import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import MethodeTitle from '../../../components/molecules/methodeTitle/MethodeTitle';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import arnaud from '../../../assets/profilePics/arnaud.png';
import clement from '../../../assets/profilePics/clement.png';
import Linkedin from '../../../components/atoms/icons/share/linkedin/Linkedin';
import TextHr from '../../../components/molecules/textHr/TextHr';
import './Methode.css';
import React from 'react';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import accordCommerces from '../../../assets/methode/accordCommerces.webp';
import banquier from '../../../assets/methode/banquier.svg';
import boiteOutils from '../../../assets/methode/boiteOutils.webp';
import confidentialite from '../../../assets/methode/confidentialite.svg';
import expertise from '../../../assets/methode/expertise.svg';
import fusee from '../../../assets/methode/fusee.svg';
import fuseeMonsieur from '../../../assets/methode/fuseeMonsieur.webp';
import galaxie from '../../../assets/methode/galaxie.svg';
import ideeEntrepreneur from '../../../assets/methode/ideeEntrepreneur.svg';
import intermediaires from '../../../assets/methode/intermediaires.svg';
import mindmap from '../../../assets/methode/mindmap.svg';
import neutralite from '../../../assets/methode/neutralite.svg';
import outilsDashboard from '../../../assets/methode/outilsDashboard.svg';
import outilsMonsieur from '../../../assets/methode/outilsMonsieur.svg';
import partage from '../../../assets/methode/partage.svg';
import partenairesExploitant from '../../../assets/methode/partenairesExploitant.svg';
import proprietaireImmo from '../../../assets/methode/proprietaireImmo.svg';
import questionFemme from '../../../assets/methode/questionFemme.svg';
import rapportDetaille from '../../../assets/methode/rapportDetaille.svg';
import reseauFrance from '../../../assets/methode/reseauFrance.svg';
import balanceHotels from '../../../assets/methode/balanceHotels.webp';
import commerceCommercants from '../../../assets/methode/commerceCommercants.webp';
import commerceCompta from '../../../assets/methode/commerceCompta.webp';
import evolution from '../../../assets/methode/evolution.svg';
import hommeExplique from '../../../assets/methode/hommeExplique.svg';
import profitabilite from '../../../assets/methode/profitabilite.svg';
import valorisationAffaire from '../../../assets/methode/valorisationAffaire.svg';
import engrenages from '../../../assets/methode/engrenages.svg';
import coiffeur from '../../../assets/methode/coiffeur.webp';
import monsieurCommerce from '../../../assets/methode/monsieurCommerce.webp';
import rapportOutils from '../../../assets/methode/rapportOutils.svg';
import exempleRapport from '../../../assets/methode/exempleRapport.svg';
import euroClair from '../../../assets/methode/euroClair.svg';
import euroMoyen from '../../../assets/methode/euroMoyen.svg';
import euroFonce from '../../../assets/methode/euroFonce.svg';
import Commerce from '../../../components/atoms/icons/general/commerce/Commerce';
import Usine from '../../../components/atoms/icons/general/usine/Usine';
import Immeuble from '../../../components/atoms/icons/general/immeuble/Immeuble';
import { useWindowSize } from '@uidotdev/usehooks';
import RoundedDiv from '../../../components/atoms/icons/general/roundedDiv/RoundedDiv';
import DottedAngle from '../../../components/atoms/methode/icons/dottedAngle/DottedAngle';
import DottedPath from '../../../components/atoms/methode/icons/dottedPath/DottedPath';
import DottedLine from '../../../components/atoms/methode/icons/dottedLine/DottedLine';
import MethodeRowTextImg from '../../../components/molecules/methodeRowTextImg/MethodeRowTextImg';
import MethodeRowBorders from '../../../components/molecules/methodeRowBorders/MethodeRowBorders';

function Methode() {
    const { width } = useWindowSize();
    return (
        <>
            <Header />
            <section className="container methode-container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Estimer: Méthode & philosophie',
                            to: '/methode',
                        },
                    ]}
                />
                <Banner
                    title="Méthode & philosophie"
                    subtitle={
                        <>
                            Qui sommes-nous ? Quelles méthodes sont utilisées pour vos estimations ? <br />
                            Voici nos valeurs, notre philosophie et notre méthode.
                        </>
                    }
                />
                <MethodeTitle
                    number={1}
                    title="la petite histoire"
                    subtitle="Rencontre d'un spécialiste de la cession et d'un expert du digital"
                />
                <div className="row-500 mt-xl gap-lg space-around profile-pics-container">
                    <div className="centered">
                        <img src={arnaud} alt="arnaud villeroy ceo" />
                        <div className="centered">
                            <p className="my-md text-lg basic-sans-bold">Arnaud VILLEROY</p>
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="#0077B5"
                                hoverColor="#0077B5"
                                to="https://www.linkedin.com/in/arnaudvilleroy/"
                                rel="nofollow"
                                target="_blank">
                                <Linkedin />
                            </FilledButton>
                        </div>
                    </div>
                    <div className="centered">
                        <img src={clement} alt="clement gippet cto" />
                        <div className="centered">
                            <p className="my-md text-lg basic-sans-bold">Clément GIPPET</p>
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="#0077B5"
                                hoverColor="#0077B5"
                                to="https://www.linkedin.com/in/clement-gippet/"
                                rel="nofollow"
                                target="_blank">
                                <Linkedin />
                            </FilledButton>
                        </div>
                    </div>
                </div>
                <p className={'mt-xl ' + (width > 992 ? 'line-height-lg' : '')}>
                    Arnaud Villeroy, après une 1ère vie d’exploitant de CHR, a passé 15 ans sur le terrain au contact
                    des commerçants & entrepreneurs, des repreneurs et de leur écosystème (juristes, comptables,
                    banques, assurances, cabinets de contrôle, administration, etc.).
                    <br />
                    <br />A la tête de son cabinet spécialisé en cessions de commerces et entreprises, Arnaud a
                    accompagné intégralement plus de 300 repreneurs et cédants.
                </p>
                <div className={'mt-xxl ' + (width > 1200 ? 'mx-xxl' : '')}>
                    <h3 className={width > 1200 ? '' : 'centered mb-lg'}>
                        Une vision globale acquise par l’orchestration des cessions
                    </h3>
                    <div className="row-1000-reverse space-between gap-lg">
                        <div className="centered">
                            <ul className={'dots-list ' + (width > 1200 ? 'line-height-lg' : '')}>
                                <li>Prospection, analyse et évaluation des affaires</li>
                                <li>Recherche des repreneurs, présentation des dossiers de reprise</li>
                                <li>Rencontres, négociations et encadrement des étapes de la cession</li>
                                <li>Montage, recherche et obtention des financements</li>
                                <li>Formalités de transmission / reprise</li>
                                <li>Encadrement du closing et de l'accompagnement</li>
                            </ul>
                        </div>
                        <div className="centered">
                            <img className="methode-img" src={accordCommerces} alt="TODO" />
                        </div>
                    </div>
                    <MethodeRowTextImg
                        alt="TODO"
                        img={ideeEntrepreneur}
                        textMaxWidth="500px"
                        title=""
                        maxWidth="350px"
                        textMargin="auto    ">
                        <p className="gold-before-section">
                            Grâce à un travail rigoureux sur chaque cession (et ses particularités), Arnaud a acquis les
                            bons réflexes en matière commerciale, comptable, fiscale, normative et sociale. L'ensemble
                            de ces compétences lui a permis d'acquérir une vue d'ensemble du marché de la cession, dont
                            la 1ère étape est la valorisation de chaque affaire.
                        </p>
                    </MethodeRowTextImg>
                    <div
                        className={
                            'row-1000 mt-xxl ' + (width > 1200 ? 'gap-xl space-around' : 'space-between gap-md')
                        }>
                        <div className="gradient-card-container">
                            {width <= 991 && <img src={questionFemme} className="methode-img" alt="TODO" />}
                            <div className="gradient-card" style={{ '--color': 'var(--light-blue)' }}>
                                <p className="basic-sans-semibold-it">
                                    « Si les exploitants œuvrent au quotidien pour la pérennité de leur affaire, ils
                                    sont mal informés sur sa valorisation et sa transmissibilité.
                                    <br /> Leurs conseillers (immobiliers, comptables, juridiques) sont mal armés pour
                                    répondre à ces problématiques »
                                </p>
                            </div>
                            {width > 991 && <img src={questionFemme} className="methode-img" alt="TODO" />}
                        </div>
                        <div className="gradient-card-container">
                            <img src={outilsMonsieur} className="methode-img" alt="TODO" />
                            <div className="gradient-card" style={{ '--color': 'var(--beige)' }}>
                                <p className="basic-sans-semibold-it">
                                    « Au fil du temps, nous avons ainsi appris qu’au-delà de la théorie sur la
                                    valorisation et la cession de commerces et d’entreprises, la réalité est bien plus
                                    complexe et ne figure dans aucun manuel.
                                    <br /> Nous avons donc souhaité aider le plus grand nombre à l’aide du digital en
                                    démocratisant l’accès à une expertise métier, réservée jusque-là à un petit nombre
                                    d’acteurs. »
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={'mt-xxl gradient-border-container ' + (width > 576 ? 'mx-xl' : '')}>
                        <div className="methode-quote-container">
                            <p className="methode-quote">
                                l’estimation d’un commerce ou d’une entreprise, au même titre que sa transmission, est
                                un exercice délicat.
                                <br /> CAR ELLE DÉPEND DE NOMBREUX PARAMÈTRES D’EXPLOITATION.
                            </p>
                        </div>
                    </div>
                </div>
                <p className="line-height-lg mt-xl">
                    C’est là qu’entre en scène Clément Gippet, spécialiste du digital.
                    <br />
                    <br />
                    Sa maîtrise des dernières technologies et son goût pour le sur-mesure numérique, couplés au
                    savoir-faire de terrain et l’expérience d’Arnaud, ont donné naissance à un algorithme dynamique,
                    éprouvé par de longues phases de tests intensifs.
                </p>
                <div className={'mt-xl ' + (width > 1200 ? 'mx-xl' : '') + (width > 576 ? '' : ' d-none')}>
                    <div className={'row-1000 space-around ' + (width > 1200 ? 'gap-xl mx-lg' : 'gap-xl')}>
                        <div className="gradient-card-container">
                            <img className="methode-img" src={fusee} alt="TODO" />
                            <div className="gradient-card" style={{ '--color': 'var(--beige)' }}>
                                <p className="basic-sans-semibold-it">
                                    « Notre priorité, paradoxalement à la précision de calcul de notre algorithme et son
                                    niveau de sophistication, a été de doter l’utilisateur d’une interface simple,
                                    presque ludique, puisque c’est lui qui renseigne les données aboutissant à
                                    l’estimation.
                                    <br /> Nous avons donc eu à cœur de créer un parcours de saisie fluide et rapide,
                                    jalonné de tutos clairs, pour une expérience utilisateur agréable. »
                                </p>
                            </div>
                        </div>
                        {width > 992 ? (
                            <img className="methode-img my-auto" src={outilsDashboard} alt="TODO" />
                        ) : (
                            <div className="centered">
                                <img className="methode-img my-auto" src={outilsDashboard} alt="TODO" />
                            </div>
                        )}
                    </div>
                </div>
                <div className={'mt-xxl ' + (width > 1200 ? 'mx-xxl px-xl' : '')}>
                    <div className={'gradient-border-container' + (width > 576 ? ' mx-xl' : '')}>
                        <div className={width > 576 ? 'p-lg' : 'p-md'}>
                            <p
                                className={
                                    'basic-sans-bold centered ' +
                                    (width > 1200 ? 'text-lg ' : '') +
                                    (width > 576 ? 'mb-lg' : 'mb-sm')
                                }>
                                Puissant outil digital, l’algorithme EstimerMonCommerce.fr possède les capacités
                                d’analyse comparables à celle d’un évaluateur professionnel « physique » aguerri.
                            </p>
                            <p className={'centered ' + (width > 1200 ? 'text-lg' : '')}>
                                En adoptant une démarche de vision globale opérationnelle, EstimerMonCommerce.fr vous
                                permet ainsi d’estimer vous-même en ligne la valeur d’un fonds de commerce, de titres de
                                société ou de murs commerciaux, en toute confidentialité.
                            </p>
                        </div>
                    </div>
                    <div className="centered mt-xxl">
                        <FilledButton
                            padding="10px 25px"
                            fontSize={width > 1200 ? '1.3rem' : '1rem'}
                            to="/debut-parcours">
                            Je démarre mon estimation
                        </FilledButton>
                    </div>
                    <h2>
                        <TextHr
                            colors="transparent, var(--dark-blue), var(--gold), var(--gold), var(--gold), var(--dark-blue), transparent"
                            fontSize="3rem"
                            className="mt-xxl">
                            Nos valeurs
                        </TextHr>
                    </h2>
                </div>
                <div className={'row-1000 mt-xl methode-valeurs ' + (width <= 992 && width > 576 ? 'gap-xl' : '')}>
                    <div className="row-500 space-around w-100">
                        <div>
                            {width > 576 && <img src={expertise} alt="TODO" className="methode-img-xs" />}
                            <div className="centered relative">
                                <p className={'text-lg mt-lg ' + (width > 1200 ? 'basic-sans-bold' : '')}>EXPERTISE</p>
                                <div className="gold-dot" />
                            </div>
                        </div>
                        <div className="mt-auto">
                            {width > 576 && <img src={confidentialite} alt="TODO" className="methode-img-xs" />}
                            <div className="centered relative">
                                <p className={'text-lg mt-lg ' + (width > 1200 ? 'basic-sans-bold' : '')}>
                                    CONFIDENTIALITÉ
                                </p>
                                <div className="gold-dot" />
                            </div>
                        </div>
                    </div>
                    <div className="row-500 space-around w-100">
                        <div>
                            {width > 576 && <img src={partage} alt="TODO" className="methode-img-xs" />}
                            <div className="centered relative">
                                <p className={'text-lg mt-lg ' + (width > 1200 ? 'basic-sans-bold' : '')}>PARTAGE</p>
                                <div className="gold-dot" />
                            </div>
                        </div>
                        <div className={width > 1200 ? 'mt-xl' : ''}>
                            {width > 576 && <img src={neutralite} alt="TODO" className="methode-img-xs" />}
                            <div className="centered relative">
                                <p className={'text-lg mt-lg ' + (width > 1200 ? 'basic-sans-bold' : '')}>NEUTRALITÉ</p>
                            </div>
                        </div>
                    </div>
                </div>
                <MethodeTitle
                    number={2}
                    className="mt-xxl"
                    title="la philosophie"
                    subtitle={
                        <>
                            Vous permettre d’estimer facilement en ligne la valeur d’une affaire, sans
                            <br /> avoir de connaissances particulière en comptabilité ou en évaluation.
                        </>
                    }
                />
                <div className={'mt-xl ' + (width > 1200 || (width <= 992 && width > 576) ? 'mx-xl' : '')}>
                    <div className="row-1000 space-between gap-xl">
                        <div className="centered">
                            <img
                                src={fuseeMonsieur}
                                className={'methode-img-philo ' + (width > 1200 ? 'my-auto' : '')}
                                alt="TODO"
                            />
                            <p className={'centered mt-lg ' + (width > 1200 ? 'mx-lg' : '')}>
                                Apporter une solution fiable aux acteurs de la cession de commerces et entreprises,
                                ainsi qu’à tout l’écosystème des cédants et repreneurs, bailleurs
                            </p>
                        </div>
                        <div className="centered">
                            <img
                                src={rapportDetaille}
                                className={'methode-img-philo ' + (width > 1200 ? 'my-auto' : '')}
                                alt="TODO"
                            />
                            <p className={'centered mt-lg ' + (width > 1200 ? 'mx-lg' : '')}>
                                Délivrer un rapport d’évaluation compréhensible, directement exploitable pour envisager
                                une cession, ou optimiser une affaire et sa valeur
                            </p>
                        </div>
                        <div className="centered">
                            <img
                                src={boiteOutils}
                                className={'methode-img-philo ' + (width > 1200 ? 'my-auto' : '')}
                                alt="TODO"
                            />
                            <p className={'centered mt-lg ' + (width > 1200 ? 'mx-lg' : '')}>
                                Partager notre savoir-faire de la transmission de commerce et d'entreprise avec des
                                conseils professionnels pratiques et d’outils fonctionnels dédiés
                            </p>
                        </div>
                    </div>
                    <div className="mt-xxl centered">
                        <FilledButton
                            padding="10px 25px"
                            fontSize={width > 1200 ? '1.3rem' : '1rem'}
                            to="/debut-estimation">
                            Démarrer mon estimation
                        </FilledButton>
                    </div>
                </div>
                <div className="mt-xxl galaxy-container">
                    <img src={galaxie} alt="TODO" />
                    <div>
                        <div />
                        <p className="centered">L’écosystème de la {width <= 992 && <br />}cession / transmission</p>
                    </div>
                </div>
                <div className={'mt-xxl ' + (width > 1200 ? 'mx-xxl' : '')}>
                    <MethodeRowBorders
                        title="Les intermédiaires qui orchestrent les cessions"
                        content="Agents immobiliers, réseaux mandataires, cabinets d’affaires et conseillers en reprise d’entreprise"
                        alt="TODO"
                        img={intermediaires}
                    />
                    <MethodeRowBorders
                        imgRight
                        title="Les conseils qui accompagnent les transmissions, les gestions de patrimoine et les litiges"
                        content="Experts-comptables, avocats, notaires, mandataires judiciaires, experts-immobiliers"
                        alt="TODO"
                        img={partenairesExploitant}
                    />
                    <MethodeRowBorders
                        title="Les réseaux qui arbitrent leurs parcs de points de vente"
                        content="Enseignes, franchiseurs, succursalistes, groupes de commerces ou de services"
                        alt="TODO"
                        img={reseauFrance}
                    />
                    <MethodeRowBorders
                        imgRight
                        title="Les propriétaires d’immobilier commercial"
                        content="Bailleurs, foncières et centres commerciaux"
                        alt="TODO"
                        img={proprietaireImmo}
                    />
                    <MethodeRowBorders
                        title="Les partenaires du quotidien des entrepreneurs"
                        content="Banques, assurances, courtiers, organismes publics, incubateurs, associations"
                        alt="TODO"
                        img={banquier}
                    />
                </div>
                <div className="mt-xxl centered">
                    <FilledButton
                        fontSize={width > 1200 ? '1.3rem' : '1rem'}
                        padding="10px 25px"
                        to="/debut-estimation">
                        Démarrer le parcours d'estimation
                    </FilledButton>
                </div>
                <MethodeTitle
                    number={3}
                    title="la méthode"
                    className="mt-xxl"
                    subtitle={
                        <>
                            L’expérience nous a appris que la valeur d’une affaire ne se résume pas à des ratios
                            comptables, {width > 1200 && <br />}à un emplacement, ou aux seuls souhaits du cédant : elle
                            résulte d’un juste équilibre entre ces {width > 1200 && <br />} paramètres, l’approche de
                            potentiels repreneurs et la finançabilité du projet.
                        </>
                    }
                />
                <div className="centered mt-xxl">
                    <Commerce width={width > 1200 ? '150px' : '100px'} />
                    <h2 className={'basic-sans-semibold ' + (width > 1200 ? 'text-xxl mt-lg' : 'text-xl mt-md')}>
                        L'estimation du fonds de commerce
                    </h2>
                </div>
                <div className={'mt-xxl row methode-estimation-fdc ' + (width > 992 || width <= 576 ? '' : 'centered')}>
                    <div>
                        <p className={'basic-sans-semibold ' + (width > 1200 ? 'text-xl' : 'text-lg')}>
                            EstimerMonCommerce.fr réalise l’estimation de l'affaire sur mesure.
                        </p>
                        <p className={width > 1200 ? 'mt-lg basic-sans-semibold' : 'mt-md'}>
                            En s'adaptant à son contexte spécifique et en intégrant toutes les informations pertinentes
                            caractérisant chaque exploitation :
                        </p>
                        <ul className="dots-list line-height-lg mt-lg" style={{ '--color': '#C0CEFF' }}>
                            <li>Chiffre d’affaires</li>
                            <li>Rentabilité retraitée</li>
                            <li>Emplacement</li>
                            <li>Etat locatif</li>
                            <li>Dynamique commerciale</li>
                            <li>Attributs & local d’activité</li>
                            <li>Normes</li>
                            <li>Ratios-clés</li>
                        </ul>
                    </div>
                    <img src={mindmap} alt="TODO" className="methode-img-lg ml-xl" />
                </div>
                <div className={'mt-xxl ' + (width > 1200 ? 'mx-xxl' : '')}>
                    <p className="basic-sans-semibold text-xl">Une base de plusieurs méthodes reconnues</p>
                    <hr className="methode-hr banner-hr" />
                    <p className="basic-sans-semibold text-lg">
                        Votre estimation est réalisée sur la base de plusieurs méthodes d'estimation reconnues :
                    </p>
                </div>
                <MethodeRowTextImg alt="TODO" img={commerceCommercants} title="La méthode par la rentabilité retraitée">
                    <p>
                        à laquelle l’on applique un coefficient multiplicateur selon le secteur d’activité.
                        <br />
                        <br /> Cette méthode s’appuie sur la capacité de l'affaire à réaliser des bénéfices pour assurer
                        la finançabilité du projet et un revenu décent pour l’exploitant.
                        <br />
                        <br /> C’est la méthode utilisée par les banques, les repreneurs et les professionnels de la
                        cession.
                    </p>
                </MethodeRowTextImg>
                <MethodeRowTextImg
                    alt="TODO"
                    imgRight
                    img={commerceCompta}
                    title="La méthode par le chiffre d’affaires">
                    <p>
                        auquel l’on applique un coefficient multiplicateur selon le secteur d’activité.
                        <br />
                        <br /> C’est la méthode retenue par l’administration fiscale.
                    </p>
                </MethodeRowTextImg>
                <MethodeRowTextImg alt="TODO" img={balanceHotels} title="La méthode comparative">
                    <p>
                        qui s’appuie sur l’étude des cessions réalisées sur des affaires le plus similaire possible et
                        dont le prix de vente est connu.
                        <br />
                        <br />
                        C’est la méthode à laquelle ont recours les experts immobiliers.
                    </p>
                </MethodeRowTextImg>
            </section>
            <RoundedDiv color="var(--beige)" className="methode-margin-xxl-900 mt-xxl" />
            <section className="bg-beige methode-container">
                <div className="container">
                    <MethodeRowTextImg
                        maxWidth="300px"
                        alt="TODO"
                        img={evolution}
                        imgRight
                        textMargin="0"
                        title="Nous suivons l'évolution des cessions officielles">
                        <p>
                            Ceci afin d'ajuster continuellement nos facteurs d'évaluation.
                            <br />
                            <br />
                            Ainsi, vos estimations sont en permanence adaptées aux contextes locaux ou spécifiques.
                        </p>
                    </MethodeRowTextImg>
                </div>
            </section>
            {width <= 576 && (
                <>
                    <RoundedDiv color="var(--beige)" rotation="180deg" />
                    <RoundedDiv color="var(--beige)" className="methode-margin-xxl-900" />
                </>
            )}
            <section className="bg-beige methode-container">
                <div className="container">
                    <MethodeRowTextImg
                        maxWidth="300px"
                        alt="TODO"
                        img={profitabilite}
                        title="Le taux de profitabilité"
                        className="mb-xxl">
                        <p>
                            Nous avons ajouté une méthode, consistant à valoriser une affaire en fonction de son ratio
                            rentabilité / CA.
                            <br />
                            <br />
                            Cette méthode traduit le niveau d’attractivité de l’affaire par rapport à d’autres affaires.
                            <br />
                            <br />
                            Et reflète le choix d’un acquéreur envers une affaire plutôt qu’une autre.
                        </p>
                    </MethodeRowTextImg>
                </div>
            </section>
            <RoundedDiv color="var(--beige)" rotation="180deg" />
            <section className="container methode-container">
                <div className="analyse-title">
                    {width > 576 && <img src={hommeExplique} alt="TODO" className="methode-img-xs" />}
                    <h2 className={'basic-sans-semibold ' + (width > 992 ? 'text-xxl' : 'text-xl')}>
                        Aller plus loin dans l'analyse
                    </h2>
                    {width > 576 && <img src={engrenages} alt="TODO" className="methode-img-xs" />}
                </div>
                <div className={width > 576 ? 'centered' : ''}>
                    <p className={'basic-sans-semibold ' + (width > 992 ? 'text-xl' : 'text-lg')}>
                        De l'activité réalisée... à celle présente et future
                    </p>
                    <p className={'mt-lg ' + (width > 576 ? 'mx-xxl text-lg' : '')}>
                        De nombreuses affaires connaissent leurs performances en cours ou à venir avec précision. Il est
                        important de pouvoir les intégrer dans l’évaluation.
                    </p>
                    <p className={'mt-lg ' + (width > 576 ? 'text-lg' : '')}>
                        C’est vous qui choisissez le nombre d’années (de 1 à 4) constituant votre évaluation :
                    </p>
                    <div
                        className={
                            'row-1000 methode-annee-container mt-xl ' + (width > 1200 ? 'mx-xl gap-lg' : ' gap-sm')
                        }>
                        <div className={'row-500 ' + (width > 576 ? 'gap-lg' : 'gap-sm')}>
                            <div className="methode-annee" style={{ '--bg-color': '#252B41' }}>
                                <div>N-1</div>
                                <p>Le dernier exercice comptable</p>
                            </div>
                            <div className="methode-annee" style={{ '--bg-color': '#3B306E' }}>
                                <div>N-2</div>
                                <p>L'avant-dernier exercice comptable</p>
                            </div>
                        </div>
                        <div className={'row-500 ' + (width > 576 ? 'gap-lg' : 'gap-sm')}>
                            <div className="methode-annee" style={{ '--bg-color': '#6064D6' }}>
                                <div>N</div>
                                <p>L’exercice en cours (ou terminé mais le bilan n’est pas encore disponible)</p>
                            </div>
                            <div
                                className="methode-annee"
                                style={{ '--bg-color': '#E7EAF5', '--color': 'var(--dark-blue)' }}>
                                <div>N+1</div>
                                <p>L’année prochaine (prévisionnelle)</p>
                            </div>
                        </div>
                    </div>
                    <p className={'mt-lg ' + (width > 576 ? 'text-lg' : '')}>
                        L’estimation est réalisée sur la moyenne des années que vous avez choisies.
                    </p>
                </div>
                <div className={'mt-xxl ' + (width > 576 ? 'mx-xxl' : '')}>
                    <h2 className={'basic-sans-semibold ' + (width > 1200 ? 'text-xxl' : 'text-xl text-center')}>
                        ...Et encore plus loin avec une approche
                        {width > 1200 && <br />} opérationnelle globale inédite
                    </h2>
                </div>
                <div
                    className={
                        'methodes-utilisees approche-inedite ' +
                        (width > 1200 ? 'mx-xxl gap-xxl row mt-xxl' : 'centered mt-xl')
                    }>
                    {width > 576 && (
                        <img
                            src={valorisationAffaire}
                            alt="TODO"
                            className={width > 1200 ? 'methode-img-400' : 'methode-img-xs'}
                        />
                    )}
                    <div className={'my-auto ' + (width > 576 ? 'mx-xl' : 'text-left')}>
                        <p
                            className={
                                'basic-sans-bold  mb-lg ' +
                                (width > 1200 ? 'text-xl' : width > 576 ? ' text-lg mt-lg' : ' text-lg')
                            }>
                            Une affaire, ce ne sont pas que des chiffres.
                        </p>
                        <p>
                            C’est aussi la réalité de l’exploitation au quotidien, ses forces et faiblesses.
                            <br />
                            <br />
                            Votre estimation intègre 40 facteurs non-comptables, chacun pesant sur l’évaluation selon
                            son importance. Les facteurs varient selon l’activité évaluée.
                        </p>
                    </div>
                </div>
                <div className="mt-xxl">
                    {width <= 576 && (
                        <p className="basic-sans-semibold text-xl mx-lg centered my-sm">
                            Ces 40 facteurs sont répartis en 6 catégories
                        </p>
                    )}
                    <div className={'f-center row-500 ' + (width > 576 ? 'gap-xl' : 'mt-lg')}>
                        <div className="methode-facteurs" style={{ '--bg-color': '#252B41' }}>
                            <p>Emplacement & rayonnement de l’affaire</p>
                            {width > 576 ? <DottedPath /> : <DottedAngle />}
                        </div>
                        <div className="methode-facteurs" style={{ '--bg-color': '#180047' }}>
                            <p>Caractéristiques du local d’activité</p>
                            {width > 576 ? <DottedLine /> : <DottedAngle rotation="180deg" />}
                        </div>
                        <div className="methode-facteurs" style={{ '--bg-color': '#3B306E' }}>
                            <p>Loyer et bail commercial</p>
                            {width > 576 ? <DottedPath rotation="180deg" /> : <DottedAngle />}
                        </div>
                    </div>
                    {width > 576 && (
                        <p className="basic-sans-semibold text-xxl centered my-sm">
                            Ces 40 facteurs sont répartis en 6 catégories
                        </p>
                    )}
                    <div className={'f-center methode-facteurs-2nd-line row-500 ' + (width > 576 ? 'gap-xl' : '')}>
                        <div className="methode-facteurs" style={{ '--bg-color': '#6064D6' }}>
                            {width > 576 ? <DottedPath rotation="180deg" /> : <DottedAngle rotation="180deg" />}
                            <p>Normes et accessibilité</p>
                        </div>
                        <div
                            className="methode-facteurs"
                            style={{ '--bg-color': '#B1BEEE', '--color': 'var(--dark-blue)' }}>
                            {width > 576 ? <DottedLine /> : <DottedAngle />}
                            <p>Matériel et agencements</p>
                        </div>
                        <div
                            className="methode-facteurs"
                            style={{ '--bg-color': '#E7EAF5', '--color': 'var(--dark-blue)' }}>
                            {width > 576 ? <DottedPath /> : <></>}
                            <p>Ratios clefs, notoriété et facteurs exceptionnels</p>
                        </div>
                    </div>
                </div>
                <p className={'basic-sans-semibold centered mt-xxl ' + (width > 1200 ? ' text-xxl' : 'text-lg')}>
                    Vous pouvez estimer plus de
                    <br />
                    <span className="methode-nombre-activites">300 activités</span>
                </p>
                <ul
                    className={
                        'dots-list line-height-lg mt-lg ' + (width > 992 ? 'mx-xxl' : width > 576 ? 'mx-xl' : '')
                    }
                    style={{ '--color': '#C0CEFF' }}>
                    <li className={width > 1200 ? 'text-lg' : ''}>Commerces ET Entreprises</li>
                    <li className={width > 1200 ? 'text-lg' : ''}>
                        TPE, PME, EI, sté de biens ou services, artisan, prof. libérale...
                    </li>
                    <li className={width > 1200 ? 'text-lg' : ''}>Avec ou sans pignon sur rue</li>
                    <li className={width > 1200 ? 'text-lg' : ''}>Avec ou sans local commercial</li>
                    <li className={width > 1200 ? 'text-lg' : ''}>Avec ou sans bail commercial</li>
                </ul>
                {width > 1200 && (
                    <p className="mt-lg centered text-lg">
                        Chaque activité relève d’un barème spécifique, actualisé dans le temps.
                    </p>
                )}
                <div className={'centered ' + (width > 1200 ? 'mt-xxl' : 'mt-xl')}>
                    <FilledButton padding="10px 25px" fontSize={width > 1200 ? '1.3rem' : '1rem'} to="/debut-parcours">
                        Je démarre mon estimation
                    </FilledButton>
                </div>
                <div className={width > 1200 ? 'mx-xxl' : ''}>
                    <h2
                        className={
                            'basic-sans-semibold mt-xxl ' +
                            (width > 1200 ? ' text-xxl' : 'text-xl') +
                            (width > 576 && width <= 1200 ? ' mx-xl' : '')
                        }>
                        Une analyse complexe pour un rendu
                        {width > 992 && <br />} final clair, fiable et facilement exploitable
                    </h2>
                    <MethodeRowTextImg
                        imgVisibleTreshold={576}
                        className="mt-lg"
                        textMargin="0 auto 0 0"
                        alt="TODO"
                        img={rapportDetaille}
                        imgRight
                        textMaxWidth="500px"
                        title="">
                        <p className={'text-left' + (width > 1200 ? 'text-lg' : '')}>
                            Mettre un savoir-faire pointu au service de tous, avec une interface intuitive, délivrant un
                            rapport clair.
                            <br />
                            <br />
                            L’analyse des facteurs est délivrée dans le rapport détaillé que vous obtenez immédiatement
                            à l’issue du parcours d’évaluation.
                            <br />
                            <br />
                            Le rapport se présente sous la forme d’énoncés clairs : les forces et faiblesses.
                            <br />
                            <br />
                            Chaque facteur est noté de 1 à 3 étoiles et retranscrit dans une analyse textuelle
                            compréhensible et concrète.
                        </p>
                    </MethodeRowTextImg>
                    <MethodeRowTextImg
                        textMaxWidth="60%"
                        imgVisibleTreshold={576}
                        alt="TODO"
                        textMargin="auto 0 auto auto"
                        img={exempleRapport}
                        title="Des tableaux et graphique vous permettent de :">
                        <>
                            <ul
                                className={'dots-list line-height-lg ml-sm ' + (width > 1200 ? '' : 'mt-auto')}
                                style={{ '--color': '#C0CEFF' }}>
                                <li className={width > 992 ? 'text-lg' : ''}>
                                    Identifier les différents indicateurs de performances
                                </li>
                                <li className={width > 992 ? 'text-lg' : ''}>Visualiser leurs évolutions par année</li>
                                <li className={width > 992 ? 'text-lg' : ''}>
                                    Faire un focus sur certains ratio-clés essentiels
                                </li>
                            </ul>
                        </>
                    </MethodeRowTextImg>
                </div>
                <p className={'centered mt-xxl ' + (width > 1200 ? 'text-lg' : '') + (width > 576 ? ' mx-xl' : '')}>
                    Votre rapport se conclut par une valeur finale moyenne, {width > 1200 && <br />}
                    décomposée en 3 fourchettes de valeurs indexées sur :
                </p>
                <div className={'row-500 f-center mt-xl ' + (width > 576 ? 'gap-xl' : 'centered gap-md')}>
                    <div className="methode-euro-container">
                        <img src={euroFonce} alt="TODO" className="methode-img" />
                        <p>Le volume d'activité (CA)</p>
                    </div>
                    <div className="methode-euro-container">
                        <img src={euroMoyen} alt="TODO" className="methode-img" />
                        <p>La rentabilité retraitée</p>
                    </div>
                    <div className="methode-euro-container">
                        <img src={euroClair} alt="TODO" className="methode-img" />
                        <p>Le ratio rentabilité / CA</p>
                    </div>
                </div>
                <div className={'centered ' + (width > 1200 ? 'mt-xxl' : 'mt-xl')}>
                    <FilledButton padding="10px 25px" fontSize={width > 1200 ? '1.3rem' : '1rem'} to="/debut-parcours">
                        Démarrer mon estimation
                    </FilledButton>
                </div>
                <div className="centered mt-xxl">
                    <Usine width={width > 1200 ? '150px' : '100px'} />

                    <h2 className={'basic-sans-semibold ' + (width > 1200 ? 'text-xxl mt-lg' : 'text-xl mt-md')}>
                        L'estimation des titres de société
                    </h2>
                    <p
                        className={
                            'basic-sans-semibold centered ' + (width > 1200 ? 'text-xl mt-xxl' : 'text-lg mt-md')
                        }>
                        L'estimation est réalisée avec 2 phases de calcul
                    </p>
                    <p className="border-text">1</p>
                    <p className={width > 576 ? 'text-lg mx-xl' : ''}>
                        Notre algorithme établit la valeur du fonds de commerce, tel que décrit précédemment.
                    </p>
                    <p className="border-text">2</p>
                    <p className={width > 576 ? 'text-lg mx-xl' : ''}>
                        Cette valeur de fonds est corrigée avec les éléments d'actif et passif du bilan.
                        <br />
                        <br />
                    </p>
                    <p className={(width > 1200 ? 'text-lg' : '') + (width > 576 ? ' mx-xl' : '')}>
                        Le rapport détaillé délivré pour l'estimation des titres de société indique les fourchettes de
                        valorisation des titres, ainsi que celles de valorisation de base du fonds de commerce.
                    </p>
                    <Immeuble className="mt-xxl" width={width > 1200 ? '150px' : '100px'} />

                    <h2 className={'basic-sans-semibold ' + (width > 1200 ? 'text-xxl mt-lg' : 'text-xl mt-md')}>
                        L'estimation des murs commerciaux
                    </h2>
                    <p className={'basic-sans-semibold centered ' + (width > 1200 ? 'text-xl mt-xl' : 'mt-md text-lg')}>
                        Elle est réalisée sur la base de 2 approches :
                    </p>
                </div>
                <MethodeRowTextImg
                    imgVisibleTreshold={576}
                    alt="TODO"
                    img={coiffeur}
                    title=""
                    className={width > 576 ? 'methode-margin-xxl-900' : 'methode-margin-xl'}
                    imgRight>
                    <div className="text-center">
                        <p className="border-text">1</p>
                        <p className={width > 1200 ? 'text-lg' : ''}>
                            Celle d’un acquéreur (souvent l’occupant des locaux) qui utilisera lui-même le local pour
                            son exploitation. Cette méthode se base sur la situation locative, dont le taux d’effort
                            théorique et le loyer effectif.
                        </p>
                    </div>
                </MethodeRowTextImg>
                <MethodeRowTextImg
                    imgVisibleTreshold={576}
                    alt="TODO"
                    img={monsieurCommerce}
                    className={width > 576 ? 'methode-margin-xxl-900' : 'methode-margin-xl'}
                    title="">
                    <div className="text-center">
                        <p className="border-text">2</p>
                        <p className={width > 1200 ? 'text-lg' : ''}>
                            Celle d’un investisseur, qui vise des revenus locatifs. Cette méthode se base sur les taux
                            de rentabilité attendue en fonction des qualités d’emplacement, tout en intégrant le loyer
                            effectif en vigueur.
                        </p>
                    </div>
                </MethodeRowTextImg>
                <p
                    className={
                        'text-center basic-sans-semibold text-xl mt-xl ' + (width > 1200 ? 'text-xl' : 'text-lg')
                    }>
                    Ces 2 approches sont pondérées par 20 autres facteurs du local.
                </p>
                <MethodeTitle
                    title="professionnalisme et neutralité"
                    subtitle="Le résultat final est une estimation professionnelle, rapide et fiable, mais aussi indépendante et neutre."
                    number={4}
                    className="mt-xxl"
                />
                <p className={'mt-sm ' + (width > 576 ? 'text-lg' : '')}>
                    EstimerMonCommerce.fr ne réalise aucune cession et n’a aucun intérêt à influencer la valeur de votre
                    estimation à la hausse ou à la baisse.
                </p>
                <div className={'mt-xxl row-1000 space-around ' + (width > 992 ? 'mb-xxl' : '')}>
                    <img
                        src={rapportOutils}
                        alt="TODO"
                        className={
                            (width > 1200 ? 'methode-img-400' : 'methode-img-ssm') + (width > 992 ? '' : ' mx-auto')
                        }
                    />
                    <div className="methode-card-beige">
                        <p>
                            A l’aide de votre rapport personnalisé et de votre accès à nos outils pratiques et conseils
                            exclusifs, vous avez toutes les cartes en main pour préparer un projet de cession /
                            acquisition dans les meilleures conditions.
                        </p>
                        <div className="centered">
                            <FilledButton
                                padding="10px 30px"
                                className="mt-md"
                                fontSize={width > 1200 ? '1.3rem' : '1rem'}
                                to="/debut-parcours">
                                Commencer
                            </FilledButton>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Methode;
