import './MethodeTitle.css';
import React from 'react';

function MethodeTitle({ number, title, subtitle, className }) {
    return (
        <div className={'methode-title-container ' + className}>
            <h2>
                <span>{number}</span>
                {title}
            </h2>
            <p>{subtitle}</p>
        </div>
    );
}

export default MethodeTitle;
