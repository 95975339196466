import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import Banner from '../../../components/molecules/banner/Banner';
import './ConseilsProsArticle.css';
import React, { useEffect, useState, useRef } from 'react';
import ArrowSm from '../../../components/atoms/icons/general/arrowSm/ArrowSm';
import { Link } from 'react-router-dom';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import DOMPurify from 'dompurify';
import ReactDOMServer from 'react-dom/server';
import HtmlToReact, { Parser } from 'html-to-react';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Cross from '../../../components/atoms/icons/general/cross/Cross';
import RapportsColorsCTA from '../../../components/atoms/icons/conseilsPros/rapportsColorsCTA/RapportsColorsCTA';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import ExpendableGroup from '../../../components/organisms/expendableGroup/ExpendableGroup';
import Eye from '../../../components/atoms/icons/general/eye/Eye';
import PDF from '../../../uploads/rapportsExamples/exemple_rapport_fdc.pdf';
import Check from '../../../components/atoms/icons/general/check/Check';
import Linkedin from '../../../components/atoms/icons/share/linkedin/Linkedin';
import Facebook from '../../../components/atoms/icons/share/facebook/Facebook';
import Twitter from '../../../components/atoms/icons/share/twitter/Twitter';
import Email from '../../../components/atoms/icons/share/email/Email';
import InfiniteThumbnailsRow from '../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow';

function ConseilsProsArticle() {
    const [article, setArticle] = useState({
        id: '',
        title: '',
        content: '',
        createdAt: '',
        updatedAt: '',
        imagePath: '',
        imageAlt: '',
        titleDoc: '',
        fileName: '',
        url: '',
    });
    const [articleAnchors, setArticleAnchors] = useState([]);
    const [hasOutilCTAShown, setHasOutilCTAShown] = useState(0);
    const [windowScroll] = useWindowScroll();
    const [FAQ, setFAQ] = useState({
        title: '',
        content: [],
    });
    const [suggestions, setSuggestions] = useState([]);

    const outilCTASliderRef = useRef(null);
    const articleRef = useRef(null);
    const FAQRef = useRef(null);

    const { width, _ } = useWindowSize();

    const magnet = document.getElementById('lead_magnet');

    useEffect(() => {
        fetchArticle();
        fetchFAQ();
        fetchSuggestions();

        setTimeout(() => {
            document.getElementById('rapport_container').addEventListener('click', openRapport);
        }, 500);

        return () => {
            document.getElementById('rapport_container')?.removeEventListener('click', openRapport);
        };
    }, []);

    useEffect(() => {
        setAnchorsFromArticle();
    }, [article]);

    useEffect(() => {
        if (magnet && !hasOutilCTAShown && windowScroll.y >= magnet.offsetTop) {
            setHasOutilCTAShown(true);
            showOutilCTA();
        }
    }, [windowScroll]);

    function getPrettyArticle(article) {
        const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions();
        const processingInstructions = [
            {
                shouldProcessNode: function (node) {
                    return node.name && node.name === 'img';
                },
                processNode: function (node) {
                    const img = require('../../../uploads/conseilsPros/12/contentImg/40.svg');
                    return React.createElement('img', {
                        id: node.attribs.id,
                        src: img.default,
                        className: node.attribs.class,
                        alt: node.attribs.alt,
                    });
                },
            },
            {
                shouldProcessNode: function (node) {
                    return node.name && node.name === 'button';
                },
                processNode: function (node) {
                    return React.createElement(
                        'a',
                        { target: '_blank', className: 'button filled-button article-button', href: '/debut-parcours' },
                        node.children[0].data,
                    );
                },
            },
            {
                shouldProcessNode: function (node) {
                    return (
                        node.name &&
                        node.attribs &&
                        node.attribs.class &&
                        node.name === 'div' &&
                        node.attribs.class.includes('container')
                    );
                },
                processNode: function (node) {
                    return React.createElement('div', { id: 'cta_container' }, <EstimationCTA />);
                },
            },
            {
                shouldProcessNode: function () {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode,
            },
        ];
        const isValidNode = function () {
            return true;
        };

        const htmlToReactParser = new Parser();
        const reactComponent = htmlToReactParser.parseWithInstructions(
            article.content,
            isValidNode,
            processingInstructions,
        );
        article.content = ReactDOMServer.renderToStaticMarkup(reactComponent);

        return article;
    }

    async function fetchArticle() {
        //TODO

        const article = {
            id: '12',
            title: 'Quel est le rôle de l’expert-comptable pour une entreprise ?',
            content: `<p>Bien que présentant des similitudes avec une vente d’immobilier classique (de logement), le déroulement d’une <font class="basic-sans-bold">cession de fonds de commerce</font> est soumis à davantage de critères et de complexité. </p>

            <p>Entrent en jeu les aspects bilantiels, sociaux, fiscaux, normatifs et juridiques de l’affaire cédée. </p>
            
            <p>Une cession de fonds de commerce est ainsi jalonnée de nombreuses étapes successives bien distinctes et met en scène un certain nombre d’intervenants. Suivez le guide.</p>
            
            <hr class="hr-article">
            
            <h2>Le calendrier d’une cession de fonds de commerce</h2>
            
            <p>Sans être une règle générale, le calendrier présenté ci-dessous constitue une moyenne, qui tient compte :</p>
            
            <ul>
                <li>des impératifs logistiques et de la disponibilité habituelle constatée des intervenants</li>
                <li>de la fluidité relative moyenne des échanges entre eux</li>
                <li>des délais moyens de formalités administratives et bancaires</li>
            </ul>
            
            <p>Dans la mesure où la phase initiale, à savoir la négociation du prix entre acquéreur et cédant, peut varier fortement, nous avons choisi de prendre comme point de départ du calendrier la signature commune d’une offre d’achat officielle, scellant l’accord entre cédant et repreneur.</p>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/40.svg" alt="déroulement cession fonds commerce">
            
            <p>La cession d’un fonds de commerce nécessite rarement moins de 3 mois entre l’offre acceptée et l’acte définitif. </p>
            
            <p>Ces délais sont aléatoires et sont également soumis à l’agenda, au dynamisme et à la bonne volonté des parties et des acteurs de la cession du fonds de commerce.</p>
            
            <p>Il vaut donc mieux être prudent, patient et organisé et se donner une marge temporelle confortable.</p>
            
            <p>Il est conseillé de tabler sur un délai minimum de 4 à 6 mois pour prévoir que la cession soit réalisée, puis disposer pleinement et librement de votre temps pour enfin prendre ces vacances si longtemps attendues !</p>
            
            <hr class="hr-article">
            
            <h2>Les intervenants d’une cession de fonds de commerce</h2>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/42.svg" alt="écosystème chef entreprise">
            
            <p>C’est un véritable <font class="basic-sans-bold">écosystème qui gravite</font> autour du cédant et du repreneur.</p>
            
            <p>La liste suivante ne prétend pas être exhaustive, mais donne une bonne idée des (parfois nombreuses) forces en présence :</p>
            
            <ul>
                <li>Le repreneur et son éventuel conjoint et/ou associé(s)</li>
                <li>le cédant et son éventuel conjoint et/ou associé(s)</li>
                <li>le rédacteur d’acte (conseiller juridique)</li>
                <li>l’expert-comptable</li>
                <li>le bailleur</li>
                <li>l’administration</li>
                <li>les banques</li>
                <li>les assurances</li>
                <li>les fournisseurs et partenaires commerciaux</li>
                <li>le franchiseur éventuel</li>
                <li>les organismes de contrôle (sécurité, extractions, accessibilité)</li>
                <li>les douanes et l’Européenne de cautionnement dans le cas du tabac</li>
                <li>la Française des Jeux, le PMU, les distributeurs de presse si l’affaire est concernée</li>
                <li>les employés en place</li>
                <li>les corps de métier si des travaux sont prévus</li>
                <li>et l’éventuel intermédiaire dans la cession, qui doit en principe coordonner tous ces interlocuteurs pour le compte du cédant et du repreneur</li>
            </ul>
            
            <p>Découvrez notre article sur le rôle et l’importance des <a class="linkLeg" href="/conseils-pros/acteurs-cession-fonds-commerce-accompagnement" target="_blank">intervenants d’une cession de fonds de commerce</a></p>
            
            <hr class="hr-article">
            
            <p class="text-blue-focus"><font class="basic-sans-bold">Note :</font> les définitions de tous les mots du jargon métier présents dans notre article sont accessibles depuis notre <a class="linkLeg" href="/lexique" target="_blank"><u>lexique commercial</u></a></p>
            
            <h2>Déterminer la valeur du fonds de commerce</h2>
            
            <p>La détermination de la valeur (ou évaluation) ne consiste pas à graver dans le marbre un prix de vente, mais davantage à obtenir (ou conforter) une idée la plus précise possible de la valeur potentielle de cession du fonds de commerce.</p>
            
            <p>Cela permet d’éviter les déconvenues et de lancer inutilement les grandes manœuvres, la valeur calculée n’étant parfois pas celle que l’on espère…</p>
            
            <button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez vendre</button>
            
            <h3>Quelle méthode pour évaluer un fonds de commerce ?</h3>
            
            <p>L’on entend régulièrement qu’il existe plusieurs méthodes d’estimation de valeur d’un fonds de commerce. C’est vrai.</p>
            
            <p>Il est également vrai qu’aucune règle unique ne fait loi. </p>
            
            <p>Partant de ce postulat, utiliser une méthode plutôt qu’une autre relève uniquement du choix de l’intéressé, choix effectué de manière complètement subjective et arbitraire en fonction de ses prérogatives. </p>
            
            <p>En d’autres termes : puisqu’il n’existe aucune obligation en la matière, il est bien tentant pour un cédant d’opter pour la méthode qui met en avant la valeur la plus haute (et inversement, pour un repreneur de lui opposer la méthode qui dégrade la valeur le plus fortement !).</p>
            
            <p>L’on peut débattre ainsi éternellement avant de trouver un accord. Ce serait dommage, car <font class="basic-sans-bold">bien évaluer un fonds de commerce</font> est le prérequis à une cession rapide et satisfaisante pour les parties.</p>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/40.svg" alt="évaluation commerce entreprise">
            
            <p>La réalité est pourtant simple et vise à mettre tout le monde d’accord : un fonds de commerce constitue un outil de travail.</p>
            
            <p>L’acheter nécessite un investissement, qui doit être rentabilisé sur un certain nombre d’années (généralement sept, durée maximum des crédits accordés par les banques sur les financements de fonds de commerce).</p>
            
            <p>Pour <font class="basic-sans-bold">calculer ce retour sur investissement</font> (c’est-à-dire capitaliser, vivre décemment de son exploitation et garder en vue un potentiel de revente cohérent), l’on utilise divers indicateurs, dont le chiffre d’affaires, la rentabilité, l’emplacement, le <a class="linkLeg" href="/conseils-pros/bail-commercial-points-importants">bail commercial</a>, la pérennité et la qualité de l’outil de travail.</p>
            
            <p>Et à cet ensemble de facteurs, l’on confronte la finançabilité de l’affaire avec un apport financier de l’ordre de 30 à 40% (cela peut descendre à 20%, voire moins, en fonction de l’excellence du dossier et de la qualité de la relation entre le prospect et sa banque).</p>
            
            <p>Retrouvez le détail des éléments à prendre en compte dans une évaluation dans notre article consacré à <a class="linkLeg" href="/conseils-pros/estimation-fonds-commerce" target="_blank">l’estimation du fonds de commerce</a>.</p>
            
            <p>Retenez a minima ces deux règles d’or qui soulignent l’importance de bien évaluer une affaire :</p>
            
            <ul>
              <li>Une cession de fonds de commerce ne peut se faire qu’avec deux parties, qui ne concrétisent l’opération qu’à l’issue de divers accords sur les conditions de vente, à commencer par le prix de cession.</li>
              <li>Tout finit par se vendre à son juste prix (ni trop haut, ni trop bas), ce n’est qu’une question de temps. Démarrer proche de ce juste prix permet à tout le monde de gagner beaucoup de temps et d’éviter des déceptions.</li>
            </ul>
            
            <div class="container mt-5 mb-5">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="card-article">
                            <div class="row g-0 card-article-row justify-content-center">
                                <div class="col-12 col-lg-auto px-0 text-center text-lg-start">
                                    <p class="card-article-title">Découvrez<br>notre module<br>d’estimation<br>en ligne</p>
                                </div>
                                <div class="col-auto d-none d-lg-block px-0">
                                    <img src="../assets/img/DocInvitEst.svg" role="presentation" alt="doc invite" class="card-title-icon">
                                </div>
                                
                                <div class="col-auto px-0">
            
                                    <div class="card-article-btn mt-4 mt-lg-0">
                                        <div class="row justify-content-center">
                                            <div class="col-auto cursorPointer">
                                                <a class="text-white" onclick="document.getElementById('formType1').submit();">Fonds de commerce</a>
                                            </div>
                                            <div class="col d-none d-lg-block"></div>
                                        </div>
            
                                        <div class="row justify-content-center mt-4">
                                            <div class="col d-none d-lg-block"></div>
                                            <div class="col-auto cursorPointer">
                                                <a class="text-white" onclick="document.getElementById('formType2').submit();">Titres de société</a>
                                            </div>
                                            <div class="col d-none d-lg-block"></div>
                                        </div>
            
                                        <div class="row justify-content-center mt-4">
                                            <div class="col d-none d-lg-block"></div>
                                            <div class="col-auto cursorPointer">
                                                <a class="text-white" onclick="document.getElementById('formType3').submit();">Murs commerciaux</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div class="white-arrow d-none d-lg-block">
                                <img src="../assets/img/white-arrow.svg" role="presentation" alt="icon arrow" class="w-100">
                            </div>
            
                        </div>
                    </div>
                </div>
            </div>
            
            <h2>Déterminer l’objet et le mode de cession du fonds de commerce</h2>
            
            <p>Vendre une affaire, oui, mais au-delà de ce terme générique, que vendez-vous exactement ? Le fonds de commerce, les titres de la société, le droit au bail, ou encore les murs commerciaux ?</p>
            
            <p>Le <font class="basic-sans-bold">mode de cession</font> dépend de ce que vous détenez, de la façon dont vous l‘avez acquis, de ce que vous êtes en mesure de vendre ou non, de l’impact fiscal et de la demande.</p>
            
            <p>Il peut être tentant de laisser l’impact fiscal de la cession dicter le choix du mode de cession, en privilégiant par exemple la vente des titres de la société pour limiter l’<a class="linkLeg" href="/conseils-pros/imposition-plus-value-fonds-commerce" target="_blank">imposition sur la plus-value</a>.</p>
            
            <p>Se limiter à cette approche peut être préjudiciable à la vendabilité de l’affaire, ne tenant compte que des impératifs du cédant.</p>
            
            <p>Choisir le mode de cession d’une affaire relève d’un ensemble de points de réflexion, qui, tout comme la détermination du prix, doit intégrer <font class="basic-sans-bold">la réalité du marché</font> et le point de vue du repreneur (et là encore, ne pas oublier que derrière lui, il y a souvent ses conseillers).</p>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/40.svg" alt="choix mode cession entreprise">
            
            <h3>Cession de fonds de commerce : vendre sous forme de fonds de commerce</h3>
            
            <p>Le mode de cession d’une activité commerciale le plus simple (tous facteurs confondus) reste la vente du fonds de commerce. </p>
            
            <p>Constitué d’éléments dits corporels (“palpables”, tels que les agencements et le matériel) et incorporels (“impalpables”, tels que la clientèle, le droit au bail et le nom commercial), le fonds de commerce est l’outil de travail détenu par la société (structure juridique) de l’exploitant. </p>
            
            <p>Lors de la <font class="basic-sans-bold">cession de fonds de commerce</font>, le cédant conserve sa société, qu’il pourra utiliser pour d’autres activités. Retrouvez plus de détails dans notre article sur la <a class="linkLeg" href="/conseils-pros/vente-fonds-commerce" target="_blank">vente d'un fonds de commerce</a>.</p>
            
            <h3>Cession de fonds de commerce : vendre sous forme de titres de société</h3>
            
            <p>L’autre mode de cession répandu est la <font class="basic-sans-bold">vente des titres de société</font>. </p>
            
            <p>Les titres de société sont les parts sociales (pour les SARL, EURL ou SNC) ou les actions (pour les SA et SAS), qui constituent le titre de propriété sur une entreprise.</p>
            
            <p>Dans ce cas, il ne s’agit plus seulement de céder le fonds de commerce, mais la structure juridique (la société) qui le détient, avec son actif (trésorerie, entre autres) et son passif (dettes et comptes courants d’associés), qui entrent ainsi dans le calcul de valeur, puisqu’ils sont repris par l’acquéreur.</p>
            
            <p>Nous développons la <a class="linkLeg" href="/conseils-pros/evaluation-entreprises-titres-societe" target="_blank">vente des titres de société</a> dans un article spécifique.</p>
            
            <h3>Cession de fonds de commerce : vendre sous forme de droit au bail</h3>
            
            <p>Il est également possible de ne céder que le droit au bail, mais celui-ci ne concerne plus l’activité commerciale.</p>
            
            <p>La cession du droit au bail est la vente, par un locataire à un autre, du simple droit de lui succéder dans le local commercial qu'il occupe.</p>
            
            <p>L’on a notamment recours à ce type de cession lorsque l’activité n’est pas cessible (si elle n’a pas ou plus de valeur, ou s’il n’y a pas de demande), ou que l’exploitant souhaite la conserver pour la relocaliser par exemple.</p>
            
            <p>Ce type de cession, qui implique directement le bailleur, est développé dans notre article dédié à la <a class="linkLeg" href="/conseils-pros/cession-droit-bail" target="_blank">vente du droit au bail</a></p>
            
            <h3>Cession de fonds de commerce : vendre les murs commerciaux</h3>
            
            <p>Si le cédant possède également les <font class="basic-sans-bold">murs commerciaux</font> et qu’il souhaite les céder, leur valorisation et leur vente seront séparées de celle du fonds de commerce.</p>
            
            <p>En effet, les murs commerciaux relèvent juridiquement de la vente immobilière traditionnelle (sauf si les murs sont détenus par une personne morale comme une SCI, ou autre, dont il est alors possible de vendre les titres).</p>
            
            <p>Découvrez notre article consacré à la <a class="linkLeg" href="/conseils-pros/valorisation-murs-commerciaux" target="_blank">valorisation des murs commerciaux</a></p>
            
            <h3>Cession de fonds de commerce : le crédit-vendeur</h3>
            
            <p>Développé dans notre article dédié, le <a class="linkLeg" href="/conseils-pros/credit-vendeur-cession-fonds-commerce" target="_blank">crédit-vendeur</a> peut constituer une facette de la cession, qui apporte souvent la clef à un blocage de l’opération du à un manque d’apport financier.</p>
            
            <hr class="hr-article">
            
            <h2>L’intervention du conseil juridique dans la cession du fonds de commerce</h2>
            
            <p>Quelle que soit la forme sous laquelle est cédée l’affaire (titres de société ou fonds de commerce), une <font class="basic-sans-bold">cession de commerce ou d’entreprise est un processus rigoureux</font>, notamment en termes juridiques. Le rôle du conseiller est donc primordial.</p>
            
            <h3>A quoi sert le conseil juridique dans une cession du fonds de commerce ?</h3>
            
            <p>Si le bail commercial du local d’activité concerné n’exige pas un acte authentique (c’est-à-dire devant notaire), une transaction peut être effectuée sous seing privé (c’est-à-dire directement entre les parties, avec ou sans avocat).</p>
            
            <p>La vente de fonds de commerce étant jalonnée d’obligations légales, de formalités bien précises et de précautions à prendre, il est indispensable de recourir aux <font class="basic-sans-bold">services d’un conseil juridique</font> et ainsi de sécuriser l’opération, autant pour le compte du repreneur que du cédant.</p>
            
            <p>Son rôle est d’examiner l’ensemble du cadre relatif à l’exploitation, afin de dévoiler les éventuels points cachés ou non-connus par les parties et de respecter le cadre juridique et légal d’une cession.</p>
            
            <p id="lead_magnet">C’est pourquoi il est demandé au cédant de transmettre au conseil un ensemble d’éléments, qu’il vaut mieux avoir préparé en amont !</p>
            
            <p>Un notaire ou un avocat (de préférence spécialisé en droit des affaires et fiscalité) est donc votre meilleur atout pour vous accompagner dans une opération de cession de fonds de commerce.</p>
            
            <h3>Combien coûtent les honoraires juridiques dans une cession du fonds de commerce ?</h3>
            
            <p>La question du choix du <font class="basic-sans-bold">conseil juridique</font> n’est pas anodine, entre autres car au-delà du talent et de l’expérience du conseil concerné, cela génère un coût.</p>
            
            <p>Dans une cession, les honoraires juridiques étant à la charge de l’acquéreur, il est de rigueur que le choix du conseil revienne à celui-ci.</p>
            
            <p>Il est ainsi fréquent qu’un seul conseiller rédige et encadre la transaction pour le compte des deux parties (ce qui est à proscrire dans le cas d’une cession de titres de société).</p>
            
            <p>Il est donc important que repreneur et cédant communiquent avec le conseil juridique choisi dès que possible après avoir trouvé un accord sur le prix de vente et ses conditions.</p>
            
            <p>Cela permet entre autres d’instaurer la <font class="basic-sans-bold">confiance mutuelle</font>, moteur important d’une cession agréable et constructive. </p>
            
            <p>Il est également possible et fréquent pour le cédant de faire intervenir son propre conseil. Si les usages diffèrent en fonction des régions et des personnes, en règle générale les honoraires du conseil du cédant restent à la charge de ce dernier. L’éventuel partage d’honoraires entre conseillers relève de pratiques individuelles et de négociations au cas par cas.</p>
            
            <p>Cependant il est fortement recommandé de bien cadrer ces questions en amont, afin d’éviter des tensions futures pouvant causer la paralysie de la transaction ou des renégociations de prix dues à des frais non-anticipés.</p>
            
            <p>L’intervention d’un conseil juridique génère, outre les frais liés aux droits d’enregistrement (pour l’acquéreur), des honoraires. Ceux-ci sont librement fixés, mais oscillent entre 1,5 et 3% du prix de cession en moyenne avec souvent un forfait minimum de l’ordre de 3.000 € ht.</p>
            
            <p>Cela représente certes un coût, mais s’en passer peut entraîner des conséquences désastreuses se traduisant par des coûts très largement supérieurs.</p>
            
            <hr class="hr-article">
            
            <h2>La cession du fonds de commerce se déroule en 3 phases officielles</h2>
            
            <p>La transmission d’une affaire passe par trois grandes étapes :</p>
            
            <ul>
                <li>l’offre d’achat (ou la lettre d’intention),</li>
                <li>le compromis de vente </li>
                <li>et l’acte définitif.</li>
            </ul>
            
            <p>Ces étapes permettent d’avancer progressivement en fonction d’un calendrier précis, de respecter certains délais légaux, de procéder aux différentes formalités, et de mettre des deux côtés les meilleures chances de succès pour sécuriser la transmission.</p>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/40.svg" alt="étapes cession fonds commerce">
            
            <p><font class="basic-sans-bold">La lettre d’intention ou l’offre d’achat</font> sert à formaliser et officialiser la volonté du repreneur d’acquérir le fonds de commerce ou les titres de société. </p>
            
            <p>Ce document, que le cédant devra contresigner pour acceptation, est soumis à un certain nombre de critères, qui sont développés dans notre article consacré à <a class="linkLeg" href="/conseils-pros/offre-achat-lettre-intention" target="_blank">la lettre d’intention ou l’offre d’achat</a></p>
            
            <p><font class="basic-sans-bold">Le compromis de vente</font> de fonds de commerce (ou protocole d’accord pour les titres de société) formalise juridiquement les conditions de réalisation de la cession, reprenant tous les éléments essentiels, sur lesquels les parties se sont mises d’accord lors des négociations préalables.</p>
            
            <p>Rédigé par le conseil juridique sous forme de projet, il est soumis aux parties pour validation avant signature. Il inclut notamment les conditions de réalisation de la cession, appelées conditions suspensives.</p>
            
            <p>Retrouvez en détail les points de vigilance sur le compromis de vente dans notre article dédié au <a class="linkLeg" href="/conseils-pros/compromis-vente-protocole-accord" target="_blank">compromis de vente</a> dans la cession de fonds de commerce </p>
            
            <p><font class="basic-sans-bold">L’acte de vente (ou acte définitif)</font> de fonds de commerce est la réitération du compromis de vente. C’est quasiment un copier/coller de celui-ci.</p>
            
            <p>Néanmoins c’est bien l’acte de vente qui formalise la cession et officialise le repreneur en tant que propriétaire du bien visé.</p>
            
            <p>Il mentionne donc obligatoirement certaines informations dont l’absence peut entraîner l’annulation de la vente (à la demande de l’acheteur). Outre les éléments repris dans le compromis, l’on doit y trouver :</p>
            
            <ul>
                <li>L’origine de propriété du fonds de commerce (nom du précédent vendeur, date et nature de l’acquisition et prix d’acquisition scindé en éléments corporels et incorporels)</li>
                <li>Le prix de cession du fonds de commerce</li>
                <li>Les informations sur le bail commercial et ses conditions de <a class="linkLeg" href="/conseils-pros/renouvellement-bail-commercial" target="_blank">renouvellement</a></li>
                <li>Les privilèges et nantissements</li>
                <li>Le détail du chiffre d'affaires et des résultats nets des 3 dernières années, ainsi que le chiffre d’affaires mois par mois depuis la clôture du dernier exercice comptable</li>
            </ul>
            
            <p>Si une partie de ces éléments est inexacte, l’on parle de <font class="basic-sans-bold">vice caché</font>. Cela peut remettre en cause la transaction au travers d’une baisse du prix calculée sur le préjudice causé au repreneur. </p>
            
            <p>Attention : une installation électrique défaillante, une peinture qui s’effrite ou du matériel qui tombe en panne ne constitue pas un vice caché, le repreneur ayant déclaré dans le compromis et dans l’acte prendre l’affaire en l’état pour l’avoir visitée et en connaître les aménagements.</p>
            
            <p>Dans le cas de deux conseils juridiques (un pour chaque partie), il est de rigueur que l’un rédige le compromis et l’autre l’acte définitif.</p>
            
            <p>C’est le conseil juridique qui va se charger des formalités, détaillées dans notre article sur <a class="linkLeg" href="/conseils-pros/formalites-cession-fonds-commerce" target="_blank">les formalités</a> dans une cession de fonds de commerce </p>
            
            <hr class="hr-article">
            
            <h2>L’accompagnement (passage de témoin) dans une transmission de fonds de commerce</h2>
            
            <p>Bien qu’une reprise de fonds de commerce soit mieux balisée qu’une création et son évident lot d’inconnues, le démarrage reste une phase intense.</p>
            
            <p>Ce n’est qu’à ce moment que le repreneur va réellement être aux commandes de l’exploitation et donc plongé dans la réalité et la dynamique de l’activité.</p>
            
            <p>Tout comme en création, le repreneur démarre en découvrant véritablement l’outil de travail du cédant, sa clientèle, ses concurrents, ses employés et doit trouver ses marques.</p>
            
            <p>Les tableaux de bord, si le cédant en a réalisés, lui seront utiles.</p>
            
            <img class="mt-5 mb-5 maxHeightIconAuto" src="../../../uploads/conseilsPros/12/contentImg/42.svg" alt="transmission entreprise">
            
            <h3>Y a-t-il une obligation d’accompagnement dans une transmission de fonds de commerce</h3>
            
            <p>L’accompagnement du cédant au bénéfice du repreneur se déroule durant une période convenue entre eux et précisée dans l’acte de vente.</p>
            
            <p>Cette période de passation de témoin, aussi appelée période de mise au courant, permet de faire le pont entre l’exploitation du cédant et celle du repreneur.</p>
            
            <p>Cela concerne notamment le fonctionnement des locaux, des outils, la présentation des fournisseurs et de la clientèle, les habitudes de travail, le personnel, etc.</p>
            
            <p>Il n’existe <font class="basic-sans-bold">pas d’obligation d’accompagnement</font>, ni de durée, ni du moment où il doit intervenir. La passation peut se dérouler avant la signature de l’acte définitif, après, ou bien sur une période à cheval sur la cession. </p>
            
            <p>Dans ce dernier cas, l'habitude veut que le repreneur observe le cédant agir au quotidien dans l’affaire avant la cession, et l’inverse après la cession.</p>
            
            <button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez acheter</button>
            
            <h3>Les points de vigilance de l’accompagnement dans une transmission de fonds de commerce</h3>
            
            <p>Il est important de bien choisir cette période, car elle peut engendrer un certain stress entre les parties pour plusieurs raisons. Voici quelques cas de figures issus de notre vécu :</p>
            
            <ul>
                <li>Deux capitaines à la tête du même navire n’est pas chose aisée, surtout si leurs façons de voir les choses sont très différentes, ce qui est presque toujours le cas.</li>
                <li>Le repreneur prend souvent véritablement conscience de la masse de travail qui l’attend lors de cette passation et cela peut le déstabiliser, l’effrayer et parfois être tenté de renoncer à l’acquisition.</li>
                <li>Si le cédant n’a pas été transparent sur l’état véritable d’un matériel fatigué, le repreneur va le constater de lui-même lors de la passation et réclamer une baisse de prix, voire tenter de renoncer à l’acquisition à cause de la perte de confiance.</li>
                <li>Si le cédant choisit après la cession et malgré son engagement, de ne pas honorer l’accompagnement ou de le raccourcir, il n’est pas aisé de l’obliger juridiquement à le faire si cet accompagnement n’est pas rémunéré et encadré par un statut salarié précis. Quand bien même, l’accompagnement serait réalisé dans une atmosphère désastreuse pour la conduite de l’activité.</li>
                <li>Il est fréquent qu’un repreneur libère le cédant de ses obligations avant la fin de la période d’accompagnement, ayant d’une part l’envie de se sentir enfin “chez lui” et d’autre part car il maîtrise suffisamment l’outil de travail.</li>
            </ul>
            
            <h3>La durée de l’accompagnement dans une transmission de fonds de commerce</h3>
            
            <p>Lors d’une <font class="basic-sans-bold">cession de fonds de commerce</font>, la <font class="basic-sans-bold">durée de l’accompagnement</font> répond davantage à une notion de cohérence que d’obligation. </p>
            
            <p>L’accompagnement étant censé garantir le succès de la reprise, sa durée doit s’adapter à la complexité de l’activité et à l’expérience du repreneur, ainsi qu’à ses souhaits.</p>
            
            <p>La durée de l’accompagnement fait donc souvent partie de la négociation. </p>
            
            <p>Pour un commerce tel qu’un restaurant par exemple, la durée est souvent de 2 à 3 semaines (en physique et en disponibilité téléphonique par la suite).</p>
            
            <p>Elle peut atteindre 12 mois (voire plus) dans le cas d’entreprises spécifiques ou techniques, étant parfois le fruit d’une vie de travail du cédant.</p>
            
            <h3>La forme de l’accompagnement dans une transmission de fonds de commerce</h3>
            
            <p>Lors d’une cession de fonds de commerce, la forme de l’accompagnement est variable et adaptative.</p>
            
            <p>Celui-ci étant prodigué par le cédant, il est classique que ce dernier ne soit pas spécifiquement rémunéré pour cette tâche, le prix de vente incluant l’accompagnement.</p>
            
            <p>Cependant si la durée dépasse un mois en cas de nécessité de passation plus complète, il est fréquent que cette tâche soit monnayée en sus du prix de cession et concrétisée par un contrat de travail de courte durée pour le cédant.</p>
            
            <p>Certaines cessions d’entreprises intègrent la reprise du cédant sur une période de plusieurs mois sous forme salariée, notamment dans les cas de transmission d’un savoir-faire conséquent, de phases de concrétisations de marché longues, de pré-départ en retraite du cédant, etc.</p>
            
            <p>Dans ces cas-là, la globalité de la rémunération prévue pour le cédant sur la durée est un véritable aspect de négociation pour déterminer de quelle manière elle fait partie ou est déduite du prix de vente.</p>`,
            createdAt: '15-11-2021',
            updatedAt: '20-01-2023',
            url: 'benchmark-concurrentiel',
            imagePath: 'Image_de_tete_Le_calendrier.jpeg',
            imageAlt: 'calendrier cession fonds de commerce',
            titleDoc: 'liste des éléments à fournir pour céder une affaire',
            fileName: 'LISTE_DES_PIECES_A_FOURNIR_SI_VOUS_ACHETEZ_UNE_AFFAIRE.pdf',
        };

        setArticle(getPrettyArticle(article));
    }

    async function fetchFAQ() {
        //TODO

        const faq = {
            title: 'Le pacte d’associés en 3 questions',
            content: [
                {
                    title: 'Qu’est-ce qu’un pacte d’associés ?',
                    answer: 'Le pacte d’associés est un contrat entre un ou plusieurs associés d’une entreprise. Il peut être signé par tous les associés ou seulement certains d’entre eux (au minimum deux). Le pacte d’associés est un document juridique extra-statutaire qui vise à définir les engagements contractuels entre associés et de façon confidentielle, contrairement aux statuts. En fonction de l’entreprise et du partage de son capital (en parts sociales ou en actions), on parlera de pacte d’associés (parts sociales) ou de pacte d’actionnaires (actions). Dans les deux cas, la finalité reste la même : le pacte permet de définir les relations entre associés, les conditions de cessions des titres, la gouvernance de l’entreprise et les sanctions en cas de non-respect du pacte.',
                },
                {
                    title: 'Quand doit-on rédiger un pacte d’associés ?',
                    answer: 'La rédaction d’un pacte d’associés peut se faire à tout moment au cours de la vie d’une entreprise : à sa création, lors de sa cession ou en cas d’augmentation de son capital, à travers l’arrivée d’un nouvel associé ou investisseur. L’important étant de veiller à ce que les clauses qu’il comprend soit claires et précises et non-discriminatoires, qu’elles ne contredisent pas celles des statuts de l’entreprise et qu’elles précisent sa durée.',
                },
                {
                    title: 'Quelles sont les limites du pacte d’associés ?',
                    answer: 'La réalisation d’un pacte d’associés demande une grande vigilance de la part de son rédacteur et de ses signataires. Toutes les parties doivent s’assurer de la logique des clauses, afin qu’il n’y ait pas de contradictions, ni entre elles, ni avec celles des statuts. Il est également important de veiller à préserver les intérêts des signataires et de l’entreprise. Aucune partie ne doit être lésée ou victime d’une clause piégeuse ou abusive. Tous les cas de figure doivent donc être envisagés et étudiés avec soin, afin d’éviter tout litige futur. Ce qui serait dommage, étant donné que c’est le but premier du pacte d’associés ! C’est pourquoi il est recommandé de s’entourer de personnes spécialisées dans la rédaction de ce type de document juridique, comme les avocats spécialisés en droit des affaires.',
                },
            ],
        };

        setFAQ(faq);
    }

    async function fetchSuggestions() {
        // TODO

        const suggestions = [
            {
                title: 'Le crédit-vendeur dans une cession de fonds : ses avantages',
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Les différents acteurs d'une cession de fonds de commerce",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/3/thumbnail/Image_de_tete_Valorisation_murs_commerciaux.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/4/thumbnail/Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: 'Le crédit-vendeur dans une cession de fonds : ses avantages',
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/5/thumbnail/Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Les différents acteurs d'une cession de fonds de commerce",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/6/thumbnail/Image_de_tete_estimation_fonds_commerce.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
            {
                title: "Formalités d'une cession de fonds de commerce : ce qu'il faut savoir",
                tag: 'Cession d’une affaire',
                picture: '/conseilsPros/7/thumbnail/Image_de_tete_compromis_vente_protocole_accord.jpeg',
                link: '/conseils-pros/benchmark-concurrentiel',
                linkText: "Lire l'article",
            },
        ];

        setSuggestions(suggestions);
    }

    function showOutilCTA() {
        if (outilCTASliderRef.current) {
            outilCTASliderRef.current.style.display = 'block';
            outilCTASliderRef.current.style.right = '20px';
        }
    }

    function hideOutilCTA() {
        if (outilCTASliderRef.current) {
            outilCTASliderRef.current.style.opacity = '0';
        }
        setTimeout(() => {
            outilCTASliderRef.current.style.display = 'none';
        }, 500);
    }

    function setAnchorsFromArticle() {
        const children = [...Array.from(articleRef.current.children), ...Array.from(FAQRef.current.children)];
        const titles = children.filter(child => child.localName === 'h2');
        setArticleAnchors(
            titles.map((title, index) => {
                title.setAttribute('id', getTitleIdFromName(title.innerHTML));
                const top = title.offsetTop - 50;
                return {
                    name: title.innerHTML,
                    link: getTitleIdFromName(title.innerHTML),
                    top: top,
                    bottom: titles.length > index + 1 ? titles[index + 1].offsetTop : top + 1000000,
                };
            }),
        );
    }

    function getTitleIdFromName(name) {
        return name
            .replace('&nbsp;', ' ')
            .split(' ')
            .join('-')
            .replace(/[^a-zA-Z0-9- ]/g, '');
    }

    function openRapport(e) {
        e.preventDefault();
        window.open(PDF, '_blank');
    }

    const EstimationCTA = () => (
        <div className="my-xl">
            <h3 className="text-xl basic-sans-bold centered mb-md">Estimez en ligne une affaire</h3>
            <div className="estimation-cta-container">
                <a href={PDF} className="estimation-cta-img-container" id="rapport_container">
                    <div
                        className="button inversion-button article-button text-lg"
                        style={{
                            '--padding': '3px 10px',
                            '--hover-color': 'var(--white)',
                            '--bg-color': 'var(--blue)',
                        }}>
                        <Eye />
                    </div>
                    <img src={require('../../../assets/rapportsExamples/miniature_rapport_fdc.webp')} />
                </a>
                <div className="flex f-column space-between gap-sm mx-auto">
                    <p className="cta-title centered">Découvrez nos modules d'estimation&nbsp;:</p>
                    <div className="mx-auto">
                        <p>
                            <span>
                                <Check />
                                &nbsp;&nbsp;Fonds de commerce
                            </span>
                            <br />
                            <span>
                                <Check />
                                &nbsp;&nbsp;Titres de société
                            </span>
                            <br />
                            <span>
                                <Check />
                                &nbsp;&nbsp;Murs commerciaux
                            </span>
                        </p>
                    </div>
                    <div className="centered">
                        <a className="button filled-button article-button text-md" href="/debut-parcours">
                            Démarrer mon estimation
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Conseils: Conseils de pros',
                            to: '/conseils-pros',
                        },
                        {
                            name: article.title,
                            to: '/TODO',
                        },
                    ]}
                />
                <Banner
                    title="Conseils de pros"
                    subtitle={
                        <Link className="link-with-arrow" to="/conseils-pros">
                            <ArrowSm /> Retour à la liste des conseils
                        </Link>
                    }
                />
                <PageTitle title={article.title} />
                <img
                    className="article-main-picture mb-md"
                    src={
                        article.id &&
                        require(`../../../uploads/conseilsPros/${article.id}/thumbnail/${article.imagePath}`)
                    }
                />
                <div className="centered mb-md">
                    <p className="text-sky-blue basic-sans-bold">
                        Publié le {article.createdAt}
                        {article.updatedAt && (
                            <span className="text-sky-blue basic-sans-bold"> et mis à jour le {article.updatedAt}</span>
                        )}
                    </p>
                </div>
                <div className="outil-cta-slider" ref={outilCTASliderRef}>
                    <div className="outil-cta-container">
                        <Cross width="20px" className="outil-cta-close" onClick={hideOutilCTA} />
                        <div className="flex f-column gap-md centered">
                            <p className="centered">
                                Découvrez notre
                                <br />
                                <span className="basic-sans-bold">{article.titleDoc}</span>
                            </p>
                            <RapportsColorsCTA />
                            <FilledButton padding="9px 15px" bgColor="var(--light-blue)" color="var(--dark-blue)">
                                Télécharger notre outil
                            </FilledButton>
                        </div>
                    </div>
                </div>
                <div className="flex space-between">
                    <aside className="article-aside">
                        <h4 className="basic-sans-bold">Dans cet article</h4>
                        <ul className="article-nav f-column gap-md flex">
                            {articleAnchors.map((anchor, key) => (
                                <li
                                    key={key}
                                    className={
                                        windowScroll.y >= anchor.top && windowScroll.y < anchor.bottom ? 'active' : ''
                                    }>
                                    <HashLink to={'#' + anchor.link}>{anchor.name}</HashLink>
                                </li>
                            ))}
                        </ul>
                    </aside>
                    <div className="conseils-pros-container">
                        <div
                            className="article-container"
                            ref={articleRef}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}></div>
                        <div className="article-container" ref={FAQRef}>
                            <hr className="hr-article" />
                            <h2>F.A.Q</h2>
                            <p>{FAQ.title}</p>
                            <div className={width > 576 ? 'mx-lg' : ''}>
                                <ExpendableGroup
                                    data={FAQ.content.map(row => {
                                        return { title: row.title, content: <p>{row.answer}</p> };
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <aside className="article-aside">
                        <h4 className="basic-sans-bold">Découvrez nos outils</h4>
                        <p className="mb-md">
                            Des outils pratiques pour l'estimation, la cession et l'acquisition de fonds, titres et murs
                            commerciaux
                        </p>
                        <Link className="link link-with-arrow mb-md" to="/outils-pratiques">
                            Je découvre les outils pratiques&nbsp;
                            <ArrowSm rotation="180deg" />
                        </Link>
                        <Link className="link link-with-arrow mb-lg" to="/outils-pratiques">
                            J'estime une affaire ou des murs&nbsp;
                            <ArrowSm rotation="180deg" />
                        </Link>
                        <h4 className="basic-sans-bold">Partagez cet article !</h4>
                        <p>Cet article vous a plus ? Partagez-le !</p>
                        <div className="row space-between mt-sm">
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="#0077B5"
                                hoverColor="#0077B5"
                                target="_blank"
                                to={
                                    'https://www.linkedin.com/sharing/share-offsite/?url=' +
                                    process.env.REACT_APP_URL +
                                    '/conseils-pros/' +
                                    article.url
                                }
                                rel="nofollow">
                                <Linkedin />
                            </FilledButton>
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="#1761AB"
                                hoverColor="#1761AB"
                                target="_blank"
                                to={
                                    'https://www.facebook.com/sharer/sharer.php?u=' +
                                    process.env.REACT_APP_URL +
                                    '/conseils-pros/' +
                                    article.url
                                }
                                rel="nofollow">
                                <Facebook />
                            </FilledButton>
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="#2D9AFF"
                                hoverColor="#2D9AFF"
                                target="_blank"
                                to={
                                    'https://twitter.com/share?url=' +
                                    process.env.REACT_APP_URL +
                                    '/conseils-pros/' +
                                    article.url
                                }
                                rel="nofollow">
                                <Twitter />
                            </FilledButton>
                            <FilledButton
                                padding="5px 6px"
                                borderRadius="7px"
                                bgColor="var(--gold)"
                                hoverColor="var(--gold)"
                                target="_blank"
                                to={
                                    'mailto:?Subject=Article%20EstimerMonCommerce.fr&body=Partage%20d%27un%20article%20sur%20EstimerMonCommerce.fr%20:%20' +
                                    process.env.REACT_APP_URL +
                                    '/conseils-pros/' +
                                    article.url
                                }
                                rel="nofollow">
                                <Email />
                            </FilledButton>
                        </div>
                    </aside>
                </div>
                <div className="my-xl centered">
                    <FilledButton padding="8px 20px" to="/debut-parcours">
                        Je démarre mon estimation
                    </FilledButton>
                </div>
            </section>
            <InfiniteThumbnailsRow
                title={<PageTitle title="DANS LA MÊME CATÉGORIE :" margin="20px auto" />}
                data={suggestions}
                margin="-15px auto 50px"
            />
            <Footer />
        </>
    );
}

export default ConseilsProsArticle;
