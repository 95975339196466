import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import Banner from '../../../components/molecules/banner/Banner';
import './TrucsAstucesArticle.css';
import '../conseilsProsArticle/ConseilsProsArticle.css';
import React, { useEffect, useState, useRef } from 'react';
import ArrowSm from '../../../components/atoms/icons/general/arrowSm/ArrowSm';
import { Link } from 'react-router-dom';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import DOMPurify from 'dompurify';
import ReactDOMServer from 'react-dom/server';
import HtmlToReact, { Parser } from 'html-to-react';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production';
import { useWindowScroll } from '@uidotdev/usehooks';

function TrucsAstucesArtice() {
    const [article, setArticle] = useState({
        id: '',
        title: '',
        content: '',
        imagePath: '',
        imageAlt: '',
    });
    const [articleAnchors, setArticleAnchors] = useState([]);
    const [windowScroll] = useWindowScroll();
    const articleRef = useRef(null);

    useEffect(() => {
        fetchArticle();
    }, []);

    useEffect(() => {
        setAnchorsFromArticle();
    }, [article]);

    function getPrettyArticle(article) {
        const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions();
        const processingInstructions = [
            {
                shouldProcessNode: function (node) {
                    return node.name && node.name === 'img';
                },
                processNode: function (node) {
                    const img = require('../../../uploads/trucsAstuces/12/contentImg/40.svg');
                    return React.createElement('img', {
                        id: node.attribs.id,
                        src: img.default,
                        className: node.attribs.class,
                        alt: node.attribs.alt,
                    });
                },
            },
            {
                shouldProcessNode: function (node) {
                    return node.name && node.name === 'button';
                },
                processNode: function (node) {
                    return React.createElement(
                        'a',
                        { target: '_blank', className: 'button filled-button article-button', href: '/debut-parcours' },
                        node.children[0].data,
                    );
                },
            },
            {
                shouldProcessNode: function () {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode,
            },
        ];
        const isValidNode = function () {
            return true;
        };

        const htmlToReactParser = new Parser();
        const reactComponent = htmlToReactParser.parseWithInstructions(
            article.content,
            isValidNode,
            processingInstructions,
        );
        article.content = ReactDOMServer.renderToStaticMarkup(reactComponent);

        return article;
    }

    async function fetchArticle() {
        //TODO

        const article = {
            id: '12',
            title: "Les secrets d'une visite d'affaire bien structurée pour transformer",
            content: `<p>Beaucoup d’intermédiaires pensent qu’une fois l’<font class="myBold">offre signée et acceptée</font>, la vente est dans la poche.</p>
    
    <p>❌ ERREUR !</p>
    
    <p>Les choses sont très bien enclenchées pour concrétiser la vente, certes.</p>
    
    <p>Mais <font class="myBold">le compromis de vente reste l’étape majeure</font> d’une vente de fonds de commerce.</p>
    
    <p>La portée d’une offre signée et acceptée <font class="myBold">n’est que limitée</font> 🤷‍♂️<br>
    Il n’est pas si difficile que ça pour l’une des 2 parties de sortir du deal avant la signature du compromis.
    </p>
    
    <p>D’ailleurs, aller vers une vente forcée sur la seule base d’une offre est un <font class="myBold">processus hasardeux</font>, coûteux et énergivore. Parfois traumatique 😱</p>
    
    <p>Tant que le compromis de vente n’est pas signé, les parties ne sont pas irréversiblement engagées l’une envers l’autre.<br>
    Ni envers toi, intermédiaire de la cession.
    </p>
    
    <p>✍️ Ton objectif est donc de <font class="myBold">signer le compromis de vente au plus vite</font>.</p>
    
    <p>Voici comment faire.</p>
    
    <h2 id="Vente-dun-fonds-de-commerce--une-offre-cest-bien-Un-compromis-cest-mieux-">Vente d’un fonds de commerce : une offre c’est bien… Un compromis c’est mieux !</h2>
    
    <p>Le délai de préparation et de rédaction d’un compromis de vente de fonds de commerce par un juriste peut facilement être de <font class="myBold">5 à 6 semaines</font> 🤯</p>
    
    <p>Une fois l’offre acceptée, c’est long. Très long.</p>
    
    <p>💣 Quel est le <font class="myBold">risque entre l'offre et le compromis</font> de vente d’un fonds de commerce ?</p>
    
    <p>Avant tout, arrêt sur image et flashback :</p>
    
    <p>Tu as effectué une <a class="linkLeg" href="/trucs-astuces/les-secrets-d-une-prospection-reussie" target="_blank">prospection efficace</a>,réalisé une <a class="linkLeg" href="/trucs-astuces/comment-faire-une-bonne-decouverte-d-un-commerce-ou-d-une-entreprise-a-vendre" target="_blank">bonne découverte du vendeur</a> et rentré un mandat au bon prix 💰, grâce aux éléments du <a class="linkLeg" href="/debut-parcours" target="_blank" rel="nofollow">rapport d’estimation</a> et notamment son <a class="linkLeg" href="https://tinyweb.estimermoncommerce.fr/plan-financement" target="_blank" rel="nofollow">plan de financement avec reste-à-vivre</a>.</p>
    
    <button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez vendre</button>
    
    <p>Puis après avoir <a class="linkLeg" href="/trucs-astuces/comment-monter-un-dossier-de-vente-d-affaire-pro-et-convaincant" target="_blank">monté un dossier de vente solide</a> 💪 et <a class="linkLeg" href="/trucs-astuces/comment-rediger-une-bonne-annonce-de-vente-de-fonds-de-commerce" target="_blank">rédigé une annonce qui cartonne</a>, tu as reçu des acquéreurs, dont tu as <a class="linkLeg" href="/trucs-astuces/les-cles-pour-bien-cerner-l-acquereur-d-un-commerce" target="_blank">parfaitement identifié les besoins</a>.</p>
    
    <p>S’en est logiquement suivie une <a class="linkLeg" href="/trucs-astuces/les-secrets-d-une-visite-d-affaire-bien-structuree-pour-transformer" target="_blank">visite bien structurée</a>, qui a abouti à une <font class="myBold">offre d’achat, acceptée par le vendeur</font>.</p>
    
    <p>YES ! Là tu peux serrer le poing, car c’est une 1ère victoire ✊</p>
    
    <p>☝️ Mais attention, <font class="myBold">RIEN N’EST JOUÉ</font>.</p>
    
    <p>Ton acquéreur a signé l’offre et ton vendeur l’a contresignée.<br>
    Tu es donc tenté(e) de penser que l’affaire est faite, puisque tout ça est écrit.
    </p>
    
    <p>La réalité peut être bien différente…</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/acheteur_fonds_commerce_sous_offre.png" alt="acheteur_fonds_commerce_sous_offre">
    
    <h2 id="Comment-se-comporte-un-acqureur-ayant-sign-une-offre-dachat-sur-un-fonds-de-commerce-">Comment se comporte un acquéreur ayant signé une offre d’achat sur un fonds de commerce ?</h2>
    
    <p>Un acquéreur, une fois qu’il a signé l’offre, entre dans une phase très spéciale.<br>
    Il est à la fois galvanisé ET inquiet (énormément, parfois).
    </p>
    
    <p>🤔 <font class="myBold">Il se pose beaucoup de questions</font> : « Fais-je le bon choix ? Aurais-je pu trouver mieux ? Que va en penser mon entourage ? Etc. »</p>
    
    <p>Il devient alors plus actif dans l’avancement de son projet et va faire des <font class="myBold">rencontres</font>...</p>
    
    <p>Il y a d’abord son entourage proche ou distant, dont certains membres « bien intentionnés » tenteront de le dissuader 🤨</p>
    
    <p>Et puis il y a les potentiels conseillers &amp; accompagnateurs de tous poils.</p>
    
    <p>Aux compétences aléatoires, ils peuvent être de vrais atouts pour le pousser dans la bonne direction...</p>
    
    <p>...🚫 Mais aussi se révéler de bien piètres conseillers et <font class="myBold">jouer contre le projet</font>.</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/acquereur_personnes_barrages_vente_commerce.png" alt="acquereur_personnes_barrages_vente_commerce">
    
    <p>Il y a même des acquéreurs qui <font class="myBold">continuent à chercher</font> activement une affaire - potentiellement encore meilleure que la tienne - avant la signature du compromis.</p>
    
    <p><font class="myBold">Bon à savoir</font> : la plupart des acquéreurs (surtout les primo-accédants) n’ont pas encore de conseillers. Ni avocat, ni expert-comptable.</p>
    
    <p>Donc, si tu en as des bons dans ton réseau, <font class="myBold">oriente ton acquéreur vers eux</font> dès que possible.</p>
    
    <p>🛡️ Ca lui évitera de tomber sur un <font class="myBold">potentiel incompétent</font> au hasard des rencontres.<br>
    Il y en a, comme dans tous les métiers.
    </p>
    
    <p>Et il t’en sera reconnaissant, même si tu ne fais pas l’affaire avec lui.</p>
    
    <p>Ca c'est côté acquéreur. Maintenant il y a le…</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_vendeur_fonds_commerce_sous_offre.png" alt="vendeur_fonds_commerce_sous_offre">
    
    <h2 id="Comment-se-comporte-un-vendeur-ayant-accept-une-offre-dachat-sur-un-fonds-de-commerce-">Comment se comporte un vendeur ayant accepté une offre d’achat sur un fonds de commerce ?</h2>
    
    <p>Côté vendeur, le stress est généralement moins présent 😑<br>
    Il a envie de vendre, il a trouvé quelqu’un qui paye son prix.<br>
    En substance, tout va bien.
    </p>
    
    <p>Mais <font class="myBold">vigilance</font>...</p>
    
    <p>La nature humaine est ce qu’elle est, l’appât du gain aussi 🤑</p>
    
    <p>Il n’est donc pas impossible que le vendeur soit courtisé par d’autres acquéreurs et <font class="myBold">tente de faire monter les enchères</font>.</p>
    
    <p>Notre expérience nous a même mis face à quelques vendeurs ayant utilisé un acquéreur comme « lièvre ».</p>
    
    <p>C’est-à-dire ? Ils se sont servis de son offre pour convaincre un autre acheteur - avec qui il négociait depuis un moment - de payer plus cher.</p>
    
    <p>Heureusement, <font class="myBold">l’immense majorité des vendeurs</font> est bien intentionnée 😇</p>
    
    <p>Mais certains vendeurs découvrent à ce stade (un peu tardivement, donc) qu’ils vont devoir payer une <a class="linkLeg" href="/trucs-astuces/comprendre-la-taxe-sur-la-plus-value-d-un-fonds-de-commerce" target="_blank">taxe sur plus-value sur la vente de leur fonds de commerce</a>.</p>
    
    <p>🧑‍⚖️ Touchant du doigt la vente à venir, le vendeur a (enfin) consulté son expert-comptable ou son avocat fiscaliste, qui l’a informé de la « douloureuse ».</p>
    
    <p>Le vendeur a donc fait ses comptes et voit son prix net « dans la poche » amputé par la <a class="linkLeg" href="/conseils-pros/imposition-plus-value-fonds-commerce" target="_blank">fiscalité (sur plus-value)</a> qu’il n’avait pas anticipée.</p>
    
    <p>Et il n’est <font class="myBold">plus du tout d’accord pour vendre au prix</font> accepté, estimant y laisser trop de plumes 😡</p>
    
    <p>Cas rare : il veut alors augmenter le prix. Ce qui tue l’opération.<br>
    Cas fréquent : il préfère vendre les titres de la société (<a class="linkLeg" href="/conseils-pros/calcul-imposition-titre-societe" target="_blank">fiscalité plus favorable</a>).
    </p>
    
    <p>Ce qui n’est plus du tout le même deal !<br>
    Surtout pour l’acquéreur, qui devra dans ce cas reprendre un passif.
    </p>
    
    <p>🙅 Bref, les raisons de dédit d’un acquéreur ou d’un vendeur peuvent être nombreuses.</p>
    
    <p>Alors comment éviter ça ?</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_engagement_offre_achat_fonds_commerce.png" alt="engagement_offre_achat_fonds_commerce">
    
    <h2 id="Acqureur-et-vendeur-du-fonds-de-commerce-ne-sont-fermement-engags-qu-la-signature-du-compromis-de-vente">Acquéreur et vendeur du fonds de commerce ne sont fermement engagés qu’à la signature du compromis de vente</h2>
    
    <p>En principe, <font class="myBold">une offre d’achat acceptée vaut engagement</font>.<br>
    Ca permet – en théorie – à la partie lésée d’obtenir une vente forcée auprès de la partie qui se dédit.
    </p>
    
    <p>En théorie...</p>
    
    <p>Faut-il encore, si tu vas devant un tribunal pour forcer une vente, que :</p>
    
    <ul>
      <li>Les termes de l’offre soient <font class="myBold">réellement engageants</font></li>
      <li>Ton mandat de vente soit rédigé <font class="myBold">au cordeau</font> 🛡️</li>
      <li>Le juge estime la partie lésée… réellement lésée</li>
    </ul>
    
    <p>Et quand bien même cette étape serait passée, la suite n’est pas une partie de plaisir.</p>
    
    <p>🚧 En effet, obliger un acquéreur ou un vendeur à réaliser une transaction qu’il ne souhaite plus est <font class="myBold">l’assurance de rencontrer tous les blocages</font> et reculades possibles.</p>
    
    <p>Tandis qu’une fois le compromis signé, il n’existe que 3 façons d’en sortir :</p>
    
    <p>1. <font class="myBold">Mener l’opération à terme</font> comme c’est prévu, donc tout va bien 🏆</p>
    
    <p>2. Une <font class="myBold">condition suspensive</font> n’est pas réalisée : les parties reprennent leurs billes, merci et au revoir (elles peuvent aussi proroger le compromis)</p>
    
    <p>3. Sortir du deal malgré tout, <font class="myBold">en payant l’équivalent du dépôt de garantie</font> (généralement 10% du prix net vendeur du fonds), voire des indemnités additionnelles pour les frais engagés et le préjudice subi par la partie lésée.</p>
    
    <p>On parle donc d’un véritable engagement.</p>
    
    <p>☝️ D’autant plus que le <font class="myBold">délai de rétraction de 10 jours n’existe pas</font> après la signature d’un compromis de vente de fonds de commerce (ou de droit au bail ou de titres de société).</p>
    
    <p>Tout ceci étant dit…</p>
    
    <h2 id="Comment-signer-un-compromis-de-vente-au-plus-vite-aprs-lacceptation-de-loffre-dachat-">Comment signer un compromis de vente au plus vite après l’acceptation de l’offre d’achat ?</h2>
    
    <p>Ton anticipation, ta maîtrise du processus et ta réactivité permettent de fortement <font class="myBold">réduire ce délai</font>.</p>
    
    <p>Comment faire ?</p>
    
    <p>👉 Voici <font class="myBold">plusieurs actions concrètes</font> à mettre en place pour :</p>
    
    <ol>
      <li>Garder la main sur l’opération</li>
      <li>Mener celle-ci à bon train</li>
      <li>Signer le compromis en un temps record ⏱</li>
    </ol>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_liste_documents_cession_fonds_commerce.png" alt="liste_documents_cession_fonds_commerce">
    
    <h2 id="Collecter-rapidement-les-pices-acqureur-et-vendeur-ncessaires--la-rdaction-du-compromis-de-vente">Collecter rapidement les pièces acquéreur et vendeur nécessaires à la rédaction du compromis de vente</h2>
    
    <p>Tu disposes déjà de certains éléments du vendeur, notamment <font class="myBold">bail et bilans</font>, depuis le jour où tu as rentré l’affaire en mandat.</p>
    
    <p>Il te faut néanmoins récupérer de <font class="myBold">nombreuses pièces</font> côté vendeur.</p>
    
    <p>💡 Dans nos outils pratiques, tu disposes d’une <a class="linkLeg" href="/outils-pratiques/elements-cedez-une-affaire" target="_blank">liste des pièces à fournir pour le vendeur d’une affaire</a>.</p>
    
    <p>Imprime-là, colle-là sur une pochette et coche les éléments reçus au fur et à mesure.</p>
    
    <p>C’est bien d’avoir <font class="myBold">au moins une version papier</font> du dossier.<br>
    Un dossier « master » 📕<br>
    Car il va te suivre partout.<br>
    Notamment lors des réunions avec les parties (et au compromis, bien sûr).<br>
    Tu le poseras à chaque fois devant toi, devant tout le monde.<br>
    Tu le connaîtras par cœur.<br>
    Tu pourras en extraire <font class="myBold">n’importe quelle pièce utile</font> durant la discussion.<br>
    ⚡ En 10 secondes.<br>
    Avant même que les avocats ou les experts-comptables ne la trouvent dans leur propre dossier.
    </p>
    
    <p>La <font class="myBold">symbolique est forte</font>.</p>
    
    <p>Car ça montrera à TOUT LE MONDE que tu es organisé(e) et rigoureux(se).</p>
    
    <p>Et ce professionnalisme concrétisé (entre autres) par ton dossier te permet de :</p>
    
    <ol>
      <li>Bien mieux <font class="myBold">défendre</font> tes honoraires si c’est nécessaire par la suite.</li>
      <li>Montrer à tout le monde <font class="myBold">qui orchestre</font> l’opération (toi, bien sûr…)</li>
    </ol>
    
    <p>N’hésite pas à <font class="myBold">relancer le vendeur</font> quasi-quotidiennement et suivre de près l’obtention de ces éléments, car il devra lui-même solliciter ses conseils pour en obtenir certains.</p>
    
    <p>🛟 Propose-lui <font class="myBold">ton aide pour identifier et collecter</font> ces éléments dans ses dossiers.<br>
    S'ils sont en désordre (c'est souvent le cas), ça le pousse à procrastiner.
    </p>
    
    <p>Demande à être mis en contact direct avec ses conseils et relance-les directement (ils procrastinent eux aussi souvent !).</p>
    
    <p>Classe soigneusement dans ta pochette les éléments reçus au fil des jours.</p>
    
    <button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez vendre</button>
    
    <p><font class="myBold">Même chose côté acquéreur</font> 👇</p>
    
    <p>Ses éléments sont souvent disséminés et il ne sait pas toujours <font class="myBold">comment les obtenir</font>.<br>
    Offre-lui ton aide pour accélérer / faciliter les choses.
    </p>
    
    <p>💡 Dans nos outils pratiques, tu disposes aussi d’une <a class="linkLeg" href="/outils-pratiques/elements-achetez-une-affaire" target="_blank">liste des pièces à fournir pour l’acquéreur d’une affaire</a>.</p>
    
    <p><font class="myBold">Insiste sur l’urgence</font> d’obtenir ces pièces auprès des 2 parties : il te les faut pour HIER !</p>
    
    <p>L’idéal est d’avoir tout obtenu, avant de transmettre les pièces au rédacteur de l’acte (l’avocat ou le notaire).<br>
    Plutôt que les lui envoyer au compte-goutte (il va en perdre).
    </p>
    
    <p>☝️ Certains éléments ne sont indispensables que pour le montage du dossier bancaire.</p>
    
    <p>Cependant, mieux vaut ne pas faire le distinguo.<br>
    Et <font class="myBold">réclamer toutes les pièces</font> aux interlocuteurs avec le même degré d’urgence.
    </p>
    
    <p>Comme pour les pièces du vendeur, coche la liste des documents reçus au fur et à mesure.</p>
    
    <p>💾 Puis :</p>
    
    <ol>
      <li>Scanne le tout</li>
      <li>Nomme les pièces clairement en informatique</li>
      <li>Stocke-les de manière organisée pour en <font class="myBold">faciliter la diffusion</font> (et la compréhension) aux juristes, puis aux courtiers ou aux banques.<br>
    Idéalement sur un Drive (ou Cloud), auquel tu donnes accès au(x) conseil(s).
    </li>
    </ol>
    
    <p>⚠️ Il est important de faire comprendre à tous les intervenants de l’opération que <font class="myBold">c’est toi qui orchestres la transaction</font>.</p>
    
    <p>Et que toutes les pièces doivent transiter par toi 🫵</p>
    
    <p>Ainsi, tu seras toujours dans la boucle.<br>
    Et informé des derniers développements ✅<br>
    Et en mesure d’<font class="myBold">agir en cas de problème</font>.
    </p>
    
    <p>Ou de transmettre rapidement des éléments s’ils manquent aux différents interlocuteurs.</p>
    
    <p>➡️ Et enfin, tu dois te poser comme PIVOT de l’opération.</p>
    
    <p>Pour ça, <font class="myBold">envoie dès que possible aux intervenants un mail</font> qui :</p>
    
    <ul>
      <li>Récapitule les éléments principaux de la cession</li>
      <li>Liste les coordonnées de tous les intervenants ☎</li>
      <li>Rappelle à tous que TU gères le déroulement des opérations, en relation avec les conseils</li>
      <li>Et que les éléments passent par toi et seront transmis aux conseils par tes soins</li>
      <li>Propose un calendrier (serré) de signature du compromis 📅</li>
    </ul>
    
    <p>Ps : c’est le genre de mail à relire 3 fois avant envoi 🙂</p>
    
    <h2 id="Raliser-linventaire-du-matriel">Réaliser l’inventaire du matériel</h2>
    
    <p>Avant la signature du compromis, il faut réaliser un inventaire physique et contradictoire du matériel.</p>
    
    <p>Il doit être transmis au rédacteur du compromis, auquel il sera annexé.</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_faire_inventaire_vente_fonds_commerce.png" alt="faire_inventaire_vente_fonds_commerce">
    
    <p>Tu peux aider à cet inventaire en intervenant sur place pour <font class="myBold">assister le vendeur</font> dans le listing des éléments.</p>
    
    <p>Note-les toi-même sur une liste papier.</p>
    
    <p>✅ Cela te permet aussi de contrôler que tout ce que tu as indiqué à l’acquéreur sera <font class="myBold">bien présent dans l’inventaire</font> !</p>
    
    <p>En effet, il peut arriver que des vendeurs se disent « tiens, je vais garder (et enlever) cet ustensile, ils ne verront rien »… 🙄</p>
    
    <p>📝 Tu peux ensuite :</p>
    
    <ol>
      <li><font class="myBold">Mettre la liste au propre</font> sur Word</li>
      <li>Déterminer la valeur globale avec le cédant (et son comptable, en fonction de la valeur restant à amortir)</li>
      <li>L’envoyer au rédacteur de l’acte</li>
    </ol>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_numeriser_gerer_documents_vente_commerce.png" alt="numeriser_gerer_documents_vente_commerce">
    
    <h2 id="Transmettre-efficacement-les-pices-pour-acclrer-la-signature-du-compromis-de-vente">Transmettre efficacement les pièces pour accélérer la signature du compromis de vente</h2>
    
    <p>Une fois que tu as collecté <font class="myBold">tous les éléments</font> nécessaires à la rédaction du compromis, il te faut les envoyer au rédacteur de l’acte.</p>
    
    <p>⚠️ Cette <font class="myBold">étape-clef</font> est souvent négligée par les intermédiaires.</p>
    
    <p>Pourtant, bien menée, elle peut te faire <font class="myBold">gagner jusqu’à plusieurs semaines</font> pour signer le compromis de vente.</p>
    
    <p><font class="myBold">Pourquoi ?</font></p>
    
    <p>Lorsque le rédacteur reçoit les pièces acquéreur et vendeur, il doit en <font class="myBold">vérifier le contenu et en extraire</font> tout ce qui va alimenter le compromis.</p>
    
    <p>😓 C’est un long travail.</p>
    
    <p>Et comme le rédacteur n’a pas que ton affaire à traiter, ça peut prendre pas mal de temps 🗓️</p>
    
    <p>Surtout si tu lui envoies un tas d’éléments en désordre, avec des noms de fichiers du style « <i>23-08-2023_téléchargement.pdf</i> » au lieu de « <i>Bilan 2023</i> »…<br>
    Bref, en mode « débrouille-toi avec ça et on verra bien » 🙄
    </p>
    
    <p>Donc, afin de lui <font class="myBold">faciliter la tâche et accélérer le processus</font> 🚀 voici un outil génial : le <a class="linkLeg" href="/outils-pratiques/canevas-de-compromis" target="_blank">canevas de précompromis</a>.</p>
    
    <p>Il détaille tous les éléments devant figurer dans le compromis de vente du fonds de commerce.</p>
    
    <p>⚠️ Attention, ce n’est <font class="myBold">pas un projet de compromis</font> (ça c’est le boulot du juriste, pas le tien).</p>
    
    <p>Ce canevas est une <font class="myBold">trame judicieuse organisée</font> en parties distinctes, comme le compromis.</p>
    
    <p>Dans cette trame, tu as mâché le boulot au juriste.<br>
    Et c’est très simple (mais il fallait y penser 😅).
    </p>
    
    <p>➡️ Ce canevas permet au rédacteur de construire rapidement le projet de compromis.</p>
    
    <p>Grâce à une <font class="myBold">base claire</font> et efficace, que tu lui as communiquée.</p>
    
    <p>Il va évidemment tout vérifier, cela va de soi.<br>
    Mais ainsi, le rédacteur ne part pas de zéro, gagne du temps (beaucoup) et peut <font class="myBold">avancer rapidement</font> ⚡<br>
    Il appréciera cette façon de procéder qui lui facilite la vie.<br>
    Et si tu travailles régulièrement avec lui, il va rapidement <font class="myBold">privilégier tes dossiers</font>.
    </p>
    
    <p>🏆 Et ainsi être capable de te préparer un projet de compromis en <font class="myBold">1 à 2 semaines au lieu de 4 à 5</font>.</p>
    
    <p>Ce document te permet en outre de mieux <font class="myBold">encadrer certains paramètres</font> de l’opération en évitant des échanges inutiles, voire 🤬 houleux ou destructeurs entre les parties.</p>
    
    <p>Qui pourraient évoquer des points-clefs sans ta présence.</p>
    
    <p>Par exemple le montant du prix de vente, celui de tes honoraires ou celui de l’apport financier.</p>
    
    <p>En effet, <font class="myBold">le canevas de compromis mentionne</font> clairement (entre autres) :</p>
    
    <ul>
      <li>Le montant de la vente</li>
      <li>Les honoraires</li>
      <li>Le montant d’apport de l’acquéreur</li>
      <li>Le montant maximum du prêt et ses conditions</li>
      <li>Les dates</li>
      <li>Les principales conditions suspensives</li>
      <li>Etc.</li>
    </ul>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_honoraires_agent_immobilier_vente_commerce.png" alt="honoraires_agent_immobilier_vente_commerce">
    
    <h2 id="Matriser-la-clause-dhonoraires-dans-le-compromis-de-vente-du-fonds-de-commerce">Maîtriser la clause d’honoraires dans le compromis de vente du fonds de commerce</h2>
    
    <p>Les rédacteurs d’acte ne rédigent pas tous ta <font class="myBold">clause d’honoraires</font> aux petits oignons…</p>
    
    <p>Certains <font class="myBold">te sécurisent mal</font>.<br>
    Notamment en cas de non-réalisation de l’opération pour cause de dédit d’une des parties.
    </p>
    
    <p> Tu dois donc disposer d’un <a class="linkLeg" href="/outils-pratiques/modele-clause-honoraires" target="_blank">modèle de clause d’honoraires solide</a>.<br>
    Et l’envoyer au rédacteur d’acte avant qu’il ne rédige le projet de compromis.
    </p>
    
    <p>Ainsi, au lieu de rédiger une <font class="myBold">clause d’honoraires souvent tronquée</font>, il va intégrer la tienne (complète) au compromis de vente (et à l’acte dans un second temps).</p>
    
    <p>En prime, là aussi tu lui feras gagner du temps 🚀</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_preparation_avant_signature_compromis_commerce.png" alt="preparation_avant_signature_compromis_commerce">
    
    <h2 id="Valider-le-projet-de-compromis-de-vente-du-fonds-de-commerce-avant-sa-signature">Valider le projet de compromis de vente du fonds de commerce avant sa signature</h2>
    
    <p>Au préalable de sa signature, le compromis doit être envoyé (sous forme de projet) par mail à toutes les parties.</p>
    
    <p><font class="myBold">Dont toi</font> 🫵</p>
    
    <p>📢 Insiste bien sur ce point en amont, car certains juristes peuvent facilement « oublier » de te mettre en copie, considérant - à tort - que tu ne sers pas à grand-chose.</p>
    
    <p>Le contenu du projet de compromis doit être vérifié et validé par tous, <font class="myBold">toi y compris</font>.<br>
    Pour assurer une <font class="myBold">signature sereine</font> le jour J.
    </p>
    
    <p>Vérifie bien que ta clause d’honoraires y est clairement stipulée 😅</p>
    
    <button class="gold-bordered-btn d-flex m-auto" onclick="window.open('/debut-parcours', '_blank')">Evaluez en ligne l'entreprise que vous voulez vendre</button>
    
    <p>A ce stade, ton rôle consiste aussi à gérer la relation avec les notaires ou avocats pour les <font class="myBold">imprévus</font> (réguliers) ou l’envoi de pièces manquantes.</p>
    
    <p>C'est le moment où il te faut être <font class="myBold">ultra-réactif</font> 💫<br>
    Il s'agit de montrer à tout le monde combien tu es pro.<br>
    Et que <font class="myBold">tes honoraires</font> sont largement justifiés
    </p>
    
    
    <p>Est-il nécessaire de préciser qu’au jour de la signature du compromis, tu es impérativement présent pour aider à <font class="myBold">arbitrer les éventuels imprévus</font> et trouver des solutions ? Non, bien sûr, tu le sais déjà.</p>
    
    <p>Car même si tout a été bien anticipé, il arrive que certains points sensibles restent à régler le jour même de la signature.</p>
    
    <p>Et sans toi, <font class="myBold">ça peut dégénérer</font> 🤬</p>
    
    <p>🧐 Alors, prends bien garde à rester vigilant(e) jusqu’au bout !</p>
    
    <img class="mt-5 mb-5 maxWidthAuto" src="../assets/imgTrucsAstuces/image_conseils_gagner_temps_offre_compromis.png" alt="conseils_gagner_temps_offre_compromis">
    
    <p>Retrouve notre article sur <a class="linkLeg" href="/conseils-pros/compromis-vente-protocole-accord" target="_blank">le compromis de vente d’un fonds de commerce</a></p>`,
            imagePath: 'Image_Visite_affaire_bien_structuree.jpg',
            imageAlt: 'visite affaire structurée',
        };

        setArticle(getPrettyArticle(article));
    }

    function setAnchorsFromArticle() {
        const children = Array.from(articleRef.current.children);
        const titles = children.filter(child => child.localName === 'h2');
        setArticleAnchors(
            titles.map((title, index) => {
                title.setAttribute('id', getTitleIdFromName(title.innerHTML));
                const top = title.offsetTop - 50;

                return {
                    name: title.innerHTML,
                    link: getTitleIdFromName(title.innerHTML),
                    top: top,
                    bottom: titles.length > index + 1 ? titles[index + 1].offsetTop : top + 1000000,
                };
            }),
        );
    }

    function getTitleIdFromName(name) {
        return name
            .replace('&nbsp;', ' ')
            .split(' ')
            .join('-')
            .replace(/[^a-zA-Z0-9- ]/g, '');
    }

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Conseils: Trucs & astuces agents immos',
                            to: '/trucs-astuces',
                        },
                        {
                            name: article.title,
                            to: '/TODO',
                        },
                    ]}
                />
                <Banner
                    title="Trucs & astuces agents immos"
                    subtitle={
                        <Link className="link-with-arrow" to="/conseils-pros">
                            <ArrowSm /> Retour à la liste des trucs & astuces
                        </Link>
                    }
                />
                <PageTitle title={article.title} />
                <img
                    className="article-main-picture mb-xl"
                    src={
                        article.id &&
                        require(`../../../uploads/trucsAstuces/${article.id}/thumbnail/${article.imagePath}`)
                    }
                />
                <div className="flex space-between">
                    <aside className="article-aside">
                        <h4 className="basic-sans-bold">Dans cet article</h4>
                        <ul className="article-nav f-column gap-md flex">
                            {articleAnchors.map((anchor, key) => (
                                <li
                                    key={key}
                                    custom-title={anchor.name}
                                    className={
                                        windowScroll.y >= anchor.top && windowScroll.y < anchor.bottom ? 'active' : ''
                                    }>
                                    <HashLink to={'#' + anchor.link}>{anchor.name}</HashLink>
                                </li>
                            ))}
                        </ul>
                    </aside>
                    <div className="conseils-pros-container mb-sm">
                        <div
                            className="article-container"
                            ref={articleRef}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}></div>
                    </div>
                    <aside className="article-aside">
                        <h4 className="basic-sans-bold">Découvrez nos outils</h4>
                        <p className="mb-md">
                            Des outils pratiques pour l'estimation, la cession et l'acquisition de fonds, titres et murs
                            commerciaux
                        </p>
                        <Link className="link link-with-arrow mb-md" to="/outils-pratiques">
                            Je découvre les outils pratiques&nbsp;
                            <ArrowSm rotation="180deg" />
                        </Link>
                        <Link className="link link-with-arrow" to="/outils-pratiques">
                            J'estime une affaire ou des murs&nbsp;
                            <ArrowSm rotation="180deg" />
                        </Link>
                    </aside>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default TrucsAstucesArtice;
