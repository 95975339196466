import { useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import SearchBar from '../../../components/molecules/searchBar/SearchBar';
import TextHr from '../../../components/molecules/textHr/TextHr';
import ExpendableGroup from '../../../components/organisms/expendableGroup/ExpendableGroup';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './Faq.css';
import React, { useEffect, useState } from 'react';

function Faq() {
    const [questions, setQuestions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchBarTreshold, setSearchBarTreshold] = useState();

    const { width } = useWindowSize();

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        setSearchBarTreshold(width > 1200 ? 105 : 95);
    }, [width]);

    async function fetchQuestions() {
        //TODO fetch all questions

        const data = [
            {
                question: "Quelle est la valeur de l'estimation délivrée par EstimerMonCommerce.fr ?",
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question:
                    'Quelle méthode utilise EstimerMonCommerce.fr pour estimer un fonds de commerce ou des titres de société ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question: 'Quelle méthode utilise EstimerMonCommerce.fr pour estimer des murs commerciaux ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question: 'Pourquoi retraiter l’EBE (la rentabilité) pour estimer une affaire avec justesse ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question: 'Existe-t-il un barème national d’évaluation des fonds de commerce ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question: 'Comment intégrer l’impact du Covid 19 dans mon estimation ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'L’ESTIMATION',
            },
            {
                question: 'En tant qu’exploitant, quelle est l’utilité de l’estimation EstimerMonCommerce.fr ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: "Quelle est l'utilité de notre estimation ?",
            },
            {
                question: "En tant qu’agence immobilière, quelle est l’utilité de l'estimation EstimerMonCommerce.fr ?",
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: "Quelle est l'utilité de notre estimation ?",
            },
            {
                question: "En tant qu’avocat ou notaire, quelle est l’utilité de l'estimation EstimerMonCommerce.fr ?",
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: "Quelle est l'utilité de notre estimation ?",
            },
            {
                question: 'Mon activité se trouve-t-elle dans le choix proposé par EstimerMonCommerce.fr ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'LE PARCOURS D’ESTIMATION',
            },
            {
                question: 'Sous quelle forme se présente le résultat de l’estimation ?',
                answer: "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                category: 'LE PARCOURS D’ESTIMATION',
            },
        ];

        setCategoriesFromQuestions(data);
        setQuestions(data);
    }

    function setCategoriesFromQuestions(questions) {
        let tmp = [];
        questions.forEach(question => {
            if (!tmp.includes(question.category)) tmp.push(question.category);
        });

        tmp.map(
            (category, index) =>
                (tmp[index] = {
                    name: category,
                    id: index,
                }),
        );

        setCategories(tmp);
    }

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        { name: 'Accueil', to: '/' },
                        { name: 'Outils: Foire aux questions', to: '/faq' },
                    ]}
                />
                <Banner
                    title="Foire aux questions"
                    subtitle="Vous souhaitez en savoir plus ? Voici vos questions fréquentes"
                />
            </section>
            <div className="sticky-container">
                <FiltersGroup
                    data={categories.map(category => {
                        return {
                            name: category.name,
                            to: '#' + category.id,
                        };
                    })}
                />
                <div className="sticky-search-bar-container">
                    <SearchBar topTreshold={searchBarTreshold} />
                </div>
            </div>
            <section className="container">
                {categories.map((category, key) => {
                    return (
                        <React.Fragment key={key}>
                            <TextHr className={'faq-hr ' + (key === 0 ? 'mt-0' : '')} id={category.id}>
                                {category.name}
                            </TextHr>
                            <ExpendableGroup
                                data={questions
                                    .filter(question => question.category === category.name)
                                    .map(question => {
                                        return {
                                            title: question.question,
                                            content: <p>{question.answer}</p>,
                                        };
                                    })}
                            />
                        </React.Fragment>
                    );
                })}
                <FilledButton to="debut-parcours" className="mid-page-button">
                    Démarrer le parcours d'estimation
                </FilledButton>
            </section>
            <Footer />
        </>
    );
}

export default Faq;
