import React, { useRef, useEffect } from 'react';
import { defaultValidation } from '../../../../utils/formValidation/FormValidation.js';
import './Radio.css';
import Warning from '../../../atoms/icons/general/warning/Warning';
import { toggleCollapseElement } from '../../../../utils/Utils.js';

function Radio({
    label,
    name,
    content,
    register,
    error,
    defaultChecked,
    setValue,
    visible,
    direction = 'row',
    padding = '.5rem',
    onChange,
}) {
    const ref = useRef(null);

    useEffect(() => {
        if (visible !== undefined) toggleCollapseElement(ref.current, visible);
    }, [visible]);

    const inputRegister = register && (visible || visible === undefined) ? register(name, defaultValidation) : null;

    let rows = [];
    content.map((row, key) => {
        rows.push(
            <React.Fragment key={key}>
                <input
                    className="input-radio"
                    onClick={() => {
                        if (setValue) setValue(row.value);
                    }}
                    type="radio"
                    id={name + key}
                    name={name}
                    {...inputRegister}
                    onChange={e => {
                        inputRegister && inputRegister.onChange(e);
                        onChange && onChange(e);
                    }}
                    value={row.value}
                    defaultChecked={defaultChecked !== null && key === defaultChecked ? true : false}
                />
                <label
                    key={key}
                    htmlFor={name + key}
                    className={'radio-container' + (name === 'selectVille' ? ' selectVille' : '')}
                    style={{ '--padding': padding }}>
                    <p className="radio-label">{row.label}</p>
                </label>
            </React.Fragment>,
        );
    });

    return (
        <div className={'w-100' + (visible === undefined || visible ? ' pb-sm' : '')} ref={ref}>
            <div className="field-container radio-field-container">
                <label className="form-label">{label}</label>
                <span className="input-container">
                    <div className="radio-group" style={{ '--direction': direction }}>
                        {rows}
                    </div>
                    {error ? (
                        <div className="form-error">
                            <Warning /> <p>{error}</p>
                        </div>
                    ) : (
                        <p className="form-error"></p>
                    )}
                </span>
            </div>
        </div>
    );
}

export default Radio;
