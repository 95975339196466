import React, { useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';

export function toggleCollapseElement(element, visible, bonusHeight = 0) {
    element.style.overflowY = 'hidden';
    if (!visible) {
        element.style.maxHeight = 0;
    } else {
        element.style.maxHeight = element.scrollHeight + bonusHeight + 'px';
    }
}
export function useScrollbarWidth() {
    const didCompute = useRef(false);
    const widthRef = useRef(0);

    if (didCompute.current) return widthRef.current;

    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    didCompute.current = true;
    widthRef.current = scrollbarWidth;

    return scrollbarWidth;
}

export function toInt(str) {
    return str ? Number(str) : 0;
}

export function formatNumberWithSpaces(str) {
    if (!str) return '';
    str = str + '';
    str = str.replace(/\s/g, '');
    str = str.replace(/[^0-9.,-]/g, '');
    str = str.replace(/\./g, ',');
    str = str[0] + str.slice(1).replace(/-/g, '');
    if (str === 'undefined') return '';

    const parts = str.split(',');
    let integerPart = parts[0] ? parts[0] : '';
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimalPart = parts.length > 1 ? ',' + parts[1] : '';
    if (integerPart === '' && decimalPart) integerPart = '0';
    while (integerPart[0] === '0' && integerPart[1] !== ',' && integerPart.length > 1)
        integerPart = integerPart.slice(1);
    return integerPart + decimalPart;
}

export function getIdUser() {
    const queryParameters = new URLSearchParams(window.location.search);
    let id = queryParameters.get('id_user');

    if (id) {
        localStorage.setItem('id_user', id);
        window.history.replaceState(null, null, window.location.pathname);
    } else if (id === '') {
        window.history.replaceState(null, null, window.location.pathname);
        localStorage.removeItem('id_user');
    } else id = localStorage.getItem('id_user');

    return id;
}

export function removeSpaces(str) {
    if (str === undefined) return '';
    str = str + '';
    return Number(str.replace(/\s/g, '').replace(',', '.'));
}

export function removeSpacesFromEvent(e) {
    if (!e.target) return '';
    e.target.value = e.target.value.replace(/\s/g, '').replace(',', '.');
    return e;
}

export function getRandomString(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function uploadImage(div, token, route, extraData, callback, imageName) {
    try {
        html2canvas(div).then(e => {
            axios
                .post(
                    process.env.REACT_APP_SERVER_URL + route,
                    {
                        image: e.toDataURL('image/jpeg', 0.9),
                        image_name: imageName + '_' + getRandomString(10) + '.jpeg',
                        ...extraData,
                    },
                    {
                        headers: {
                            Authorization: token,
                        },
                    },
                )
                .then(e => {
                    callback();
                });
        });
    } catch (e) {}
}

export function isStickyElementPinned(elementRef, topTreshold) {
    if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();

        if (rect.top <= topTreshold) return true;
        else return false;
    }
    return false;
}
