import { useWindowSize } from '@uidotdev/usehooks';
import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import SearchBar from '../../../components/molecules/searchBar/SearchBar';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import ThumbnailsGroup from '../../../components/organisms/thumbnailsGroup/ThumbnailsGroup';
import './OutilsPratiques.css';
import React, { useEffect, useState } from 'react';
import ThumbnailsRow from '../../../components/organisms/thumbnailsRow/ThumbnailsRow';
import TextHr from '../../../components/molecules/textHr/TextHr';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import InfiniteThumbnailsRow from '../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow';

function OutilsPratiques() {
    const [tags, setTags] = useState([]);
    const [articles, setArticles] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [shownArticles, setShownArticles] = useState([]);
    const [tagsFilters, setTagsFilters] = useState([]);
    const { width, _ } = useWindowSize();
    const [thumbnailWidth, setThumbnailWidth] = useState('0px');
    const [searchBarTreshold, setSearchBarTreshold] = useState(105);

    useEffect(() => {
        const data = [
            {
                title: 'Les 15 points de vérification du bail commercial',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Les 15 points de vérification du bail commercial',
                alt: 'super cool',
                tag: 'modèles à personnaliser',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: 'modèles à personnaliser',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: 'modèles à personnaliser',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Les 15 points de vérification du bail commercial',
                alt: 'super cool',
                tag: 'modèles à personnaliser',
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: "cession d'affaire",
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: "cession d'affaire",
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: "cession d'affaire",
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: "cession d'affaire",
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: 'Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: "cession d'affaire",
                picture: '/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
        ];
        setArticles(data);
        setShownArticles(data);

        setSuggestions([
            {
                title: '1Les 15 points de vérification du bail commercial',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: '2Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/conseilsPros/2/thumbnail/Image_de_tete_Vente_fonds_commerce.svg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: '3Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/conseilsPros/3/thumbnail/Image_de_tete_Valorisation_murs_commerciaux.jpeg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: '4Canevas d’informations à envoyer au rédacteur du compromis',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/conseilsPros/4/thumbnail/Image_de_tete_Imposition_plus-value_fonds_commerce.jpeg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
            {
                title: '5Check-list à suivre pour visiter un local commercial',
                alt: 'super cool',
                tag: 'Gestion d’affaire',
                picture: '/conseilsPros/5/thumbnail/Image_de_tete_Evaluation_entreprises_titres_societes.jpeg',
                link: '/outils-pratiques/todo',
                linkText: "Accéder à l'outil",
            },
        ]);

        window.addEventListener('load', () => {
            const thumbnail = document.getElementsByClassName('thumbnail-container')[0];
            if (thumbnail) setThumbnailWidth(thumbnail.offsetWidth + 'px');
        });
    }, []);

    useEffect(() => {
        setTags(getTagsFromArticles());
    }, [articles, width]);

    useEffect(() => {
        setTagsFilters(getTagsFilters());
    }, [tags, width]);

    useEffect(() => {
        const thumbnail = document.getElementsByClassName('thumbnail-container')[0];
        if (thumbnail) setThumbnailWidth(thumbnail.offsetWidth + 'px');

        setSearchBarTreshold(width > 1200 ? 105 : 95);
    }, [width]);

    function getTagsFromArticles() {
        let data = [];

        articles.forEach(article => {
            if (!data.includes(article.tag)) data.push(article.tag);
        });

        data.map((tag, index) => {
            data[index] = {
                name: tag,
                id: index,
            };
        });
        if (width > 576)
            data.unshift({
                name: 'Tous',
                id: -1,
            });

        return data;
    }

    function getTagsFilters() {
        let data = [];

        tags.forEach(tag => {
            data.push({
                name: tag.name,
                onClick: () => filterArticles(tag.id),
            });
        });

        return data;
    }

    function filterArticles(tagId) {
        if (width > 576) {
            if (tagId === -1) {
                setShownArticles(articles);
                return;
            }
            const tag = tags.filter(tag => tag.id == tagId)[0].name;

            setShownArticles(articles.filter(article => article.tag == tag));
        } else
            window.scrollTo(
                0,
                document.getElementById(tags.filter(tag => tag.id == tagId)[0].id).getBoundingClientRect().top +
                    window.scrollY -
                    100,
            );
    }

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            name: 'Accueil',
                            to: '/',
                        },
                        {
                            name: 'Outils: Outils pratiques',
                            to: '/outils-pratiques',
                        },
                    ]}
                />
                <Banner
                    title="Outils pratiques"
                    subtitle="Téléchargez nos outils exclusifs pour vous aider à optimiser, céder ou acquérir une affaire"
                />
            </section>
            <div>
                <div className="sticky-container">
                    <FiltersGroup data={tagsFilters} />
                    <div className="sticky-search-bar-container">
                        <SearchBar topTreshold={searchBarTreshold} />
                    </div>
                </div>
                {width > 576 ? (
                    <>
                        <section className="container">
                            <ThumbnailsGroup data={shownArticles} />
                            <FilledButton className="mid-page-button">Démarrer le parcours d'estimation</FilledButton>
                        </section>
                        <InfiniteThumbnailsRow
                            margin="0 auto"
                            thumbnailWidth={thumbnailWidth}
                            data={suggestions}
                            title={<h2 className=" px-xl">Découvrez également nos conseils de pros !</h2>}
                            id="suggestions"
                        />
                        <FilledButton className={'mid-page-button ' + (width <= 1200 ? 'mt-md' : 'mt-xl')}>
                            Voir tous nos conseils de pros
                        </FilledButton>
                    </>
                ) : (
                    <>
                        {tags.map((tag, key) => {
                            if (tag.id !== -1)
                                return (
                                    <ThumbnailsRow
                                        key={key}
                                        title={<TextHr id={key}>{tag.name}</TextHr>}
                                        data={articles.filter(article => article.tag === tag.name)}
                                    />
                                );
                        })}
                        <FilledButton className="mid-page-button">Démarrer le parcours d'estimation</FilledButton>
                    </>
                )}
            </div>
            <Footer />
        </>
    );
}

export default OutilsPratiques;
