import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './Home.css';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import React from 'react';
import TextHr from '../../../components/molecules/textHr/TextHr';
import ExpendableGroup from '../../../components/organisms/expendableGroup/ExpendableGroup';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import PageTitle from '../../../components/molecules/pageTitle/PageTitle';
import Thumbnail from '../../../components/molecules/thumbnail/Thumbnail';
import SearchBar from '../../../components/molecules/searchBar/SearchBar';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';

function Home() {
    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        { name: 'Accueil', to: '/' },
                        { name: 'Conseils: Trucs & astuces agents immos', to: '/trucs-astuces' },
                    ]}
                />
                <Banner
                    title="Agents immos: nos trucs & astuces"
                    subtitle="Abonnez-vous en fin de page à notre Newsletter pour recevoir nos méthodes métiers tous les mois !"
                />
                <PageTitle title={'Quel est le rôle de l’expert-comptable pour une entreprise ?'} />
                <PageTitle title={'A LA UNE'} className="m-lg" />
                <TextHr id="id">Content</TextHr>
                <ExpendableGroup
                    data={[
                        {
                            title: "Quelle est la valeur de l'estimation délivrée par EstimerMonCommerce.fr ?",
                            content:
                                "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                        },
                        {
                            title: 'Quelle méthode utilise EstimerMonCommerce.fr pour estimer un fonds de commerce ou des titres de société ?',
                            content:
                                "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                        },
                        {
                            title: 'Quelle méthode utilise EstimerMonCommerce.fr pour estimer un fonds de commerce ou des titres de société ?',
                            content:
                                "L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.L’estimation délivrée par EstimerMonCommerce.fr est une évaluation professionnelle, fondée sur nos 15 ans d'expérience en cession et évaluation de commerces et d’entreprises, et sur l'observation continue des cessions effectives réalisées sur le territoire français. L’estimation qui vous est remise par EstimerMonCommerce.fr n’a pas valeur d’expert au sens juridique du terme (par exemple dans le cadre d’un litige auprès du tribunal de commerce), mais a pour vocation de vous servir de solide base de réflexion pour un projet de cession, transmission, ou optimisation de votre bien commercial. Dans cette optique, nous vous recommandons de consulter également vos conseils habituels.",
                        },
                    ]}
                />
            </section>
            <FiltersGroup
                data={[
                    { name: "l'estimation", to: '#estimation' },
                    {
                        name: "quelle est l'utilité de notre estimation ?",
                        onClick: console.log,
                        params: ['zebi', 'cool'],
                    },
                    { name: 'démarrer en toute facilité', onClick: console.log, params: ['zebi', 'cool'] },
                    { name: "le parcours d'estimation", onClick: console.log, params: ['zebi', 'cool'] },
                    // {name: "quelle est l'utilité de notre estimation ?", onClick: console.log, params: ["zebi", "cool"]}
                ]}
            />
            <section className="container">
                <div className="row-1000">
                    <Thumbnail
                        title="Les 15 points de vérification du bail commercial"
                        linkText="Accéder à l'outil"
                        picture="/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg"
                        tag={"Gestion d'affaire"}
                        link={'/outils-pratiques'}
                    />
                    <Thumbnail
                        title="Canevas d’informations à envoyer au rédacteur du compromis"
                        linkText="Accéder à l'outil"
                        picture="/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg"
                        tag={"Gestion d'affaire"}
                        link={'/outils-pratiques'}
                    />
                    <Thumbnail
                        title="Les 15 points de vérification du bail commercial"
                        linkText="Accéder à l'outil"
                        picture="/outilsPratiques/1/thumbnail/canva_d_informations_a_envoyer_au_redacteur_du_compromis.svg"
                        tag={"Gestion d'affaire"}
                        link={'/outils-pratiques'}
                    />
                </div>
                <SearchBar onChange={e => console.log(e)} />
                <div style={{ marginTop: '1000px' }} />
                <p id="estimation">estimation</p>
            </section>
            <Footer />
        </>
    );
}

export default Home;
