import Banner from '../../../components/molecules/banner/Banner';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import FiltersGroup from '../../../components/organisms/filtersGroup/FiltersGroup';
import Footer from '../../../components/organisms/footer/Footer';
import Header from '../../../components/organisms/header/Header';
import './MonCompte.css';
import React, { useState } from 'react';
import MesEstimations from './tabs/mesEstimations/MesEstimations';
import MesAbosFactures from './tabs/mesAbosFactures/MesAbosFactures';

function MonCompte() {
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs routes={[{ name: 'Accueil', to: '/' }, { name: 'Mon compte' }]} />
                <Banner title="Mon compte" subtitle="Rapports, factures, offres... Retrouvez toutes vos informations" />
            </section>
            <div className="sticky-container mb-md">
                <FiltersGroup
                    data={[
                        {
                            name: 'Mes estimations',
                            onClick: setCurrentTab,
                            params: [0],
                        },
                        {
                            name: 'Mes formations',
                            onClick: setCurrentTab,
                            params: [1],
                        },
                        {
                            name: 'Mon profil',
                            onClick: setCurrentTab,
                            params: [2],
                        },
                        {
                            name: 'Mes abonnements & factures',
                            onClick: setCurrentTab,
                            params: [3],
                        },
                    ]}
                />
            </div>
            {currentTab === 0 && <MesEstimations />}
            {currentTab === 3 && <MesAbosFactures />}
            <Footer />
        </>
    );
}

export default MonCompte;
