import { useWindowSize } from '@uidotdev/usehooks';
import './MethodeRowBorders.css';
import React from 'react';

function MethodeRowBorders({ title, content, img, alt, imgRight }) {
    const { width, _ } = useWindowSize();

    return (
        <>
            <div className={'mt-xxl methode-row-border methode-row-border' + (imgRight ? '-right' : '-left')}>
                {width > 576 && imgRight && <img src={img} className="methode-row-border-img-right" alt={alt} />}
                <div className={'row-border' + (imgRight ? '-right' : '')}>
                    <p className={'basic-sans-bold ' + (width > 576 ? 'text-xl mb-lg' : 'text-lg mb-md')}>{title}</p>
                    <p>{content}</p>
                </div>
                {width > 576 && !imgRight && (
                    <img src={img} className={'methode-row-border-img' + (imgRight ? '-right' : '-left')} alt={alt} />
                )}
            </div>
        </>
    );
}

export default MethodeRowBorders;
