import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/headerIcons/cart.svg';

function Cart({ width = '25px', color = 'var(--blue)', className }) {
    return (
        <Icon style={{ '--color': color, fill: 'var(--color)' }} width={width} height={width} className={className} />
    );
}

export default Cart;
