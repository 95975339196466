import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/heart.svg';

function Heart({ width = '15px', color = 'var(--light-grey)', className = '', onClick }) {
    return (
        <Icon
            style={{ '--color': color, fill: 'var(--color)', cursor: onClick ? 'pointer' : 'auto' }}
            width={width}
            height={width}
            className={className + (onClick ? ' clickable-icon' : '')}
            onClick={onClick}
        />
    );
}

export default Heart;
