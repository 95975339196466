import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/icons/general/info.svg';

function Info({ width = '16px', onClick, color = 'var(--gold)', className = '' }) {
    return (
        <div>
            <Icon
                width={width}
                height={width}
                stroke={color}
                className={className + (onClick ? ' clickable-icon' : '')}
                onClick={onClick}
            />
        </div>
    );
}

export default Info;
