import Header from '../../../components/organisms/header/Header';
import Footer from '../../../components/organisms/footer/Footer';
import './Tarifs.css';
import React from 'react';
import Breadcrumbs from '../../../components/molecules/breadcrumbs/Breadcrumbs';
import Banner from '../../../components/molecules/banner/Banner';
import Crown from '../../../components/atoms/icons/tarifs/crown/Crown';
import { useState } from 'react';
import { useEffect } from 'react';
import FilledButton from '../../../components/molecules/buttons/filledButton/FilledButton';
import Check from '../../../components/atoms/icons/general/check/Check';
import Download from '../../../components/atoms/icons/general/download/Download';
import { useWindowSize } from '@uidotdev/usehooks';
import RapportsRow from '../../../components/organisms/rapportsRow/RapportsRow';

function Tarifs() {
    const [discount, setDiscount] = useState({});
    const [normalPrices, setNormalPrices] = useState({});

    const { width } = useWindowSize();

    useEffect(() => {
        fetchPrices();
        fetchDiscounts();
    }, []);

    async function fetchPrices() {
        setNormalPrices({
            liberty: '99€',
            maestro: '79€',
            optimum: '69€',
        });
    }

    async function fetchDiscounts() {
        setDiscount({
            // liberty: {
            //     discountRate: '-40%',
            //     finalPrice: '59€',
            // },
            // maestro: {
            //     discountRate: '-35%',
            //     finalPrice: '52€',
            // },
            // optimum: {
            //     discountRate: '-35%',
            //     finalPrice: '52€',
            // },
        });
    }

    async function pickTarif(tarifName) {}

    const Tarif = ({ name, engagment, color }) => {
        return (
            <div className={name + '-tarif tarif-container'} style={{ '--color': color }}>
                {name === 'maestro' && <Crown className="maestro-crown" />}
                <div className="tarif-title">
                    {name}
                    {discount[name] && <div className="tarif-discount">{discount[name]?.discountRate}</div>}
                </div>
                <div className="tarif-content">
                    <div className="price-row">
                        {discount[name] ? (
                            <>
                                <p className="tarif-price tarif-with-discount">
                                    {name === 'maestro' ? (
                                        <>
                                            <span className="maestro-price">{discount[name].finalPrice}</span>
                                            <span className="normal-price-crossed">{normalPrices[name]}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="normal-price-crossed">{normalPrices[name]}</span>
                                            {discount[name].finalPrice}
                                        </>
                                    )}
                                </p>
                                <p className="tarif-duration with-promo">ht/mois</p>
                            </>
                        ) : (
                            <>
                                <p className="tarif-price">
                                    {normalPrices[name]}
                                    {name === 'maestro' ? <br /> : <>&nbsp;</>}
                                    <span className="tarif-duration">ht/mois</span>
                                </p>
                            </>
                        )}
                    </div>
                    <p className="tarif-engagment">
                        {engagment[0]}
                        <br />
                        <span>{engagment[1]}</span>
                        <br />
                        {engagment[2]}
                    </p>
                    <div className="centered">
                        <FilledButton
                            hoverColor="var(--dark-blue)"
                            fontSize={width > 1200 ? '1.7rem' : '1.3rem'}
                            color={name === 'maestro' ? 'var(--white)' : 'var(--dark-blue)'}
                            padding="15px 25px"
                            bgColor={color}
                            onClick={() => pickTarif(name)}>
                            Activer
                        </FilledButton>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Header />
            <section className="container">
                <Breadcrumbs
                    routes={[
                        {
                            to: '/',
                            name: 'Accueil',
                        },
                        {
                            to: '/tarifs',
                            name: 'Tarifs',
                        },
                    ]}
                />
                <Banner title="Nos offres" />
                <div className="tarifs-page-container">
                    <div className="relative">
                        <div className="tarifs-row">
                            <Tarif
                                name="maestro"
                                engagment={['Engagement', '12 mois', 'Paiement mensuel']}
                                color="#180047"
                            />
                        </div>
                        <div className="tarifs-row">
                            <Tarif name="liberty" engagment={['', 'Mensuel', 'Sans engagement']} color="#D9E1FF" />
                            {width > 992 && <div className="empty-tarif-container" />}
                            <Tarif
                                name="optimum"
                                engagment={['Engagement', '12 mois', 'Paiement annuel en 1 fois']}
                                color="var(--gold)"
                            />
                        </div>
                    </div>
                    <div className="tarifs-detail">
                        <p className="tarif-detail-title">Toutes nos offres incluent :</p>
                        <div className="row-1000">
                            <div className="row-500">
                                <div className="centered">
                                    <Check width={width > 992 ? '35px' : '25px'} />
                                    <p>Nombre illimité d'estimations</p>
                                </div>
                                <div className="centered">
                                    <Check width={width > 992 ? '35px' : '25px'} />
                                    <p>Estimations modifiables à volonté</p>
                                </div>
                            </div>
                            <div className="row-500">
                                <div className="centered">
                                    <Check width={width > 992 ? '35px' : '25px'} />
                                    <p>Insertion de votre logo en tête du rapport</p>
                                </div>
                                <div className="centered">
                                    <Check width={width > 992 ? '35px' : '25px'} />
                                    <p>Accès illimité à toutes nos ressources</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-lg text-center">
                        Votre offre se renouvelle automatiquement à sa date d'échéance (mois ou année), sauf si vous
                        choisissez de la résilier avant.
                    </p>
                    <p className="text-center mt-xl basic-sans-bold text-xxl">Exemples de rapports</p>
                </div>
            </section>
            <RapportsRow />
            <Footer />
        </>
    );
}

export default Tarifs;
