import './CalculateurCotisationsForm.css';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toInt, toggleCollapseElement, removeSpaces } from '../../../utils/Utils';
import Toggle from '../../molecules/formComponents/toggle/Toggle';
import NumberInput from '../../molecules/formComponents/numberInput/NumberInput';
import axios from 'axios';
import FilledButton from '../../molecules/buttons/filledButton/FilledButton';
import Cross from '../../atoms/icons/general/cross/Cross';

function CalculateurCotisationsForm({ visible, setVisible, setSalaire, setCharges }) {
    const { register, setValue, watch } = useForm();
    const Form = { register, setValue, watch };

    const parcours = visible !== undefined ? true : false;

    const [tnsFloors, setTnsFloors] = useState([]);
    const [salariesFloors, setSalariesFloors] = useState([]);
    const [typeExploitant, setTypeExploitant] = useState(0);
    const ref = useRef(null);
    const tnsRef = useRef(null);
    const salarieRef = useRef(null);

    useEffect(() => {
        setTnsArray();
        setSalarieArray();
    }, []);

    useEffect(() => {
        if (typeExploitant === 1) {
            toggleCollapseElement(tnsRef.current, false);
            toggleCollapseElement(salarieRef.current, true);
        }
        if (typeExploitant === 0) {
            toggleCollapseElement(tnsRef.current, true);
            toggleCollapseElement(salarieRef.current, false);
        }
    }, [typeExploitant]);

    useEffect(() => {
        if (visible !== undefined) toggleCollapseElement(ref.current, visible);
    }, [visible]);

    function setParcours() {
        switch (typeExploitant) {
            case 0:
                setSalaire(removeSpaces(watch('remunerationNette')));
                setCharges(removeSpaces(watch('cotSociales')));
                break;
            case 1:
                setSalaire(removeSpaces(watch('brutAnnuel')));
                setCharges(removeSpaces(watch('cotPatronAnnuelles')));
        }
    }

    function setFloor(array, fieldRef, field, percentField) {
        array.forEach(element => {
            element[field] = Math.floor(element[fieldRef] * (percentField ? element[percentField] / 100 : 12));
        });
        return array;
    }

    function getValueFromFloor(floorsArray, value, refField, percent, multiply = true) {
        let index = -1;
        floorsArray.forEach(element => {
            if (Number(value) >= element[refField]) index++;
        });
        return Number(
            multiply
                ? (value * floorsArray[index === -1 ? 0 : index][percent]) / 100
                : value / (floorsArray[index === -1 ? 0 : index][percent] / 100),
        );
    }

    function getPercent(floorsArray, value, refField, percent) {
        let index = -1;
        floorsArray.forEach(element => {
            if (value >= element[refField]) index++;
        });
        return floorsArray[index === -1 ? 0 : index][percent] / 100;
    }

    function setTnsArray() {
        axios.get(process.env.REACT_APP_SERVER_URL + 'calcul-cotisation/calctns').then(res => {
            let tmp = [];

            for (let i = 0; i < res.data.length; i++) {
                tmp.push({
                    remunerationNette: res.data[i].rem_nette_tns,
                    ratio: res.data[i].ratio,
                });
            }
            tmp = setFloor(tmp, 'remunerationNette', 'cotSociales', 'ratio');

            setTnsFloors(tmp);
        });
    }

    function sortRows(a, b) {
        return Number(a.net_annuel) - Number(b.net_annuel);
    }

    function setSalarieArray() {
        axios.get(process.env.REACT_APP_SERVER_URL + 'calcul-cotisation/calcsalarie').then(res => {
            res.data = res.data.sort(sortRows);
            let tmp = [];
            for (let i = 0; i < res.data.length; i++) {
                tmp.push({
                    netAnnuel: res.data[i].net_annuel,
                    ratio_cot_soc_salarie: res.data[i].ratio_cot_soc_salarie,
                    ratio_cot_soc_patronales: res.data[i].ratio_cot_soc_patronales,
                });
            }
            tmp = setFloor(tmp, 'netAnnuel', 'cotSalarieAnnuelles', 'ratio_cot_soc_salarie');
            tmp.forEach(element => {
                element.brutAnnuel = Number(element.netAnnuel) + Number(element.cotSalarieAnnuelles);
            });
            tmp = setFloor(tmp, 'brutAnnuel', 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
            tmp.forEach(element => {
                element.coutEntrepriseAnnuel = Number(element.brutAnnuel) + Number(element.cotPatronAnnuelles);
            });

            tmp = setFloor(tmp, 'netAnnuel', 'netMensuel');
            tmp = setFloor(tmp, 'brutAnnuel', 'brutMensuel');
            tmp = setFloor(tmp, 'coutEntrepriseAnnuel', 'coutEntrepriseMensuel');

            setSalariesFloors(tmp);
        });
    }

    function getFromNetMensuel(e) {
        let values = {};
        values.netMensuel = Number(e.target.value);
        values.netAnnuel = values.netMensuel * 12;
        values.brutAnnuel =
            values.netAnnuel +
            getValueFromFloor(salariesFloors, values.netAnnuel, 'netAnnuel', 'ratio_cot_soc_salarie');
        values.brutMensuel = Math.round(values.brutAnnuel / 12);
        values.cotPatronAnnuelles = getValueFromFloor(
            salariesFloors,
            values.brutAnnuel,
            'brutAnnuel',
            'ratio_cot_soc_patronales',
        );
        values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromNetAnnuel(e) {
        let values = {};
        values.netAnnuel = Number(e.target.value);
        values.netMensuel = values.netAnnuel / 12;
        values.brutAnnuel =
            values.netAnnuel +
            getValueFromFloor(salariesFloors, values.netAnnuel, 'netAnnuel', 'ratio_cot_soc_salarie');
        values.brutMensuel = Math.round(values.brutAnnuel / 12);
        values.cotPatronAnnuelles = getValueFromFloor(
            salariesFloors,
            values.brutAnnuel,
            'brutAnnuel',
            'ratio_cot_soc_patronales',
        );
        values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromBrutMensuel(e) {
        let values = {};
        values.brutMensuel = Number(e.target.value);
        values.brutAnnuel = values.brutMensuel * 12;
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.cotPatronAnnuelles = getValueFromFloor(
            salariesFloors,
            values.brutAnnuel,
            'brutAnnuel',
            'ratio_cot_soc_patronales',
        );
        values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromBrutAnnuel(e) {
        let values = {};
        values.brutAnnuel = Number(e.target.value);
        values.brutMensuel = values.brutAnnuel / 12;
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.cotPatronAnnuelles = getValueFromFloor(
            salariesFloors,
            values.brutAnnuel,
            'brutAnnuel',
            'ratio_cot_soc_patronales',
        );
        values.cotPatronMensuelles = Math.round(values.cotPatronAnnuelles / 12);
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromCotPatronMensuelles(e) {
        let values = {};
        values.cotPatronMensuelles = Number(e.target.value);
        values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;
        values.brutAnnuel =
            values.cotPatronAnnuelles /
            getPercent(salariesFloors, values.cotPatronAnnuelles, 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
        values.brutMensuel = Math.floor(values.brutAnnuel / 12);
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromCotPatronAnnuelles(e) {
        let values = {};

        values.cotPatronAnnuelles = Number(e.target.value);
        values.cotPatronMensuelles = Math.floor(values.cotPatronAnnuelles / 12);
        values.brutAnnuel =
            values.cotPatronAnnuelles /
            getPercent(salariesFloors, values.cotPatronAnnuelles, 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
        values.brutMensuel = Math.floor(values.brutAnnuel / 12);
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.coutEntrepriseAnnuel = values.cotPatronAnnuelles + values.brutAnnuel;
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);

        setValuesFromArray(values);
    }

    function getFromCoutEntrepriseMensuel(e) {
        let values = {};
        values.coutEntrepriseMensuel = Number(e.target.value);
        values.coutEntrepriseAnnuel = values.coutEntrepriseMensuel * 12;
        values.brutAnnuel =
            values.coutEntrepriseAnnuel /
            (1 + getPercent(salariesFloors, values.coutEntrepriseAnnuel, 'brutAnnuel', 'ratio_cot_soc_patronales'));
        values.brutMensuel = Math.round(values.brutAnnuel / 12);
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.cotPatronMensuelles = values.coutEntrepriseMensuel - values.brutMensuel;
        values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;

        setValuesFromArray(values);
    }

    function getFromCoutEntrepriseAnnuel(e) {
        let values = {};
        values.coutEntrepriseAnnuel = Number(e.target.value);
        values.coutEntrepriseMensuel = Math.round(values.coutEntrepriseAnnuel / 12);
        values.brutAnnuel =
            values.coutEntrepriseAnnuel /
            (1 + getPercent(salariesFloors, values.coutEntrepriseAnnuel, 'brutAnnuel', 'ratio_cot_soc_patronales'));
        values.brutMensuel = Math.round(values.brutAnnuel / 12);
        values.netAnnuel =
            values.brutAnnuel /
            (1 + getPercent(salariesFloors, values.brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
        values.netMensuel = values.netAnnuel / 12;
        values.cotPatronMensuelles = values.coutEntrepriseMensuel - values.brutMensuel;
        values.cotPatronAnnuelles = values.cotPatronMensuelles * 12;

        setValuesFromArray(values);
    }

    function setValuesFromArray(values) {
        for (const [key, value] of Object.entries(values)) {
            setValue(key, Math.round(value));
        }
    }

    return (
        <>
            <fieldset
                ref={ref}
                className="borderless-fieldset calculateur-container"
                style={{
                    '--max-width': parcours ? '100%' : '800px',
                    '--bg-color': parcours ? 'var(--dark-blue)' : 'transparent',
                    '--color': parcours ? 'white' : 'fontVariant(--dark-blue)',
                    '--align': parcours ? 'flex-start' : 'center',
                    '--padding': visible ? '20px 30px' : '30px',
                }}>
                <div className="s-between">
                    {parcours && (
                        <>
                            <h2>Calculateur de cotisations</h2>
                            <Cross onClick={() => setVisible(false)} />
                        </>
                    )}
                </div>
                <i className="text-grey basic-sans-it centered">
                    * Saisissez un montant dans l’une de ces cases, les autres se rempliront automatiquement
                </i>
                <div className="centered">
                    <Toggle
                        name="typeExploitant"
                        label="exploitant tns"
                        bgColor="var(--sky-blue)"
                        label2="exploitant salarié de sa société"
                        checked={typeExploitant}
                        onChange={e => (e.target.checked ? setTypeExploitant(1) : setTypeExploitant(0))}
                    />
                </div>
                <div className={'tns-container ' + (typeExploitant === 1 ? 'd-none' : '')} ref={tnsRef}>
                    <h2>Rémunération de gérance</h2>
                    <NumberInput
                        useForm={Form}
                        name="remunerationNette"
                        label="rémunération annuelle nette *"
                        icon="euro"
                        onChange={e =>
                            setValue(
                                'cotSociales',
                                Math.floor(
                                    getValueFromFloor(tnsFloors, toInt(e.target.value), 'remunerationNette', 'ratio'),
                                ),
                            )
                        }
                    />
                    <h2>Cotisations sociales</h2>
                    <NumberInput
                        useForm={Form}
                        name="cotSociales"
                        label="annuelles *"
                        icon="euro"
                        onChange={e =>
                            setValue(
                                'remunerationNette',
                                Math.floor(
                                    getValueFromFloor(tnsFloors, toInt(e.target.value), 'cotSociales', 'ratio', false),
                                ),
                            )
                        }
                    />
                </div>

                <div className={'salarie-container ' + (typeExploitant === 1 ? '' : 'd-none')} ref={salarieRef}>
                    <h2 className={'m-0' + (typeExploitant === 1 ? '' : 'd-none')}>Salaire</h2>
                    <p className={'text-sky-blue text-sm text-center my-sm' + (typeExploitant === 1 ? '' : ' d-none')}>
                        Indiquez les salaires individuellement (ne pas additionner plusieurs salaires ici)
                    </p>
                    <div className="row-1000">
                        <NumberInput
                            useForm={Form}
                            name="netMensuel"
                            label="net mensuel avant impôts *"
                            icon="euro"
                            onChange={getFromNetMensuel}
                        />
                        <NumberInput
                            useForm={Form}
                            name="netAnnuel"
                            label="net annuel avant impôts *"
                            icon="euro"
                            onChange={getFromNetAnnuel}
                        />
                    </div>
                    <div className="row-1000">
                        <NumberInput
                            useForm={Form}
                            name="brutMensuel"
                            label="brut mensuel *"
                            icon="euro"
                            onChange={getFromBrutMensuel}
                        />
                        <NumberInput
                            useForm={Form}
                            name="brutAnnuel"
                            label="brut annuel *"
                            bgColor={parcours ? 'var(--gold' : undefined}
                            icon="euro"
                            onChange={getFromBrutAnnuel}
                        />
                    </div>
                    <h2>Cotisation sociales patronales</h2>
                    <div className="row-1000">
                        <NumberInput
                            useForm={Form}
                            name="cotPatronMensuelles"
                            label="mensuelles *"
                            icon="euro"
                            onChange={getFromCotPatronMensuelles}
                        />
                        <NumberInput
                            useForm={Form}
                            name="cotPatronAnnuelles"
                            label="annuelles *"
                            icon="euro"
                            bgColor={parcours ? 'var(--gold' : undefined}
                            onChange={getFromCotPatronAnnuelles}
                        />
                    </div>
                    <div className="row-1000">
                        <NumberInput
                            useForm={Form}
                            name="coutEntrepriseMensuel"
                            label="coût total entreprise mensuel *"
                            icon="euro"
                            onChange={getFromCoutEntrepriseMensuel}
                        />
                        <NumberInput
                            useForm={Form}
                            name="coutEntrepriseAnnuel"
                            label="coût total entreprise annuel *"
                            icon="euro"
                            onChange={getFromCoutEntrepriseAnnuel}
                        />
                    </div>
                </div>
                <div className={'centered mt-sm mb-sm' + (parcours ? '' : ' d-none')}>
                    <FilledButton onClick={setParcours} height="50px" bgColor="var(--sky-blue)">
                        Reporter salaire & charges sur le parcours
                    </FilledButton>
                </div>
            </fieldset>
        </>
    );
}

export default CalculateurCotisationsForm;
