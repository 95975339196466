import React from 'react';
import './FilledButton.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function FilledButton({
    children,
    bgColor = 'var(--blue)',
    color = 'var(--white)',
    fontSize = '1rem',
    padding = '5px 20px',
    onClick,
    to,
    type = 'button',
    className = '',
    target = '_self',
    rel = '',
    hoverColor = 'var(--blue)',
}) {
    return (
        <>
            {to ? (
                to.includes('#') ? (
                    <HashLink
                        smooth
                        className={'button filled-button ' + className}
                        style={{
                            '--background-color': bgColor,
                            '--color': color,
                            '--hover-color': hoverColor,
                            '--font-size': fontSize,
                            '--padding': padding,
                        }}
                        onClick={e => {
                            onClick && onClick(e);
                        }}
                        to={to}
                        target={target}>
                        {children}
                    </HashLink>
                ) : (
                    <Link
                        className={'button filled-button ' + className}
                        style={{
                            '--background-color': bgColor,
                            '--font-size': fontSize,
                            '--hover-color': hoverColor,
                            '--color': color,
                            '--padding': padding,
                        }}
                        onClick={e => {
                            onClick && onClick(e);
                        }}
                        to={to}
                        rel={rel}
                        target={target}>
                        {children}
                    </Link>
                )
            ) : (
                <button
                    type={type}
                    className={'button filled-button ' + className}
                    style={{
                        '--background-color': bgColor,
                        '--font-size': fontSize,
                        '--hover-color': hoverColor,
                        '--color': color,
                        '--padding': padding,
                    }}
                    onClick={e => {
                        onClick && onClick(e);
                    }}>
                    {children}
                </button>
            )}
        </>
    );
}

export default FilledButton;
