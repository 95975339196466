import React from 'react';
import { ReactComponent as Icon } from '../../../../../assets/methode/icons/dottedAngle.svg';

function DottedAngle({ height = '80px', color = 'var(--dark-blue)', className, rotation = '0deg' }) {
    return (
        <Icon
            style={{ '--color': color, stroke: 'var(--color)', transform: 'rotateY(' + rotation + ')' }}
            height={height}
            className={className}
        />
    );
}

export default DottedAngle;
